export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ClaimId: number;
  Date: string;
  DateTime: string;
  MaintenancePlanId: any;
  PeripheralId: string;
  TaskId: string;
};

export type ApiToken = {
  /** A description of what this API token is used for. */
  description?: Maybe<Scalars['String']>;
  /** The expiration duration of a token if set to expire. */
  expiration?: Maybe<Scalars['Float']>;
  /** The date this token was issued. */
  generatedAt: Scalars['Date'];
  /** Whether the token is active */
  isActive: Scalars['Boolean'];
  /** The name of the API token, visible to the user. */
  name?: Maybe<Scalars['String']>;
  /** The owner (user) of this token. */
  userSub: Scalars['ID'];
};

export type AccumulatorConfig = {
  resetsOnPowerOff: Scalars['Boolean'];
  rolloverThreshold?: Maybe<Scalars['Int']>;
  rolloverValue: Scalars['Float'];
};

export type AccumulatorConfigInput = {
  resetsOnPowerOff?: InputMaybe<Scalars['Boolean']>;
  rolloverThreshold?: InputMaybe<Scalars['Int']>;
  rolloverValue?: InputMaybe<Scalars['Float']>;
};

export type Action = {
  author: Scalars['String'];
  details: Scalars['String'];
  time: Scalars['Date'];
};

export type ActionMap = {
  make: Action;
  pending?: Maybe<Action>;
  resolve?: Maybe<Action>;
  take?: Maybe<Action>;
};

export type Actions = {
  lineId?: Maybe<Scalars['ID']>;
  peripheralId?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
};

export type ActionsInput = {
  lineId?: InputMaybe<Scalars['ID']>;
  peripheralId?: InputMaybe<Scalars['ID']>;
  type: Scalars['String'];
};

export type Alarm = {
  alarmConfiguration: AlarmConfiguration;
  alarmLogs: AlarmLogs;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
  peripheral?: Maybe<Peripheral>;
  peripheralId: Scalars['ID'];
  repeatNotification: Scalars['Boolean'];
  snoozeDuration?: Maybe<Scalars['Int']>;
  status: AlarmStatus;
  subscribers: Array<SubscriberOutput>;
  threshold: Scalars['Int'];
  timeRange?: Maybe<TimeRange>;
  type: AlarmType;
};

export type AlarmAlarmLogsArgs = {
  exclusiveStartKey?: InputMaybe<AlarmLogExclusiveStartKey>;
  filter?: InputMaybe<AlarmLogFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<AlarmLogOrder>;
};

export type AlarmConfiguration = {
  n?: Maybe<Scalars['Float']>;
  stopType?: Maybe<StopTypeForAlarms>;
  t?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

export type AlarmConfigurationInput = {
  n?: InputMaybe<Scalars['Float']>;
  stopType?: InputMaybe<StopTypeForAlarms>;
  t?: InputMaybe<Scalars['Float']>;
  x?: InputMaybe<Scalars['Float']>;
  y?: InputMaybe<Scalars['Float']>;
};

export type AlarmLog = {
  config?: Maybe<AlarmLogConfig>;
  createdAt?: Maybe<Scalars['Date']>;
  lastNotification?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
  status: AlarmStatus;
  timeRange: TimeRange;
};

export type AlarmLogConfig = {
  stopType?: Maybe<StopTypeForAlarms>;
  t?: Maybe<Scalars['Float']>;
};

export type AlarmLogExclusiveStartKey = {
  alarmId: Scalars['String'];
  start: Scalars['Date'];
};

export type AlarmLogFilter = {
  start?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AlarmStatus>;
};

export type AlarmLogLastEvaluatedKey = {
  alarmId: Scalars['String'];
  start: Scalars['Date'];
};

export type AlarmLogOrder = {
  byStart?: InputMaybe<Ordering>;
};

export type AlarmLogs = {
  entries: Array<Maybe<AlarmLog>>;
  lastEvaluatedKey?: Maybe<AlarmLogLastEvaluatedKey>;
};

export enum AlarmStatus {
  NORMAL = 'NORMAL',
  ONGOING = 'ONGOING',
  SNOOZED = 'SNOOZED',
  STOPPED = 'STOPPED',
}

export enum AlarmType {
  AboveX = 'AboveX',
  AboveXForTSeconds = 'AboveXForTSeconds',
  AboveXForTSecondsWithinYTimeNTimes = 'AboveXForTSecondsWithinYTimeNTimes',
  BelowX = 'BelowX',
  BelowXForTSeconds = 'BelowXForTSeconds',
  BelowXForTSecondsWithinYTimeNTimes = 'BelowXForTSecondsWithinYTimeNTimes',
  StopForTSeconds = 'StopForTSeconds',
  SumOfTAboveXWithinYTime = 'SumOfTAboveXWithinYTime',
  SumOfTBelowXWithinYTime = 'SumOfTBelowXWithinYTime',
}

export enum AnalogInputRange {
  FOUR_TO_TWENTY_MILLIAMPS = 'FOUR_TO_TWENTY_MILLIAMPS',
  NEGATIVE_FIVE_TO_FIVE_VOLTS = 'NEGATIVE_FIVE_TO_FIVE_VOLTS',
  NEGATIVE_TEN_TO_TEN_VOLTS = 'NEGATIVE_TEN_TO_TEN_VOLTS',
  NEGATIVE_TWENTYFIVE_TO_TWENTYFIVE_MILLIAMPS = 'NEGATIVE_TWENTYFIVE_TO_TWENTYFIVE_MILLIAMPS',
  ZERO_TO_FIVE_VOLTS = 'ZERO_TO_FIVE_VOLTS',
  ZERO_TO_TEN_VOLTS = 'ZERO_TO_TEN_VOLTS',
  ZERO_TO_TWENTYFOUR_MILLIAMPS = 'ZERO_TO_TWENTYFOUR_MILLIAMPS',
  ZERO_TO_TWENTY_MILLIAMPS = 'ZERO_TO_TWENTY_MILLIAMPS',
}

export enum AnalyticsDocumentType {
  CHANGEOVER = 'CHANGEOVER',
  PARETO = 'PARETO',
}

/**
 * Andon is the operator-technician communication maintenance module of
 * Blackbird. This module enables operators to contact technicians from the stop
 * registration dialog or through the API from the andon-prefixed mutations listed
 * under Mutations.
 *
 * An Andon call can be in one of four states, called, taken, pending and
 * resolved. Pending is an experimental state as of this writing and is not
 * publicly available. These states enable the operator and technician to document
 * the steps taken to resolve a technical problem.
 *
 * This full history of events is tracked by summary of details for each individual
 * call.
 */
export type Andon = {
  _schedule: AndonSchedule;
  /**
   * The list of Andon calls in a given week with filters enabling to only list
   * resolved, unresolved and lines initiated from a specific line.
   */
  calls: Array<Call>;
  /** Currently scoped per company, this identifier is subject to change. */
  companyId: Scalars['ID'];
  /**
   * Not available for public use and is subject to change for external system
   * interfacing.
   */
  extensions?: Maybe<Array<Extension>>;
  /**
   * The list of configured roles that a worker can be assigned. Electrician,
   * Blacksmith etc.
   */
  roles: Array<AndonRole>;
  /** @deprecated To be replaced by _schedule with Andon 2.0. */
  schedule?: Maybe<MaintenanceSchedule>;
  schedules: Array<AndonSchedule>;
  /** @deprecated To be replaced by roles with Andon 2.0. */
  supportTypes: Array<SupportType>;
  /** @deprecated To be redesigned with Andon 3.0. */
  tags: Array<Tag>;
  /** @deprecated To be redesigned with Andon 3.0. */
  workOrderKeys?: Maybe<Array<WorkOrderKey>>;
  /**
   * The list of set up `workers'. A worker represents someone at the receiving end
   * for notifications. For that, Blackbird requires information about the email
   * and/or phone number of that person.
   */
  workers: Array<Worker>;
};

/**
 * Andon is the operator-technician communication maintenance module of
 * Blackbird. This module enables operators to contact technicians from the stop
 * registration dialog or through the API from the andon-prefixed mutations listed
 * under Mutations.
 *
 * An Andon call can be in one of four states, called, taken, pending and
 * resolved. Pending is an experimental state as of this writing and is not
 * publicly available. These states enable the operator and technician to document
 * the steps taken to resolve a technical problem.
 *
 * This full history of events is tracked by summary of details for each individual
 * call.
 */
export type Andon_ScheduleArgs = {
  scheduleId: Scalars['ID'];
};

/**
 * Andon is the operator-technician communication maintenance module of
 * Blackbird. This module enables operators to contact technicians from the stop
 * registration dialog or through the API from the andon-prefixed mutations listed
 * under Mutations.
 *
 * An Andon call can be in one of four states, called, taken, pending and
 * resolved. Pending is an experimental state as of this writing and is not
 * publicly available. These states enable the operator and technician to document
 * the steps taken to resolve a technical problem.
 *
 * This full history of events is tracked by summary of details for each individual
 * call.
 */
export type AndonCallsArgs = {
  lineId?: InputMaybe<Scalars['ID']>;
  resolved?: InputMaybe<Scalars['Boolean']>;
  time?: InputMaybe<Scalars['Date']>;
};

/**
 * Andon is the operator-technician communication maintenance module of
 * Blackbird. This module enables operators to contact technicians from the stop
 * registration dialog or through the API from the andon-prefixed mutations listed
 * under Mutations.
 *
 * An Andon call can be in one of four states, called, taken, pending and
 * resolved. Pending is an experimental state as of this writing and is not
 * publicly available. These states enable the operator and technician to document
 * the steps taken to resolve a technical problem.
 *
 * This full history of events is tracked by summary of details for each individual
 * call.
 */
export type AndonSupportTypesArgs = {
  available?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Andon is the operator-technician communication maintenance module of
 * Blackbird. This module enables operators to contact technicians from the stop
 * registration dialog or through the API from the andon-prefixed mutations listed
 * under Mutations.
 *
 * An Andon call can be in one of four states, called, taken, pending and
 * resolved. Pending is an experimental state as of this writing and is not
 * publicly available. These states enable the operator and technician to document
 * the steps taken to resolve a technical problem.
 *
 * This full history of events is tracked by summary of details for each individual
 * call.
 */
export type AndonTagsArgs = {
  lineId?: InputMaybe<Scalars['ID']>;
  time?: InputMaybe<Scalars['Date']>;
};

/**
 * Andon is the operator-technician communication maintenance module of
 * Blackbird. This module enables operators to contact technicians from the stop
 * registration dialog or through the API from the andon-prefixed mutations listed
 * under Mutations.
 *
 * An Andon call can be in one of four states, called, taken, pending and
 * resolved. Pending is an experimental state as of this writing and is not
 * publicly available. These states enable the operator and technician to document
 * the steps taken to resolve a technical problem.
 *
 * This full history of events is tracked by summary of details for each individual
 * call.
 */
export type AndonWorkOrderKeysArgs = {
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AndonRole = {
  escalationConfiguration?: Maybe<Array<EscalationConfiguration>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AndonSchedule = {
  id: Scalars['ID'];
  lineIds: Array<Scalars['ID']>;
  /** Lists lines covered by this Andon schedule. */
  lines: Array<Line>;
  name: Scalars['String'];
  shift: Array<AndonShift>;
  shifts: Array<AndonShift>;
};

export type AndonScheduleShiftArgs = {
  input?: InputMaybe<AndonShiftInput>;
  shiftId: Scalars['ID'];
};

export type AndonScheduleShiftsArgs = {
  input?: InputMaybe<AndonShiftInput>;
};

export type AndonShift = {
  attendees: Array<AttendingWorker>;
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  rRuleSet: Scalars['String'];
};

export type AndonShiftAttendeesArgs = {
  input?: InputMaybe<AttendeesInput>;
};

export type AndonShiftInput = {
  timeFilter?: InputMaybe<TimeFilter>;
};

export type AttendanceInput = {
  exceptions: Array<Scalars['Date']>;
  workerId: Scalars['ID'];
};

export enum AttendanceTypes {
  EXCLUSIVE = 'EXCLUSIVE',
  INCLUSIVE = 'INCLUSIVE',
}

export type Attendee = {
  dateExceptions: Array<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  messages: Array<Message>;
  phoneNumber?: Maybe<Scalars['String']>;
  supportId: Scalars['ID'];
};

export type AttendeesInput = {
  includeExcluded?: InputMaybe<Scalars['Boolean']>;
};

export type AttendingWorker = IWorker & {
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExcluded?: Maybe<Scalars['Boolean']>;
  isPermanent?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  preferredSchedules: Array<AndonSchedule>;
  /** @deprecated Workers may now reference multiple roles. */
  role: AndonRole;
  roles: Array<AndonRole>;
};

export type AuthToken = {
  /** The auth token value. */
  accessToken: Scalars['String'];
  /** Whether or not the token is active. */
  isActive: Scalars['Boolean'];
  /** The owner (user) of this token. */
  userSub: Scalars['ID'];
};

export type AutomaticStopRegistration = {
  comment?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  minimumStopMillis?: Maybe<Scalars['Int']>;
  splitAtEnds?: Maybe<Scalars['Boolean']>;
  stopCauseId?: Maybe<Scalars['ID']>;
};

export type AutomaticStopRegistrationInput = {
  comment?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  minimumStopMillis?: InputMaybe<Scalars['Int']>;
  splitAtEnds?: InputMaybe<Scalars['Boolean']>;
  stopCauseId?: InputMaybe<Scalars['ID']>;
};

export type AverageProducedInput = {
  duration: Scalars['Float'];
};

export type BaseBatch = {
  actualStart?: Maybe<Scalars['Date']>;
  actualStop?: Maybe<Scalars['Date']>;
  batchId: Scalars['ID'];
  batchNumber: Scalars['String'];
  plannedStart?: Maybe<Scalars['Date']>;
};

export type BaseProduct = {
  expectedAverageSpeed: Scalars['Float'];
  itemNumber: Scalars['String'];
  name: Scalars['String'];
  parameters: Array<Parameter>;
  productId: Scalars['ID'];
  validatedLineSpeed: Scalars['Float'];
};

export type BaseStop = {
  _id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  countermeasure?: Maybe<Scalars['String']>;
  countermeasureInitials?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  initials?: Maybe<Scalars['String']>;
  isAutomaticRegistration?: Maybe<Scalars['Boolean']>;
  isMicroStop: Scalars['Boolean'];
  ongoing: Scalars['Boolean'];
  registeredTime?: Maybe<Scalars['Date']>;
  standalone?: Maybe<StandaloneConfiguration>;
  stopCause?: Maybe<StopCause>;
  timeRange: TimeRange;
};

export type Batch = BaseBatch & {
  actualStart?: Maybe<Scalars['Date']>;
  actualStop?: Maybe<Scalars['Date']>;
  amount: Scalars['Float'];
  batchId: Scalars['ID'];
  batchNumber: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  controls: Array<BatchControl>;
  /** Get the user who created this batch. */
  createdBy: User;
  /** Get the line related to this batch. */
  line: Line;
  lineId: Scalars['ID'];
  manualScrap?: Maybe<Scalars['Float']>;
  plannedStart?: Maybe<Scalars['Date']>;
  produced?: Maybe<Scalars['Float']>;
  product: Product;
  /** Get the samples collected within the timespan of the batch. */
  samples?: Maybe<Array<Maybe<Sample>>>;
  /** Get all the scrapped samples within the timespan of the batch. */
  scrap?: Maybe<Array<Maybe<Sample>>>;
  sorting: Scalars['String'];
  state: BatchState;
  /** Get the stats collected within the timespan of the batch. */
  stats: Stat;
  stopStats: StopStats;
  /** Get the stops collected within the timespan of the batch. */
  stops: Array<Maybe<Stop>>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type BatchLineArgs = {
  filter?: InputMaybe<LineFilterInput>;
  languageCode?: InputMaybe<Scalars['String']>;
};

export type BatchSamplesArgs = {
  points?: InputMaybe<Scalars['Int']>;
};

export type BatchScrapArgs = {
  points?: InputMaybe<Scalars['Int']>;
};

export type BatchStopStatsArgs = {
  filter?: InputMaybe<StopFilter>;
};

export type BatchStopsArgs = {
  filter?: InputMaybe<StopFilter>;
  languageCode?: InputMaybe<Scalars['String']>;
};

export type BatchControl = {
  batchControlId: Scalars['ID'];
  batchId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  controlReceiptId: Scalars['ID'];
  controlReceiptName: Scalars['String'];
  entryId: Scalars['ID'];
  fieldValues: Array<BatchControlFieldValue>;
  followUp: FollowUpSettings;
  history: Array<BatchControlHistory>;
  initials?: Maybe<Scalars['String']>;
  originalControl?: Maybe<OriginalControlDetails>;
  status: BatchControlStatus;
  timeControlUpdated?: Maybe<Scalars['Date']>;
  timeControlled?: Maybe<Scalars['Date']>;
  timeTriggered: Scalars['Date'];
  title?: Maybe<Scalars['String']>;
  trigger: ControlTrigger;
};

export type BatchControlFieldInput = {
  fieldId: Scalars['ID'];
  value: Scalars['String'];
};

export type BatchControlFieldValue = {
  controlReceiptField: ControlReceiptEntryField;
  value?: Maybe<Scalars['String']>;
};

export type BatchControlHistory = {
  comment?: Maybe<Scalars['String']>;
  fieldValues: Array<BatchControlFieldValue>;
  initials?: Maybe<Scalars['String']>;
  status: BatchControlStatus;
  timeControlUpdated?: Maybe<Scalars['Date']>;
  timeControlled?: Maybe<Scalars['Date']>;
};

export type BatchControlList = {
  items: Array<BatchControl>;
  nextToken?: Maybe<Scalars['ID']>;
};

export enum BatchControlStatus {
  CANCELED = 'CANCELED',
  DONE = 'DONE',
  PENDING = 'PENDING',
}

export type BatchFilter = {
  batchId?: InputMaybe<Scalars['ID']>;
  batchNumber?: InputMaybe<Scalars['String']>;
  exclusiveFromCheck?: InputMaybe<Scalars['Boolean']>;
  from?: InputMaybe<Scalars['Date']>;
  includeSurroundingBatches?: InputMaybe<Scalars['Boolean']>;
  itemNumber?: InputMaybe<Scalars['String']>;
  packagingId?: InputMaybe<Scalars['ID']>;
  productId?: InputMaybe<Scalars['ID']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  productName?: InputMaybe<Scalars['String']>;
  searchField?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<BatchState>;
  to?: InputMaybe<Scalars['Date']>;
};

export type BatchList = {
  count: Scalars['Int'];
  items: Array<Batch>;
  nextToken?: Maybe<Scalars['ID']>;
  pages: Array<Scalars['ID']>;
};

export enum BatchState {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
}

export type BatchesInput = {
  batchFilter?: InputMaybe<BatchFilter>;
  preferences?: InputMaybe<BatchesPreference>;
};

export type BatchesPreference = {
  exclusiveBatchTime?: InputMaybe<Scalars['Boolean']>;
};

export type Breadcrumb = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Call = {
  action: ActionMap;
  id: Scalars['ID'];
  lastMessage?: Maybe<Scalars['Date']>;
  /** The line from which this call originates if it still exists. */
  line?: Maybe<Line>;
  lineId: Scalars['ID'];
  /** @deprecated Will be replaced by `role.id`. */
  requestedSupport?: Maybe<Scalars['String']>;
  role?: Maybe<AndonRole>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  urgency: Urgency;
  workOrder?: Maybe<WorkOrder>;
};

export type Camera = Peripheral & {
  _id?: Maybe<Scalars['ID']>;
  attachedSensors: Array<Maybe<Sensor>>;
  description: Scalars['String'];
  /** @deprecated Use `peripheralId` instead. */
  id: Scalars['ID'];
  index: Scalars['ID'];
  name: Scalars['String'];
  offlineStatus: OfflineStatus;
  owner?: Maybe<Scalars['ID']>;
  peripheralId: Scalars['ID'];
  peripheralType: PeripheralType;
  streamURL?: Maybe<Scalars['String']>;
};

export type CameraStreamUrlArgs = {
  startTime?: InputMaybe<Scalars['Date']>;
};

export type CameraConfigInput = {
  retentionPeriodHours?: InputMaybe<Scalars['Int']>;
};

export type ChangeoverStop = BaseStop & {
  _id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  countermeasure?: Maybe<Scalars['String']>;
  countermeasureInitials?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  initials?: Maybe<Scalars['String']>;
  isAutomaticRegistration?: Maybe<Scalars['Boolean']>;
  isMicroStop: Scalars['Boolean'];
  ongoing: Scalars['Boolean'];
  registeredTime?: Maybe<Scalars['Date']>;
  standalone?: Maybe<StandaloneConfiguration>;
  stopCause?: Maybe<StopCause>;
  target?: Maybe<ChangeoverTarget>;
  timeRange: TimeRange;
};

export type ChangeoverTarget = {
  nextBatch?: Maybe<MinimalBatch>;
  previousBatch?: Maybe<MinimalBatch>;
  shift?: Maybe<Shift>;
  target: Scalars['Float'];
};

export type ChartConfiguration = {
  dataFilter?: Maybe<DataFilter>;
  scale?: Maybe<ChartTimeScale>;
};

export type ChartConfigurationInput = {
  dataFilter?: InputMaybe<DataFilter>;
  scale?: InputMaybe<ChartTimeScale>;
};

export enum ChartTimeScale {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
}

export type Claim = {
  id: Scalars['ClaimId'];
  name: Scalars['String'];
};

export type ClaimDeviceInput = {
  hardwareId: Scalars['ID'];
};

export type Claims = {
  index: Array<Claim>;
  resources: Array<Resource>;
};

export type ClosedTimeRange = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type Company = {
  group: Group;
  groups: Array<Group>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  users: UserList;
};

export type CompanyGroupArgs = {
  groupId: Scalars['ID'];
};

export type CompanyUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<TokenInput>;
};

export type ConsolidatedData = {
  oee?: Maybe<Oee>;
  stopsData?: Maybe<Array<Maybe<LineStopStats>>>;
  timeRange: TimeRange;
};

export type ConsolidatedDataOeeArgs = {
  includeScrap?: InputMaybe<Scalars['Boolean']>;
};

export enum ControlDerivationFormula {
  AVERAGE = 'AVERAGE',
  MAX = 'MAX',
  MIN = 'MIN',
  SUM = 'SUM',
}

export enum ControlFieldType {
  BOOLEAN = 'BOOLEAN',
  DERIVED = 'DERIVED',
  NUMBER = 'NUMBER',
  SENSOR = 'SENSOR',
  TEXT = 'TEXT',
  WEIGHT = 'WEIGHT',
}

export type ControlReceipt = {
  attachedProducts: Array<Product>;
  controlReceiptId: Scalars['ID'];
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  entries: Array<ControlReceiptEntry>;
  name: Scalars['String'];
  userPoolId: Scalars['ID'];
};

export type ControlReceiptEntry = {
  entryId: Scalars['ID'];
  fields: Array<ControlReceiptEntryField>;
  followUp: FollowUpSettings;
  title?: Maybe<Scalars['String']>;
  trigger: ControlTrigger;
};

export type ControlReceiptEntryField = {
  derivationSettings?: Maybe<ControlReceiptEntryFieldDerivationSettings>;
  description?: Maybe<Scalars['String']>;
  fieldId: Scalars['ID'];
  label: Scalars['String'];
  limits?: Maybe<ControlReceiptEntryFieldLimits>;
  sensorId?: Maybe<Scalars['ID']>;
  type: ControlFieldType;
};

export type ControlReceiptEntryFieldDerivationSettings = {
  fields: Array<Scalars['String']>;
  formula: ControlDerivationFormula;
};

export type ControlReceiptEntryFieldDerivationSettingsInput = {
  fields: Array<Scalars['String']>;
  formula: ControlDerivationFormula;
};

export type ControlReceiptEntryFieldInput = {
  derivationSettings?: InputMaybe<ControlReceiptEntryFieldDerivationSettingsInput>;
  description?: InputMaybe<Scalars['String']>;
  fieldId: Scalars['String'];
  label: Scalars['String'];
  limits?: InputMaybe<ControlReceiptentryFieldLimitsInput>;
  sensorId?: InputMaybe<Scalars['ID']>;
  type: ControlFieldType;
};

export type ControlReceiptEntryFieldLimits = {
  lower?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
};

export type ControlReceiptEntryInput = {
  entryId?: InputMaybe<Scalars['String']>;
  fields: Array<ControlReceiptEntryFieldInput>;
  followUp: FollowUpSettingsInput;
  title?: InputMaybe<Scalars['String']>;
  trigger: ControlTriggerInput;
};

export type ControlReceiptFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type ControlReceiptList = {
  items: Array<ControlReceipt>;
  nextToken?: Maybe<Scalars['ID']>;
};

export type ControlReceiptentryFieldLimitsInput = {
  lower?: InputMaybe<Scalars['Float']>;
  upper?: InputMaybe<Scalars['Float']>;
};

export type ControlTrigger = {
  delayWhenStopped: Scalars['Boolean'];
  payload?: Maybe<TimedTriggerPayload>;
  type: ControlTriggerType;
};

export type ControlTriggerInput = {
  delayWhenStopped: Scalars['Boolean'];
  payload?: InputMaybe<TimedTriggerPayloadInput>;
  type: ControlTriggerType;
};

export enum ControlTriggerType {
  END_OF_BATCH = 'END_OF_BATCH',
  ONCE_AFTER_BATCH_START = 'ONCE_AFTER_BATCH_START',
  /** @deprecated Use `RECURRING_FROM_BATCH_START` instead. */
  RECURRING_EVERY_45_MIN_FROM_START = 'RECURRING_EVERY_45_MIN_FROM_START',
  /** @deprecated Use `RECURRING_FROM_BATCH_START` instead. */
  RECURRING_EVERY_FOUR_HOURS_FROM_START = 'RECURRING_EVERY_FOUR_HOURS_FROM_START',
  /** @deprecated Use `RECURRING_FROM_BATCH_START` instead. */
  RECURRING_EVERY_HALF_HOUR_FROM_START = 'RECURRING_EVERY_HALF_HOUR_FROM_START',
  /** @deprecated Use `RECURRING_FROM_BATCH_START` instead. */
  RECURRING_EVERY_HOUR_FROM_START = 'RECURRING_EVERY_HOUR_FROM_START',
  /** @deprecated Use `RECURRING_FROM_BATCH_START` instead. */
  RECURRING_EVERY_QUARTER_HOUR_FROM_START = 'RECURRING_EVERY_QUARTER_HOUR_FROM_START',
  /** @deprecated Use `RECURRING_FROM_BATCH_START` instead. */
  RECURRING_EVERY_TWO_HOURS_FROM_START = 'RECURRING_EVERY_TWO_HOURS_FROM_START',
  RECURRING_FROM_BATCH_START = 'RECURRING_FROM_BATCH_START',
  RECURRING_FROM_EPOCH = 'RECURRING_FROM_EPOCH',
  /** @deprecated Use `RECURRING_FROM_EPOCH` instead. */
  RECURRING_ON_EVERY_WHOLE_HOUR = 'RECURRING_ON_EVERY_WHOLE_HOUR',
  RECURRING_ON_SHIFT_END = 'RECURRING_ON_SHIFT_END',
  RECURRING_ON_SHIFT_START = 'RECURRING_ON_SHIFT_START',
  START_OF_BATCH = 'START_OF_BATCH',
}

export type CreateMaintenanceLogEntryInput = {
  comment: Scalars['String'];
  initials: Scalars['String'];
  peripheralId: Scalars['PeripheralId'];
  planId: Scalars['MaintenancePlanId'];
  status: WorkOrderStatus;
};

export type CreateMaintenancePlanInput = {
  asset: Scalars['String'];
  component: Scalars['String'];
  instructions?: InputMaybe<Scalars['String']>;
  peripheralId: Scalars['PeripheralId'];
  repeat: ShouldRepeat;
  startFrom: Scalars['DateTime'];
  tagPartNumber?: InputMaybe<Scalars['String']>;
  trackBy: TrackByOptionsInput;
};

export type CreateNodeInput = {
  parentId?: InputMaybe<Scalars['ID']>;
  refId?: InputMaybe<Scalars['ID']>;
};

export type CreatePeripheralInput = {
  description?: InputMaybe<Scalars['String']>;
  index: Scalars['String'];
  name: Scalars['String'];
};

/** * LEGACY STUFF BELOW */
export type CreateTaskConfigurationInput = {
  asset: Scalars['String'];
  component: Scalars['String'];
  firstMaintenance: Scalars['DateTime'];
  instructions?: InputMaybe<Scalars['String']>;
  peripheralId: Scalars['PeripheralId'];
  repeatOptions?: InputMaybe<RepeatOptionsInput>;
  tagPartNumber?: InputMaybe<Scalars['String']>;
};

export type CreateTaskLogEntryInput = {
  comment: Scalars['String'];
  initials: Scalars['String'];
  peripheralId: Scalars['PeripheralId'];
  status: TaskStatus;
  taskId: Scalars['TaskId'];
};

export type CustomKpi = {
  description: Scalars['String'];
  id: Scalars['ID'];
  kpiParameters?: Maybe<Array<KpiParameter>>;
  name: Scalars['String'];
  peripheralId: Scalars['ID'];
  templateParameters?: Maybe<Array<KpiParameter>>;
  type: CustomKpiType;
  unit: Scalars['String'];
};

export enum CustomKpiType {
  FORMULA = 'FORMULA',
}

export type CustomKpiValue = {
  id: Scalars['String'];
  name: Scalars['String'];
  unit: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type Data = {
  /**
   * Average produced over duration: Sum of value produced / time - scoped within
   * the requested time range.
   */
  averageProduced?: Maybe<Scalars['Float']>;
  /**
   * Average produced hour: Sum of value produced / time.
   * @deprecated Use averageProduced instead.
   */
  averageProducedHour?: Maybe<Scalars['Float']>;
  /**
   * Average produced minute: Sum of value produced / time.
   * @deprecated Use averageProduced instead.
   */
  averageProducedMinute?: Maybe<Scalars['Float']>;
  /** Average stop length: Average stop length. */
  averageStopLength?: Maybe<Scalars['Float']>;
  /** List of the results of custom KPIs on the peripheral */
  customKPIs?: Maybe<Array<CustomKpiValue>>;
  /** Cycle time: Time / Sum of value produced. */
  cycleTime?: Maybe<Scalars['Float']>;
  /** Downtime: Sum of duration stopped. */
  downtime?: Maybe<Scalars['Float']>;
  /** Line status, indicating if it's running or stopped, and for how long. */
  lineStatus?: Maybe<LineStatus>;
  /** Longest non-stop: Longest duration running. */
  longestNonStop?: Maybe<Scalars['Float']>;
  /** Meant Time Between Failures: (Total time - all stops) / Count of red stops. */
  mtbf?: Maybe<Scalars['Float']>;
  /** Number of stops: Count of all stops. */
  numberOfStops?: Maybe<Scalars['Int']>;
  /**
   * Number of unregistered stops: Count of all stops that have not been
   * registered.
   */
  numberOfUnregisteredStops?: Maybe<Scalars['Int']>;
  /** Breakdown of the OEE (Overall Equipment Efficiency). */
  oee?: Maybe<Oee>;
  /** Produced: Sum of value produced. */
  produced?: Maybe<Scalars['Float']>;
  /** Produced units pr. stop: Sum of value produced / Count of all stops. */
  producedPerStop?: Maybe<Scalars['Float']>;
  /** Number of units scrapped downstream */
  scrap?: Maybe<Scalars['Float']>;
  /** Speed while manned: Sum of value produced / (time - time spent on blue stops). */
  speedWhileManned?: Maybe<Scalars['Float']>;
  /**
   * Speed while producing: Sum of value produced / (time - time spent on blue
   * stops and the yellow stops).
   */
  speedWhileProducing?: Maybe<Scalars['Float']>;
  /** Value adding time: [Total time - stopped time] / Total time. */
  valueAddingTime?: Maybe<Scalars['Float']>;
  /**
   * Value adding time while manned: [(Total time - time spent on blue stops) -
   * (stopped time without time spent on blue stops)] / (Total time - time spent on
   * blue stops).
   */
  valueAddingTimeWhileManned?: Maybe<Scalars['Float']>;
  /**
   * Value adding time while operating: [(Total time - time spent on blue stops and
   * gray) - (stopped time without time spent on blue stops and gray)] / (Total
   * time - time spent on blue stops and gray).
   */
  valueAddingTimeWhileOperating?: Maybe<Scalars['Float']>;
};

export type DataAverageProducedArgs = {
  input: AverageProducedInput;
};

export type DataOeeArgs = {
  includeScrap?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<OeeInput>;
};

export type DataAlarm = {
  notificationFrequency?: Maybe<Scalars['Int']>;
  repeatNotification?: Maybe<Scalars['Boolean']>;
  subscribers: Array<Subscriber>;
  updateBuffer?: Maybe<Scalars['Int']>;
};

export type DataAlarmInput = {
  notificationFrequency?: InputMaybe<Scalars['Int']>;
  repeatNotification?: InputMaybe<Scalars['Boolean']>;
  subscribers: Array<SubscriberInput>;
  updateBuffer?: InputMaybe<Scalars['Int']>;
};

export enum DataFilter {
  AVERAGE_SPEED = 'AVERAGE_SPEED',
  NONE = 'NONE',
  SPEED = 'SPEED',
  UPTIME = 'UPTIME',
}

export type DataMultiplierPeriod = {
  dataMultiplier: Scalars['Float'];
  from: Scalars['Date'];
  intrinsicValue?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type DataOverride = {
  author: Scalars['String'];
  comment: Scalars['String'];
  peripheralId: Scalars['ID'];
  timeRange: TimeRange;
  value: Scalars['Int'];
};

export type DataOverrideUpsertInput = {
  author: Scalars['String'];
  comment: Scalars['String'];
  isTestPeriod?: InputMaybe<Scalars['Boolean']>;
  timeRange: InputTimeRange;
  value: Scalars['Int'];
};

export enum DayOfWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export type Device = {
  _id?: Maybe<Scalars['ID']>;
  hardwareId: Scalars['ID'];
  maintenanceUpcomingTasks: UpcomingTaskConnection;
  name?: Maybe<Scalars['String']>;
  network?: Maybe<NetworkConfig>;
  numInputPorts: Scalars['Int'];
  owner?: Maybe<Scalars['ID']>;
  peripheral?: Maybe<Peripheral>;
  peripherals: Array<Maybe<Peripheral>>;
  /** @deprecated Use `peripheral`, with inline fragments instead. */
  sensor?: Maybe<Sensor>;
  /** @deprecated Use `peripherals`, with inline fragments instead. */
  sensors: Array<Sensor>;
  status: DeviceStatus;
  type: Scalars['String'];
  uuid: Scalars['ID'];
};

export type DeviceMaintenanceUpcomingTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DevicePeripheralArgs = {
  index: Scalars['ID'];
};

export type DevicePeripheralsArgs = {
  indices?: InputMaybe<Array<Scalars['ID']>>;
  peripheralType?: InputMaybe<PeripheralType>;
};

export type DeviceSensorArgs = {
  index: Scalars['ID'];
};

export type DeviceSensorsArgs = {
  indices?: InputMaybe<Array<Scalars['ID']>>;
};

export type DeviceMetaInput = {
  name?: InputMaybe<Scalars['String']>;
  wifiEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceStatus = {
  firmwareVersions?: Maybe<FirmwareVersions>;
  hardwareVersion?: Maybe<Scalars['String']>;
};

export type DiscreteConfig = {
  defaultState: DiscreteDeviceState;
  offStates: Array<Scalars['Int']>;
  onStates: Array<Scalars['Int']>;
};

export type DiscreteConfigInput = {
  defaultState: DiscreteDeviceState;
  offStates: Array<Scalars['Int']>;
  onStates: Array<Scalars['Int']>;
};

export enum DiscreteDeviceState {
  OFF = 'OFF',
  ON = 'ON',
}

export enum DocumentFormat {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
  PDF = 'PDF',
}

export type DocumentInputAnalytics = {
  documentType: AnalyticsDocumentType;
  filter: Scalars['String'];
  format: DocumentFormat;
};

export type DocumentInputBatches = {
  filter: Scalars['String'];
  format: DocumentFormat;
  total: Scalars['Int'];
};

export type DocumentInputHeaders = {
  language?: InputMaybe<Scalars['String']>;
  timeRange?: InputMaybe<InputTimeRange>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type DocumentInputLineOee = {
  oeeDocumentType: OeeDocumentType;
};

export type DocumentInputLinesGroupOee = {
  isDynamic: Scalars['Boolean'];
};

export type DocumentInputLiveData = {
  chartScale?: InputMaybe<ChartTimeScale>;
  documentType: LiveDataDocumentType;
  format: DocumentFormat;
  points: Scalars['Int'];
  sensorType?: InputMaybe<SensorType>;
  time: Array<InputTimeRange>;
  unitLabel?: InputMaybe<Scalars['String']>;
};

export type DownloadVideoClip = {
  videoClipUrl: Scalars['String'];
};

export enum EntityType {
  GROUP = 'GROUP',
  LINE = 'LINE',
  SENSOR = 'SENSOR',
}

export type EscalationConfiguration = {
  delay?: Maybe<Scalars['Float']>;
  takenDelay?: Maybe<Scalars['Float']>;
  type: SubscriptionType;
};

export type EscalationConfigurationInput = {
  delay?: InputMaybe<Scalars['Float']>;
  takenDelay?: InputMaybe<Scalars['Float']>;
  type: SubscriptionType;
};

export type EventActionConfiguration = {
  actions: Array<Actions>;
  peripheralId: Scalars['ID'];
  value: Scalars['String'];
};

export type Extension = {
  service: Scalars['String'];
  type: ExtensionType;
};

export enum ExtensionType {
  WORK_ORDER = 'WORK_ORDER',
}

export type FeatureItem = {
  feature: Array<FeatureValue>;
  id: Scalars['String'];
};

export type FeatureList = {
  group: Array<FeatureItem>;
  line: Array<FeatureItem>;
  peripheral: Array<FeatureItem>;
  userPool: Array<FeatureItem>;
};

export type FeatureValue = {
  M: Scalars['Boolean'];
  Q: Scalars['Boolean'];
  key: Scalars['String'];
};

export type FirmwareVersions = {
  application?: Maybe<Scalars['String']>;
  bootloader?: Maybe<Scalars['String']>;
  cellular?: Maybe<Scalars['String']>;
  wifi?: Maybe<Scalars['String']>;
};

export type FollowUpSettings = {
  delayMs?: Maybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
};

export type FollowUpSettingsInput = {
  delayMs?: InputMaybe<Scalars['Int']>;
  enabled: Scalars['Boolean'];
};

export type GenerateApiTokenInput = {
  name: Scalars['String'];
  ttl?: InputMaybe<Scalars['Float']>;
  userSub?: InputMaybe<Scalars['ID']>;
};

export type GeneratedApiToken = {
  /** A description of what this API token is used for. */
  description?: Maybe<Scalars['String']>;
  /** The expiration duration of a token if set to expire. */
  expiration?: Maybe<Scalars['Float']>;
  /** The date this token was issued. */
  generatedAt: Scalars['Date'];
  /** Whether the token is active */
  isActive: Scalars['Boolean'];
  /** The name of the API token, visible to the user. */
  name: Scalars['String'];
  /** The API token value, only exposed upon creation. */
  token: Scalars['String'];
  /** The owner (user) of this token. */
  userSub: Scalars['ID'];
};

export type GeneratedExportDocument = {
  description?: Maybe<Scalars['String']>;
  generatedAt: Scalars['Date'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type GeneratedReport = {
  description?: Maybe<Scalars['String']>;
  entityId: Scalars['ID'];
  entityType: EntityType;
  generatedAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  s3ObjectKey: Scalars['String'];
  scheduledReport?: Maybe<ScheduledReport>;
  subscribers: Array<ReportSubscriber>;
  type: ReportType;
};

export type Group = {
  defaultGroup?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  externalIds: Array<Scalars['String']>;
  id: Scalars['ID'];
  lineIds: Array<Scalars['ID']>;
  lines: Array<Maybe<Line>>;
  name: Scalars['String'];
  owner: Company;
  peripheralIds: Array<Scalars['ID']>;
  peripherals: Array<Maybe<Peripheral>>;
  role: Role;
  scheduledReports: Array<ScheduledReport>;
  /** @deprecated Use `peripherals`, with inline fragments instead. */
  sensors: Array<Maybe<Sensor>>;
  users: UserList;
};

export type GroupPeripheralsArgs = {
  peripheralType?: InputMaybe<PeripheralType>;
};

export type GroupUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<Array<TokenInput>>;
};

export type GroupAttributes = {
  description?: InputMaybe<Scalars['String']>;
  externalIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
};

export type HorizontalAnnotation = {
  axisValue: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type HorizontalAnnotationInput = {
  axisValue: Scalars['String'];
  label: Scalars['String'];
};

export type IWorker = {
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  preferredSchedules: Array<AndonSchedule>;
  /** @deprecated Workers may now reference multiple roles. */
  role: AndonRole;
  roles: Array<AndonRole>;
};

export type InputBatch = {
  actualStart: Scalars['Date'];
  actualStop?: InputMaybe<Scalars['Date']>;
  manualScrap?: InputMaybe<Scalars['Float']>;
  product: InputProduct;
  samples?: InputMaybe<Array<InputSample>>;
  scrap?: InputMaybe<Array<InputSample>>;
  stopStats?: InputMaybe<InputStopStats>;
};

export type InputConfigs = {
  dataMultiplier?: InputMaybe<Scalars['Float']>;
  expectedSpeed?: InputMaybe<Scalars['Float']>;
  stopRegisterThreshold?: InputMaybe<Scalars['Float']>;
  stopThreshold?: InputMaybe<Scalars['Float']>;
  stopValueThreshold?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<SensorType>;
  validFrom: Scalars['Date'];
  validatedSpeed?: InputMaybe<Scalars['Float']>;
};

export type InputCustomKpi = {
  description: Scalars['String'];
  id: Scalars['ID'];
  kpiParameters?: InputMaybe<Array<InputParamaters>>;
  name: Scalars['String'];
  peripheralId: Scalars['ID'];
  templateParameters?: InputMaybe<Array<InputParamaters>>;
  type: CustomKpiType;
  unit: Scalars['String'];
};

export type InputOfflineStatus = {
  expiration?: InputMaybe<Scalars['Float']>;
  lastReceived: Scalars['Float'];
};

export type InputParamaters = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type InputParameter = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type InputProduct = {
  dataMultiplier: Scalars['Float'];
  validatedLineSpeed: Scalars['Float'];
};

export type InputSample = {
  data: InputSampleData;
  timeRange: Time;
};

export type InputSampleData = {
  accValue?: InputMaybe<Scalars['Float']>;
  maxValue?: InputMaybe<Scalars['Float']>;
  minValue?: InputMaybe<Scalars['Float']>;
};

export type InputStatsData = {
  batches?: InputMaybe<Array<InputBatch>>;
  configs?: InputMaybe<Array<InputConfigs>>;
  offlineStatus?: InputMaybe<InputOfflineStatus>;
  peripheralId: Scalars['ID'];
  samples?: InputMaybe<Array<InputSample>>;
  samplesWhilePrimaryRuns?: InputMaybe<Array<InputSample>>;
  scrap?: InputMaybe<Array<InputSample>>;
  sensorType?: InputMaybe<SensorType>;
  stopStats?: InputMaybe<InputStopStats>;
  time: Time;
  validatedSpeed?: InputMaybe<Scalars['Float']>;
};

export type InputStop = {
  _id: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  duration: Scalars['Float'];
  initials?: InputMaybe<Scalars['String']>;
  isMicroStop?: InputMaybe<Scalars['Boolean']>;
  ongoing?: InputMaybe<Scalars['Boolean']>;
  registeredTime?: InputMaybe<Scalars['Date']>;
  stopCause?: InputMaybe<InputStopCause>;
  timeRange: Time;
};

export type InputStopCause = {
  _id?: InputMaybe<Scalars['ID']>;
  categoryName?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  stopType: StopType;
};

export type InputStopCauseStat = {
  accDuration: Scalars['Float'];
  accTarget: Scalars['Float'];
  isMicroStop: Scalars['Boolean'];
  numberOfStops: Scalars['Int'];
  stopCategoryName: Scalars['String'];
  stopCauseDescription: Scalars['String'];
  stopCauseId: Scalars['ID'];
  stopCauseName: Scalars['String'];
  type?: InputMaybe<StopType>;
};

export type InputStopStats = {
  lastStop?: InputMaybe<InputStop>;
  longestNonStop?: InputMaybe<Scalars['Float']>;
  stopCauseStats: Array<InputStopCauseStat>;
};

export type InputTimeRange = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type KpiParameter = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Line = {
  andonSchedules: Array<AndonSchedule>;
  batch: Batch;
  batches: BatchList;
  /** A description of the line. */
  description?: Maybe<Scalars['String']>;
  /** The edges between the nodes. */
  edges: Array<LineEdge>;
  /** The identifier of the line. */
  id: Scalars['ID'];
  /** Flag if the line is currently in a test. */
  isInTestMode: Scalars['Boolean'];
  /** The language code of the line metadata. */
  languageCode: Scalars['String'];
  location: Location;
  /** The peripheral Id of the main node. */
  mainPeripheralId: Scalars['ID'];
  mainSensor?: Maybe<Sensor>;
  /** The name of the line. */
  name: Scalars['String'];
  /** The nodes that make of the line. */
  nodes: Array<LineNode>;
  /** TODO: Implement this bad boi */
  owner?: Maybe<Scalars['ID']>;
  packagings: PackagingList;
  products: ProductList;
  schedule?: Maybe<Schedule>;
  scheduledReports: Array<ScheduledReport>;
  /** Test mode information for the line. */
  tests: Array<Test>;
  time?: Maybe<Array<Maybe<LineTimeData>>>;
};

export type LineBatchArgs = {
  batchId: Scalars['ID'];
};

export type LineBatchesArgs = {
  filter?: InputMaybe<BatchFilter>;
  maxItems?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['ID']>;
};

export type LinePackagingsArgs = {
  filter?: InputMaybe<PackagingFilter>;
  maxItems?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['ID']>;
};

export type LineProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
  paginationToken?: InputMaybe<Scalars['ID']>;
};

export type LineScheduleArgs = {
  validIn: ScheduleTimeInput;
};

export type LineTimeArgs = {
  time: Array<Time>;
};

export type LineEdge = {
  from?: Maybe<Scalars['ID']>;
  to: Scalars['ID'];
};

export type LineEdgeInput = {
  from?: InputMaybe<Scalars['ID']>;
  to: Scalars['ID'];
};

export type LineFilterInput = {
  /** Apply a filter on the line descriptions. */
  lineDescription?: InputMaybe<Scalars['String']>;
  /** Apply a filter on the line names. */
  lineName?: InputMaybe<Scalars['String']>;
  /** Filter nodes that are upstream/downstream from main peripheral */
  nodePosition?: InputMaybe<NodePosition>;
  /** Apply a filter on the node type. */
  nodeType?: InputMaybe<NodeType>;
  /** Apply a filter on tests, to only look after a certain time. */
  testTimeRange?: InputMaybe<TimeRangeInput>;
};

export type LineNode = {
  /** @deprecated Use `peripheralId` instead. */
  id: Scalars['ID'];
  peripheralId: Scalars['ID'];
  sensor?: Maybe<Sensor>;
  type: NodeType;
};

export type LineNodeInput = {
  nodeId?: InputMaybe<Scalars['ID']>;
  peripheralId: Scalars['ID'];
  type: NodeType;
};

export type LineStatus = {
  cause?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  status?: Maybe<Status>;
};

export type LineStopStats = {
  lineId: Scalars['ID'];
  lineName: Scalars['String'];
  stopStats: StopStats;
};

export type LineTimeData = {
  _id?: Maybe<Scalars['ID']>;
  alarmLogs: AlarmLogs;
  batches: BatchList;
  changeoverStops: Array<Maybe<ChangeoverStop>>;
  configs: Array<Maybe<SensorConfig>>;
  dataOverrides: Array<DataOverride>;
  isTest: Scalars['Boolean'];
  pendingControls: BatchControlList;
  samples?: Maybe<Array<Maybe<Sample>>>;
  scrap: Array<Sample>;
  stats: Stat;
  stopStats: StopStats;
  stops: Array<Maybe<Stop>>;
  timeRange: TimeRange;
};

export type LineTimeDataAlarmLogsArgs = {
  exclusiveStartKey?: InputMaybe<AlarmLogExclusiveStartKey>;
  filter?: InputMaybe<AlarmLogFilter>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type LineTimeDataBatchesArgs = {
  clip?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BatchFilter>;
  paginationToken?: InputMaybe<Scalars['ID']>;
};

export type LineTimeDataChangeoverStopsArgs = {
  filter?: InputMaybe<StopFilter>;
  languageCode?: InputMaybe<Scalars['String']>;
};

export type LineTimeDataPendingControlsArgs = {
  maxItems?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['ID']>;
};

export type LineTimeDataSamplesArgs = {
  points?: InputMaybe<Scalars['Int']>;
  raw?: InputMaybe<Scalars['Boolean']>;
  requestRaw?: InputMaybe<Scalars['Boolean']>;
  resample?: InputMaybe<Scalars['Boolean']>;
};

export type LineTimeDataStatsArgs = {
  input?: InputMaybe<StatsInput>;
};

export type LineTimeDataStopStatsArgs = {
  filter?: InputMaybe<StopFilter>;
};

export type LineTimeDataStopsArgs = {
  filter?: InputMaybe<StopFilter>;
  languageCode?: InputMaybe<Scalars['String']>;
};

export enum LiveDataDocumentType {
  CHART = 'CHART',
  KPI = 'KPI',
}

export type Location = {
  timeZone?: Maybe<Scalars['String']>;
};

export type MaintenanceEvent = {
  attendees: Array<Attendee>;
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  id: Scalars['ID'];
  lineId: Scalars['ID'];
  rRuleSet: Scalars['String'];
  summary: Scalars['String'];
};

export type MaintenanceFilter = {
  peripheralIds: Array<Scalars['PeripheralId']>;
};

export type MaintenanceLogEntry = {
  comment: Scalars['String'];
  initials: Scalars['String'];
  onTime: Scalars['Boolean'];
  peripheral: Peripheral;
  peripheralId: Scalars['PeripheralId'];
  planId: Scalars['MaintenancePlanId'];
  snapshot: MaintenancePlanSnapshot;
  status: WorkOrderStatus;
  timestamp: Scalars['DateTime'];
};

export type MaintenanceLogEntryConnection = {
  /** A list of edges. */
  edges: Array<MaintenanceLogEntryEdge>;
  /** A list of nodes. */
  nodes: Array<MaintenanceLogEntry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MaintenanceLogEntryEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: MaintenanceLogEntry;
};

export type MaintenancePlan = {
  asset: Scalars['String'];
  component: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  log: MaintenanceLogEntryConnection;
  peripheral: Peripheral;
  peripheralId: Scalars['PeripheralId'];
  planId: Scalars['MaintenancePlanId'];
  repeat: ShouldRepeat;
  startFrom: Scalars['DateTime'];
  tagPartNumber?: Maybe<Scalars['String']>;
  trackBy: TrackByOptions;
  version: Scalars['Int'];
};

export type MaintenancePlanLogArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MaintenancePlanConnection = {
  /** A list of edges. */
  edges: Array<MaintenancePlanEdge>;
  /** A list of nodes. */
  nodes: Array<MaintenancePlan>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MaintenancePlanEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: MaintenancePlan;
};

export type MaintenancePlanSnapshot = {
  asset: Scalars['String'];
  component: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  tagPartNumber?: Maybe<Scalars['String']>;
  trackBy: TrackByOptions;
};

export type MaintenanceSchedule = {
  events: Array<MaintenanceEvent>;
};

export type MaintenanceScheduleEventsArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type MaintenanceWorkOrder = {
  asset: Scalars['String'];
  component: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  latestMaintenance?: Maybe<MaintenanceLogEntry>;
  peripheral: Peripheral;
  peripheralId: Scalars['PeripheralId'];
  planId: Scalars['MaintenancePlanId'];
  tagPartNumber?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  trackBy: TrackByOptions;
};

export type MaintenanceWorkOrderConnection = {
  /** A list of edges. */
  edges: Array<MaintenanceWorkOrderEdge>;
  /** A list of nodes. */
  nodes: Array<MaintenanceWorkOrder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MaintenanceWorkOrderEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: MaintenanceWorkOrder;
};

export type Message = {
  delay: Scalars['Float'];
  id: Scalars['ID'];
  takenDelay?: Maybe<Scalars['Float']>;
  type: SubscriptionType;
};

export type MinimalBatch = BaseBatch & {
  actualStart?: Maybe<Scalars['Date']>;
  actualStop?: Maybe<Scalars['Date']>;
  batchId: Scalars['ID'];
  batchNumber: Scalars['String'];
  plannedStart?: Maybe<Scalars['Date']>;
  product: MinimalProduct;
};

export type MinimalProduct = BaseProduct & {
  expectedAverageSpeed: Scalars['Float'];
  itemNumber: Scalars['String'];
  name: Scalars['String'];
  parameters: Array<Parameter>;
  productId: Scalars['ID'];
  validatedLineSpeed: Scalars['Float'];
};

export type Mutation = {
  addHorizontalAnnotation: HorizontalAnnotation;
  addLineToGroups?: Maybe<Scalars['ID']>;
  addPeripheralToGroups?: Maybe<Scalars['ID']>;
  addUserToGroups?: Maybe<User>;
  addVerticalAnnotation: VerticalAnnotation;
  andonAttendanceDowngrade: Array<AndonShift>;
  andonAttendanceUpgrade: Array<AndonShift>;
  andonCallCancel: Scalars['ID'];
  andonCallCreate: Call;
  andonCallRelease: Call;
  andonCallReopen: Call;
  andonCallResolve: Call;
  andonCallTake: Call;
  andonCallUpdateTags: Call;
  andonCallUpgrade: Call;
  andonCallWorkOrderInitiate: Call;
  andonRoleCreate: AndonRole;
  andonRoleDelete: Array<AndonRole>;
  andonRoleUpdate: AndonRole;
  andonScheduleCreate: AndonSchedule;
  andonScheduleDelete: Scalars['Boolean'];
  andonScheduleEventAttend: MaintenanceEvent;
  andonScheduleEventAttendeeAddMessage: Array<Message>;
  andonScheduleEventAttendeeDeleteMessage: Array<Message>;
  andonScheduleEventCreate: MaintenanceEvent;
  andonScheduleEventDelete: Scalars['Boolean'];
  andonScheduleEventUnattend: MaintenanceEvent;
  andonScheduleEventUpdate: MaintenanceEvent;
  andonScheduleUpdate: AndonSchedule;
  andonShiftCreate: Array<AndonShift>;
  andonShiftDelete: Scalars['Boolean'];
  andonShiftUpdate: Array<AndonShift>;
  andonSupportCreate: SupportType;
  andonSupportDelete: Array<SupportType>;
  andonSupportUpdate: SupportType;
  andonTagCreate?: Maybe<Tag>;
  andonTagUpdate?: Maybe<Tag>;
  andonWorkerCreate: Worker;
  andonWorkerDelete: Array<Worker>;
  andonWorkerUpdate: Worker;
  attachCamera: Camera;
  attachControlReceipt: Product;
  batchSetAlarmEnablement: Scalars['ID'];
  cancelBatchControl: BatchControl;
  /** @deprecated Use `cancelPendingBatchControls` instead. */
  cancelPendingBatchControl: BatchControl;
  cancelPendingBatchControls: Array<BatchControl>;
  claimDevice: Device;
  createAlarm: Alarm;
  createAnalyticsDocument: GeneratedExportDocument;
  createBatch: Batch;
  createBatchesDocument: GeneratedExportDocument;
  createControlReceipt: ControlReceipt;
  /** Create a new edge. @iam(key: "Lines.Line") */
  createEdge: LineEdge;
  createExportStopsDocument: GeneratedExportDocument;
  createGroup?: Maybe<Group>;
  createGroupOEEDocument: GeneratedExportDocument;
  createLineWithTopology: Line;
  createLiveDataDocument: GeneratedExportDocument;
  createMaintenanceLogEntry: MaintenanceLogEntry;
  createMaintenancePlan: MaintenancePlan;
  /** Create a new node. @iam(key: "Lines.Line") */
  createNode: LineNode;
  createOEEDocument: GeneratedExportDocument;
  createPackaging: Packaging;
  createPeripheral?: Maybe<Peripheral>;
  /** Create a pre-signed URL for uploading documents for import. */
  createPreSignedURL: PreSignedUrl;
  createProduct: Product;
  createRole?: Maybe<Role>;
  createScheduledReportForGroup: ScheduledReport;
  createScheduledReportForLine: ScheduledReport;
  createScheduledReportForSensor: ScheduledReport;
  createStopCause?: Maybe<Array<StopCauseCategory>>;
  createStopCauseCategory?: Maybe<Array<StopCauseCategory>>;
  /** * METHODS BELOW HERE ARE LEGACY */
  createTaskConfiguration: TaskConfiguration;
  createTaskLogEntry: TaskLogEntry;
  /** Create a new test on the line. @iam(key: "Lines.Test") */
  createTest: Test;
  createTreeNode: Node;
  createUser?: Maybe<User>;
  dataOverrideDelete: DataOverride;
  dataOverrideUpsert: DataOverride;
  deleteAlarm: Scalars['Int'];
  deleteBatch: Scalars['ID'];
  deleteControlReceipt: Scalars['ID'];
  deleteDevice: Scalars['Boolean'];
  /** Delete an existing edge. @iam(key: "Lines.Line") */
  deleteEdge: Array<LineEdge>;
  deleteEventActionConfiguration: Scalars['Boolean'];
  deleteGroup?: Maybe<Scalars['ID']>;
  /** Delete an existing line. @iam(key: "Lines.Line.delete") */
  deleteLine: Scalars['Boolean'];
  /** Delete an existing line translation (cannot be the main main language). @iam(key: "Lines.Line") */
  deleteLineTranslation: Scalars['Boolean'];
  deleteMaintenancePlan: MaintenancePlan;
  /** Delete an existing node and return the line with the node and its edges removed. @iam(key: "Lines.Line") */
  deleteNode: Array<LineNode>;
  deletePackaging: Scalars['ID'];
  deletePeripheral: Scalars['Boolean'];
  deleteProduct: Scalars['ID'];
  deleteRole?: Maybe<Scalars['ID']>;
  deleteSchedule: Scalars['Boolean'];
  deleteScheduledReportForGroup: Scalars['Int'];
  deleteScheduledReportForLine: Scalars['Int'];
  deleteScheduledReportForSensor: Scalars['Int'];
  deleteStopCause?: Maybe<Array<Maybe<StopCauseCategory>>>;
  deleteStopCauseCategory?: Maybe<Array<StopCauseCategory>>;
  deleteTarget: Scalars['Boolean'];
  deleteTaskConfiguration: TaskConfiguration;
  /** Delete an existing test. @iam(key: "Lines.Test") */
  deleteTest: Scalars['Boolean'];
  /** Delete an existing test translation (cannot be the main main language). @iam(key: "Lines.Test") */
  deleteTestTranslation: Scalars['Boolean'];
  deleteUser?: Maybe<User>;
  detachCamera: Camera;
  detachControlReceipt: Product;
  disableUser: UserSub;
  downloadVideoClip: DownloadVideoClip;
  enableBatchImportKey: Scalars['ID'];
  enableUser: UserSub;
  forgetWiFi: Scalars['Boolean'];
  /** Generate an API token for the user as specified from the input. */
  generateAPIToken: GeneratedApiToken;
  importStopCauseCategories?: Maybe<Scalars['Boolean']>;
  manuallyTriggerBatchControl: BatchControl;
  modifyStopCause?: Maybe<Array<StopCauseCategory>>;
  modifyStopCauseCategory?: Maybe<Array<StopCauseCategory>>;
  /** Promote an existing node to be the main. If oldMainType is not specified, it will default to 'Counter'. @iam(key: "Lines.Line") */
  promoteNodeToMain: Array<LineNode>;
  registerStop: Array<Stop>;
  removeHorizontalAnnotation: Scalars['Boolean'];
  removeLineFromGroups?: Maybe<Scalars['ID']>;
  removePeripheralFromGroups?: Maybe<Scalars['ID']>;
  removeUserFromGroups?: Maybe<User>;
  removeVerticalAnnotation: Scalars['Boolean'];
  replacePeripherals: Scalars['Boolean'];
  resetUserPassword?: Maybe<UserSub>;
  /** Revoke a specific API token if permitted to do so. */
  revokeAPIToken: Scalars['Boolean'];
  setStopCauseCategoryOrder?: Maybe<Array<StopCauseCategory>>;
  setStopCauseOrder?: Maybe<Array<StopCauseCategory>>;
  snoozeAlarm: Scalars['Int'];
  swapPeripherals: Scalars['Boolean'];
  translateAlarm: Alarm;
  /** Translate an existing line to a new language. @iam(key: "Lines.Line") */
  translateLine: Line;
  /** Translate an existing test to a new language. @iam(key: "Lines.Test") */
  translateTest: Test;
  updateAlarm: Alarm;
  updateBatch: Batch;
  updateBatchControl: BatchControl;
  updateControlReceipt: ControlReceipt;
  updateDevice: Device;
  /** Update an existing edge. @iam(key: "Lines.Line") */
  updateEdge: Array<LineEdge>;
  updateGroup?: Maybe<Group>;
  /** Update an existing line and its nodes, in an existing language. @iam(key: "Lines.Line") */
  updateLine: Line;
  /** Update a line with the provided nodes and edges. @iam(key: "Lines.Line") */
  updateLineWithTopology: Line;
  updateMaintenancePlan: MaintenancePlan;
  /** Update an existing node. @iam(key: "Lines.Line") */
  updateNode: LineNode;
  updatePackaging: Packaging;
  updatePendingBatchControl: BatchControl;
  updatePeripheral?: Maybe<Peripheral>;
  updateProduct: Product;
  updateRole?: Maybe<Role>;
  updateSchedule: Schedule;
  updateScheduledReportForGroup: ScheduledReport;
  updateScheduledReportForLine: ScheduledReport;
  updateScheduledReportForSensor: ScheduledReport;
  /** @deprecated Use `updatePeripheral` instead. */
  updateSensor?: Maybe<Sensor>;
  updateStopCauseMapping: Scalars['Boolean'];
  updateSubscribers: Alarm;
  updateTaskConfiguration: TaskConfiguration;
  /** Update an existing test, in an existing language. @iam(key: "Lines.Test") */
  updateTest: Test;
  updateUser?: Maybe<User>;
  updateVerticalAnnotation: VerticalAnnotation;
  upsertConfiguration: ScheduleConfiguration;
  upsertEventActionConfigurations: EventActionConfiguration;
  upsertSchedule: Schedule;
  upsertTarget: Targets;
  upsertWiFi: Scalars['Boolean'];
};

export type MutationAddHorizontalAnnotationArgs = {
  horizontalAnnotation: HorizontalAnnotationInput;
  peripheralId: Scalars['ID'];
};

export type MutationAddLineToGroupsArgs = {
  companyId: Scalars['ID'];
  groupIds: Array<Scalars['ID']>;
  lineId: Scalars['ID'];
  peripheralIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationAddPeripheralToGroupsArgs = {
  companyId: Scalars['ID'];
  groupIds: Array<Scalars['ID']>;
  peripheralId: Scalars['ID'];
};

export type MutationAddUserToGroupsArgs = {
  companyId: Scalars['ID'];
  groupIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type MutationAddVerticalAnnotationArgs = {
  peripheralId: Scalars['ID'];
  verticalAnnotation: VerticalAnnotationInput;
};

export type MutationAndonAttendanceDowngradeArgs = {
  companyId: Scalars['ID'];
  input: AttendanceInput;
  scheduleId: Scalars['ID'];
  shiftId: Scalars['ID'];
};

export type MutationAndonAttendanceUpgradeArgs = {
  companyId: Scalars['ID'];
  input: AttendanceInput;
  scheduleId: Scalars['ID'];
  shiftId: Scalars['ID'];
};

export type MutationAndonCallCancelArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationAndonCallCreateArgs = {
  author: Scalars['String'];
  companyId: Scalars['ID'];
  details: Scalars['String'];
  lineId: Scalars['ID'];
  roleId?: InputMaybe<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
  urgency: Urgency;
};

export type MutationAndonCallReleaseArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationAndonCallReopenArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  resolutionTime: Scalars['Date'];
};

export type MutationAndonCallResolveArgs = {
  author: Scalars['String'];
  companyId: Scalars['ID'];
  details: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationAndonCallTakeArgs = {
  author?: InputMaybe<Scalars['String']>;
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationAndonCallUpdateTagsArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
};

export type MutationAndonCallUpgradeArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationAndonCallWorkOrderInitiateArgs = {
  author: Scalars['String'];
  callId: Scalars['ID'];
  companyId: Scalars['ID'];
  details: Scalars['String'];
  tagId: Scalars['ID'];
};

export type MutationAndonRoleCreateArgs = {
  companyId: Scalars['ID'];
  input: RoleCreateInput;
};

export type MutationAndonRoleDeleteArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationAndonRoleUpdateArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: RoleUpdateInput;
};

export type MutationAndonScheduleCreateArgs = {
  companyId: Scalars['ID'];
  input: ScheduleCreateInput;
};

export type MutationAndonScheduleDeleteArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationAndonScheduleEventAttendArgs = {
  companyId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  eventId: Scalars['ID'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  supportId: Scalars['ID'];
};

export type MutationAndonScheduleEventAttendeeAddMessageArgs = {
  attendeeId: Scalars['ID'];
  companyId: Scalars['ID'];
  delay?: InputMaybe<Scalars['Float']>;
  eventId: Scalars['ID'];
  takenDelay?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<SubscriptionType>;
};

export type MutationAndonScheduleEventAttendeeDeleteMessageArgs = {
  attendeeId: Scalars['ID'];
  companyId: Scalars['ID'];
  eventId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationAndonScheduleEventCreateArgs = {
  companyId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Float'];
  lineId: Scalars['ID'];
  rRuleSet: Scalars['String'];
  summary: Scalars['String'];
};

export type MutationAndonScheduleEventDeleteArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationAndonScheduleEventUnattendArgs = {
  attendeeId: Scalars['ID'];
  companyId: Scalars['ID'];
  date?: InputMaybe<Scalars['Date']>;
  eventId: Scalars['ID'];
};

export type MutationAndonScheduleEventUpdateArgs = {
  companyId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  lineId?: InputMaybe<Scalars['ID']>;
  rRuleSet?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
};

export type MutationAndonScheduleUpdateArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: ScheduleUpdateInput;
};

export type MutationAndonShiftCreateArgs = {
  companyId: Scalars['ID'];
  input: ShiftCreateInput;
  scheduleId: Scalars['ID'];
};

export type MutationAndonShiftDeleteArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  scheduleId: Scalars['ID'];
};

export type MutationAndonShiftUpdateArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: ShiftUpdateInput;
  scheduleId: Scalars['ID'];
};

export type MutationAndonSupportCreateArgs = {
  companyId: Scalars['ID'];
  languageCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MutationAndonSupportDeleteArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationAndonSupportUpdateArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  languageCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MutationAndonTagCreateArgs = {
  lineIds: Array<Scalars['ID']>;
  value: Scalars['String'];
};

export type MutationAndonTagUpdateArgs = {
  id: Scalars['ID'];
  lineIds?: InputMaybe<Array<Scalars['ID']>>;
  value?: InputMaybe<Scalars['String']>;
};

export type MutationAndonWorkerCreateArgs = {
  companyId: Scalars['ID'];
  input: WorkerCreateInput;
};

export type MutationAndonWorkerDeleteArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationAndonWorkerUpdateArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: WorkerUpdateInput;
};

export type MutationAttachCameraArgs = {
  peripheralId: Scalars['ID'];
  sensorPeripheralId: Scalars['ID'];
};

export type MutationAttachControlReceiptArgs = {
  controlReceiptId: Scalars['ID'];
  lineId: Scalars['ID'];
  productId: Scalars['ID'];
  userPoolId: Scalars['ID'];
};

export type MutationBatchSetAlarmEnablementArgs = {
  input: SetAlarmEnablementInput;
  treeId: Scalars['String'];
};

export type MutationCancelBatchControlArgs = {
  batchId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  controlId: Scalars['ID'];
  initials?: InputMaybe<Scalars['String']>;
  lineId: Scalars['ID'];
};

export type MutationCancelPendingBatchControlArgs = {
  batchId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  controlId: Scalars['ID'];
  initials?: InputMaybe<Scalars['String']>;
  lineId: Scalars['ID'];
};

export type MutationCancelPendingBatchControlsArgs = {
  batchId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  controlIds: Array<Scalars['ID']>;
  initials?: InputMaybe<Scalars['String']>;
  lineId: Scalars['ID'];
};

export type MutationClaimDeviceArgs = {
  input: ClaimDeviceInput;
};

export type MutationCreateAlarmArgs = {
  alarmConfiguration: AlarmConfigurationInput;
  description: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  peripheralId: Scalars['ID'];
  repeatNotification?: InputMaybe<Scalars['Boolean']>;
  snoozeDuration?: InputMaybe<Scalars['Int']>;
  subscribers?: InputMaybe<Array<SubscriberInput>>;
  threshold: Scalars['Int'];
  type: AlarmType;
};

export type MutationCreateAnalyticsDocumentArgs = {
  documentInputHeaders: DocumentInputHeaders;
  documentInputs: DocumentInputAnalytics;
  peripheralId: Scalars['ID'];
};

export type MutationCreateBatchArgs = {
  actualStart?: InputMaybe<Scalars['Date']>;
  actualStop?: InputMaybe<Scalars['Date']>;
  amount: Scalars['Float'];
  batchNumber: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  dataMultiplier?: InputMaybe<Scalars['Float']>;
  expectedAverageSpeed?: InputMaybe<Scalars['Float']>;
  forceStop?: InputMaybe<Scalars['Boolean']>;
  lineId: Scalars['ID'];
  manualScrap?: InputMaybe<Scalars['Float']>;
  plannedStart: Scalars['Date'];
  productId: Scalars['ID'];
  validatedLineSpeed?: InputMaybe<Scalars['Float']>;
};

export type MutationCreateBatchesDocumentArgs = {
  documentInputHeaders: DocumentInputHeaders;
  documentInputs: DocumentInputBatches;
  lineId: Scalars['ID'];
};

export type MutationCreateControlReceiptArgs = {
  description?: InputMaybe<Scalars['String']>;
  entries: Array<ControlReceiptEntryInput>;
  name: Scalars['String'];
  userPoolId: Scalars['ID'];
};

export type MutationCreateEdgeArgs = {
  edge: LineEdgeInput;
  lineId: Scalars['ID'];
};

export type MutationCreateExportStopsDocumentArgs = {
  documentInputHeaders: DocumentInputHeaders;
  peripheralId: Scalars['ID'];
};

export type MutationCreateGroupArgs = {
  attributes: GroupAttributes;
  companyId: Scalars['ID'];
};

export type MutationCreateGroupOeeDocumentArgs = {
  documentInputHeaders: DocumentInputHeaders;
  documentInputs: DocumentInputLinesGroupOee;
  groupId: Scalars['ID'];
};

export type MutationCreateLineWithTopologyArgs = {
  description?: InputMaybe<Scalars['String']>;
  edges: Array<LineEdgeInput>;
  groupId: Scalars['ID'];
  name: Scalars['String'];
  nodes: Array<LineNodeInput>;
};

export type MutationCreateLiveDataDocumentArgs = {
  documentInputHeaders: DocumentInputHeaders;
  documentInputs: DocumentInputLiveData;
  peripheralId: Scalars['ID'];
};

export type MutationCreateMaintenanceLogEntryArgs = {
  input: CreateMaintenanceLogEntryInput;
};

export type MutationCreateMaintenancePlanArgs = {
  input: CreateMaintenancePlanInput;
};

export type MutationCreateNodeArgs = {
  lineId: Scalars['ID'];
  node: LineNodeInput;
};

export type MutationCreateOeeDocumentArgs = {
  documentInputHeaders: DocumentInputHeaders;
  documentInputs: DocumentInputLineOee;
  lineId: Scalars['ID'];
};

export type MutationCreatePackagingArgs = {
  comment?: InputMaybe<Scalars['String']>;
  lineId: Scalars['ID'];
  name: Scalars['String'];
  packagingNumber: Scalars['String'];
  unit: Scalars['String'];
};

export type MutationCreatePeripheralArgs = {
  initialGroups?: InputMaybe<Array<Scalars['ID']>>;
  input: CreatePeripheralInput;
  softwareId: Scalars['ID'];
};

export type MutationCreatePreSignedUrlArgs = {
  peripheralId: Scalars['ID'];
};

export type MutationCreateProductArgs = {
  comment?: InputMaybe<Scalars['String']>;
  dataMultiplier: Scalars['Float'];
  expectedAverageSpeed: Scalars['Float'];
  itemNumber: Scalars['String'];
  lineId: Scalars['ID'];
  name: Scalars['String'];
  packagingId: Scalars['ID'];
  parameters?: InputMaybe<Array<InputParameter>>;
  validatedLineSpeed: Scalars['Float'];
};

export type MutationCreateRoleArgs = {
  attributes: RoleAttributes;
  companyId: Scalars['ID'];
};

export type MutationCreateScheduledReportForGroupArgs = {
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  groupId: Scalars['ID'];
  name: Scalars['String'];
  subscribers: Array<ReportSubscriberInput>;
  timezone: Scalars['String'];
  trigger: TriggerType;
  triggerParameters?: InputMaybe<Scalars['String']>;
  type: ReportType;
};

export type MutationCreateScheduledReportForLineArgs = {
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  lineId: Scalars['ID'];
  name: Scalars['String'];
  subscribers: Array<ReportSubscriberInput>;
  timezone: Scalars['String'];
  trigger: TriggerType;
  triggerParameters?: InputMaybe<Scalars['String']>;
  type: ReportType;
};

export type MutationCreateScheduledReportForSensorArgs = {
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  peripheralId: Scalars['ID'];
  subscribers: Array<ReportSubscriberInput>;
  timezone: Scalars['String'];
  trigger: TriggerType;
  triggerParameters?: InputMaybe<Scalars['String']>;
  type: ReportType;
};

export type MutationCreateStopCauseArgs = {
  description?: InputMaybe<Scalars['String']>;
  deviceOwner: Scalars['ID'];
  enableCountermeasure?: InputMaybe<Scalars['Boolean']>;
  groupId?: InputMaybe<Scalars['ID']>;
  legacyStopCauseId?: InputMaybe<Scalars['ID']>;
  meta?: InputMaybe<Array<StopCauseMetaInput>>;
  name?: InputMaybe<Scalars['String']>;
  peripheralId?: InputMaybe<Scalars['ID']>;
  requireComment?: InputMaybe<Scalars['Boolean']>;
  requireInitials?: InputMaybe<Scalars['Boolean']>;
  stopCauseCategoryId: Scalars['ID'];
  stopType: StopType;
  targetSetup?: InputMaybe<TargetSetupInput>;
};

export type MutationCreateStopCauseCategoryArgs = {
  deviceOwner: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  legacyCategoryId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  peripheralId?: InputMaybe<Scalars['ID']>;
};

export type MutationCreateTaskConfigurationArgs = {
  input: CreateTaskConfigurationInput;
};

export type MutationCreateTaskLogEntryArgs = {
  input: CreateTaskLogEntryInput;
};

export type MutationCreateTestArgs = {
  description: Scalars['String'];
  from: Scalars['Date'];
  lineId: Scalars['ID'];
  name: Scalars['String'];
  to: Scalars['Date'];
};

export type MutationCreateTreeNodeArgs = {
  input?: InputMaybe<CreateNodeInput>;
  treeId: Scalars['String'];
};

export type MutationCreateUserArgs = {
  attributes: UserAttributes;
  companyId: Scalars['ID'];
  groupIds?: InputMaybe<Array<Scalars['ID']>>;
  username: Scalars['String'];
};

export type MutationDataOverrideDeleteArgs = {
  from: Scalars['Date'];
  peripheralId: Scalars['ID'];
};

export type MutationDataOverrideUpsertArgs = {
  input: DataOverrideUpsertInput;
  peripheralId: Scalars['ID'];
};

export type MutationDeleteAlarmArgs = {
  alarmId: Scalars['ID'];
  peripheralId: Scalars['ID'];
};

export type MutationDeleteBatchArgs = {
  batchId: Scalars['ID'];
  lineId?: InputMaybe<Scalars['ID']>;
};

export type MutationDeleteControlReceiptArgs = {
  controlReceiptId: Scalars['ID'];
  userPoolId: Scalars['ID'];
};

export type MutationDeleteDeviceArgs = {
  uuid: Scalars['ID'];
};

export type MutationDeleteEdgeArgs = {
  edge: LineEdgeInput;
  lineId: Scalars['ID'];
};

export type MutationDeleteEventActionConfigurationArgs = {
  peripheralId: Scalars['ID'];
  value: Scalars['String'];
};

export type MutationDeleteGroupArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationDeleteLineArgs = {
  lineId: Scalars['ID'];
};

export type MutationDeleteLineTranslationArgs = {
  languageCode: Scalars['String'];
  lineId: Scalars['ID'];
};

export type MutationDeleteMaintenancePlanArgs = {
  peripheralId: Scalars['PeripheralId'];
  planId: Scalars['MaintenancePlanId'];
};

export type MutationDeleteNodeArgs = {
  lineId: Scalars['ID'];
  nodeId: Scalars['ID'];
};

export type MutationDeletePackagingArgs = {
  lineId: Scalars['ID'];
  packagingId: Scalars['ID'];
};

export type MutationDeletePeripheralArgs = {
  peripheralId: Scalars['ID'];
};

export type MutationDeleteProductArgs = {
  lineId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type MutationDeleteRoleArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationDeleteScheduleArgs = {
  isExceptionalWeek?: InputMaybe<Scalars['Boolean']>;
  lineId: Scalars['ID'];
  scheduleId: Scalars['ID'];
};

export type MutationDeleteScheduledReportForGroupArgs = {
  groupId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationDeleteScheduledReportForLineArgs = {
  id: Scalars['ID'];
  lineId: Scalars['ID'];
};

export type MutationDeleteScheduledReportForSensorArgs = {
  id: Scalars['ID'];
  peripheralId: Scalars['ID'];
};

export type MutationDeleteStopCauseArgs = {
  deviceOwner: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseId: Scalars['ID'];
};

export type MutationDeleteStopCauseCategoryArgs = {
  deviceOwner: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseCategoryId: Scalars['ID'];
};

export type MutationDeleteTargetArgs = {
  lineId: Scalars['ID'];
  targetId: Scalars['ID'];
};

export type MutationDeleteTaskConfigurationArgs = {
  peripheralId: Scalars['PeripheralId'];
  taskId: Scalars['TaskId'];
};

export type MutationDeleteTestArgs = {
  lineId: Scalars['ID'];
  originalFromDate: Scalars['Date'];
  testId: Scalars['ID'];
};

export type MutationDeleteTestTranslationArgs = {
  languageCode: Scalars['String'];
  lineId: Scalars['ID'];
  originalFromDate: Scalars['Date'];
  testId: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
  companyId: Scalars['ID'];
  username: Scalars['String'];
};

export type MutationDetachCameraArgs = {
  peripheralId: Scalars['ID'];
  sensorPeripheralId: Scalars['ID'];
};

export type MutationDetachControlReceiptArgs = {
  controlReceiptId: Scalars['ID'];
  lineId: Scalars['ID'];
  productId: Scalars['ID'];
  userPoolId: Scalars['ID'];
};

export type MutationDisableUserArgs = {
  companyId: Scalars['ID'];
  username: Scalars['String'];
};

export type MutationDownloadVideoClipArgs = {
  endTime?: InputMaybe<Scalars['Date']>;
  startTime: Scalars['Date'];
  uuid: Scalars['ID'];
};

export type MutationEnableUserArgs = {
  companyId: Scalars['ID'];
  username: Scalars['String'];
};

export type MutationForgetWiFiArgs = {
  input: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type MutationGenerateApiTokenArgs = {
  input: GenerateApiTokenInput;
};

export type MutationImportStopCauseCategoriesArgs = {
  objectKey?: InputMaybe<Scalars['String']>;
  peripheralId: Scalars['ID'];
};

export type MutationManuallyTriggerBatchControlArgs = {
  batchId: Scalars['ID'];
  controlId: Scalars['ID'];
  controlReceiptId: Scalars['ID'];
  lineId: Scalars['ID'];
};

export type MutationModifyStopCauseArgs = {
  deviceOwner: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  languageCode?: InputMaybe<Scalars['String']>;
  newDescription?: InputMaybe<Scalars['String']>;
  newEnableCountermeasure?: InputMaybe<Scalars['Boolean']>;
  newMeta?: InputMaybe<Array<StopCauseMetaInput>>;
  newName?: InputMaybe<Scalars['String']>;
  newRequireComment?: InputMaybe<Scalars['Boolean']>;
  newRequireInitials?: InputMaybe<Scalars['Boolean']>;
  newStopCauseCategory?: InputMaybe<NewStopCauseCategory>;
  newStopType?: InputMaybe<StopType>;
  newTargetSetup?: InputMaybe<TargetSetupInput>;
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseId: Scalars['ID'];
};

export type MutationModifyStopCauseCategoryArgs = {
  deviceOwner: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  languageCode?: InputMaybe<Scalars['String']>;
  newMeta?: InputMaybe<Array<StopCauseCategoryMetaInput>>;
  newName?: InputMaybe<Scalars['String']>;
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseCategoryId: Scalars['ID'];
};

export type MutationPromoteNodeToMainArgs = {
  lineId: Scalars['ID'];
  nodeId: Scalars['ID'];
  oldMainType?: InputMaybe<NodeType>;
};

export type MutationRegisterStopArgs = {
  comment?: InputMaybe<Scalars['String']>;
  countermeasure?: InputMaybe<Scalars['String']>;
  countermeasureInitials?: InputMaybe<Scalars['String']>;
  deviceOwner: Scalars['ID'];
  initials?: InputMaybe<Scalars['String']>;
  peripheralId: Scalars['ID'];
  standalone?: InputMaybe<StandaloneConfigurationInput>;
  stopCauseId?: InputMaybe<Scalars['ID']>;
  target?: InputMaybe<TargetInput>;
  time: Array<Time>;
};

export type MutationRemoveHorizontalAnnotationArgs = {
  horizontalAnnotationId: Scalars['ID'];
  peripheralId: Scalars['ID'];
};

export type MutationRemoveLineFromGroupsArgs = {
  companyId: Scalars['ID'];
  groupIds: Array<Scalars['ID']>;
  lineId: Scalars['ID'];
};

export type MutationRemovePeripheralFromGroupsArgs = {
  companyId: Scalars['ID'];
  groupIds: Array<Scalars['ID']>;
  peripheralId: Scalars['ID'];
};

export type MutationRemoveUserFromGroupsArgs = {
  companyId: Scalars['ID'];
  groupIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type MutationRemoveVerticalAnnotationArgs = {
  peripheralId: Scalars['ID'];
  timestamp?: InputMaybe<Scalars['Date']>;
  verticalAnnotationId: Scalars['ID'];
};

export type MutationReplacePeripheralsArgs = {
  input: ReplacePeripheralsInput;
};

export type MutationResetUserPasswordArgs = {
  companyId: Scalars['ID'];
  username: Scalars['String'];
};

export type MutationRevokeApiTokenArgs = {
  input: RevokeApiTokenInput;
};

export type MutationSetStopCauseCategoryOrderArgs = {
  deviceOwner: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseCategoryIdsInOrder: Array<Scalars['ID']>;
};

export type MutationSetStopCauseOrderArgs = {
  deviceOwner: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseCategoryId: Scalars['ID'];
  stopCauseIdsInOrder: Array<Scalars['ID']>;
};

export type MutationSnoozeAlarmArgs = {
  alarmId: Scalars['ID'];
  peripheralId: Scalars['ID'];
};

export type MutationSwapPeripheralsArgs = {
  input: SwapPeripheralsInput;
};

export type MutationTranslateAlarmArgs = {
  alarmId: Scalars['ID'];
  description: Scalars['String'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
  peripheralId: Scalars['ID'];
};

export type MutationTranslateLineArgs = {
  description: Scalars['String'];
  languageCode: Scalars['String'];
  lineId: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationTranslateTestArgs = {
  description: Scalars['String'];
  languageCode: Scalars['String'];
  lineId: Scalars['ID'];
  name: Scalars['String'];
  originalFromDate: Scalars['Date'];
  testId: Scalars['ID'];
};

export type MutationUpdateAlarmArgs = {
  alarmConfiguration?: InputMaybe<AlarmConfigurationInput>;
  alarmId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  peripheralId: Scalars['ID'];
  repeatNotification?: InputMaybe<Scalars['Boolean']>;
  snoozeDuration?: InputMaybe<Scalars['Int']>;
  subscribers?: InputMaybe<Array<SubscriberInput>>;
  threshold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<AlarmType>;
};

export type MutationUpdateBatchArgs = {
  actualStart?: InputMaybe<Scalars['Date']>;
  actualStop?: InputMaybe<Scalars['Date']>;
  amount?: InputMaybe<Scalars['Float']>;
  batchId: Scalars['ID'];
  batchNumber?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  dataMultiplier?: InputMaybe<Scalars['Float']>;
  expectedAverageSpeed?: InputMaybe<Scalars['Float']>;
  forceStop?: InputMaybe<Scalars['Boolean']>;
  lineId: Scalars['ID'];
  manualScrap?: InputMaybe<Scalars['Float']>;
  plannedStart?: InputMaybe<Scalars['Date']>;
  productId?: InputMaybe<Scalars['ID']>;
  validatedLineSpeed?: InputMaybe<Scalars['Float']>;
};

export type MutationUpdateBatchControlArgs = {
  batchId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  controlId: Scalars['ID'];
  fields?: InputMaybe<Array<BatchControlFieldInput>>;
  initials?: InputMaybe<Scalars['String']>;
  lineId: Scalars['ID'];
};

export type MutationUpdateControlReceiptArgs = {
  controlReceiptId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  entries?: InputMaybe<Array<ControlReceiptEntryInput>>;
  name?: InputMaybe<Scalars['String']>;
  updateRecipeAndPendingBatches?: InputMaybe<Scalars['Boolean']>;
  userPoolId: Scalars['ID'];
};

export type MutationUpdateDeviceArgs = {
  meta?: InputMaybe<DeviceMetaInput>;
  uuid: Scalars['ID'];
};

export type MutationUpdateEdgeArgs = {
  edge: LineEdgeInput;
  lineId: Scalars['ID'];
  originalEdge: LineEdgeInput;
};

export type MutationUpdateGroupArgs = {
  attributes: GroupAttributes;
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationUpdateLineArgs = {
  description?: InputMaybe<Scalars['String']>;
  languageCode: Scalars['String'];
  lineId: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationUpdateLineWithTopologyArgs = {
  description: Scalars['String'];
  edges: Array<LineEdgeInput>;
  languageCode: Scalars['String'];
  lineId: Scalars['ID'];
  name: Scalars['String'];
  nodes: Array<LineNodeInput>;
};

export type MutationUpdateMaintenancePlanArgs = {
  peripheralId: Scalars['PeripheralId'];
  planId: Scalars['MaintenancePlanId'];
  toUpdate: UpdateMaintenancePlanInput;
  version: Scalars['Int'];
};

export type MutationUpdateNodeArgs = {
  lineId: Scalars['ID'];
  node: LineNodeInput;
};

export type MutationUpdatePackagingArgs = {
  comment?: InputMaybe<Scalars['String']>;
  lineId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  packagingId: Scalars['ID'];
  packagingNumber?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePendingBatchControlArgs = {
  batchId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  controlId: Scalars['ID'];
  fields?: InputMaybe<Array<BatchControlFieldInput>>;
  initials?: InputMaybe<Scalars['String']>;
  lineId: Scalars['ID'];
};

export type MutationUpdatePeripheralArgs = {
  config?: InputMaybe<UpdateConfigInput>;
  meta?: InputMaybe<PeripheralMetaInput>;
  peripheralId: Scalars['ID'];
};

export type MutationUpdateProductArgs = {
  comment?: InputMaybe<Scalars['String']>;
  dataMultiplier?: InputMaybe<Scalars['Float']>;
  expectedAverageSpeed?: InputMaybe<Scalars['Float']>;
  itemNumber?: InputMaybe<Scalars['String']>;
  lineId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  packagingId?: InputMaybe<Scalars['ID']>;
  parameters?: InputMaybe<Array<InputParameter>>;
  productId: Scalars['ID'];
  updateBatches?: InputMaybe<Scalars['Boolean']>;
  validatedLineSpeed?: InputMaybe<Scalars['Float']>;
};

export type MutationUpdateRoleArgs = {
  attributes: RoleAttributes;
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationUpdateScheduleArgs = {
  configuration?: InputMaybe<ScheduleConfigurationInput>;
  lineId: Scalars['ID'];
  scheduleId: Scalars['ID'];
  shifts?: InputMaybe<Array<ShiftInput>>;
  weeklyTargets?: InputMaybe<TargetsInput>;
};

export type MutationUpdateScheduledReportForGroupArgs = {
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  subscribers?: InputMaybe<Array<ReportSubscriberInput>>;
  timezone?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<TriggerType>;
  triggerParameters?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ReportType>;
};

export type MutationUpdateScheduledReportForLineArgs = {
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lineId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  subscribers?: InputMaybe<Array<ReportSubscriberInput>>;
  timezone?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<TriggerType>;
  triggerParameters?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ReportType>;
};

export type MutationUpdateScheduledReportForSensorArgs = {
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  peripheralId: Scalars['ID'];
  subscribers?: InputMaybe<Array<ReportSubscriberInput>>;
  timezone?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<TriggerType>;
  triggerParameters?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ReportType>;
};

export type MutationUpdateSensorArgs = {
  newDescription?: InputMaybe<Scalars['String']>;
  newName?: InputMaybe<Scalars['String']>;
  newSensorConfig?: InputMaybe<SensorConfigInput>;
  peripheralId: Scalars['ID'];
};

export type MutationUpdateStopCauseMappingArgs = {
  createStopCauseMapping: Array<StopCauseMappingInput>;
  deleteStopCauseMapping: Array<StopCauseMappingInput>;
  deviceOwner: Scalars['ID'];
  peripheralId: Scalars['ID'];
};

export type MutationUpdateSubscribersArgs = {
  alarmId: Scalars['ID'];
  peripheralId: Scalars['ID'];
  subscribers: Array<SubscriberInput>;
};

export type MutationUpdateTaskConfigurationArgs = {
  peripheralId: Scalars['PeripheralId'];
  taskId: Scalars['TaskId'];
  toUpdate: UpdateTaskConfigurationInput;
  version: Scalars['Int'];
};

export type MutationUpdateTestArgs = {
  description?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Date']>;
  languageCode: Scalars['String'];
  lineId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  originalFromDate: Scalars['Date'];
  testId: Scalars['ID'];
  to?: InputMaybe<Scalars['Date']>;
};

export type MutationUpdateUserArgs = {
  attributes: UserAttributes;
  companyId: Scalars['ID'];
  groupIds?: InputMaybe<Array<Scalars['ID']>>;
  removedGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  username: Scalars['String'];
};

export type MutationUpdateVerticalAnnotationArgs = {
  newVerticalAnnotation: VerticalAnnotationInput;
  oldTimestamp: Scalars['Date'];
  peripheralId: Scalars['ID'];
  verticalAnnotationId: Scalars['ID'];
};

export type MutationUpsertConfigurationArgs = {
  configuration: ScheduleConfigurationInput;
  lineId: Scalars['ID'];
};

export type MutationUpsertEventActionConfigurationsArgs = {
  actions: Array<ActionsInput>;
  peripheralId: Scalars['ID'];
  value: Scalars['String'];
};

export type MutationUpsertScheduleArgs = {
  configuration?: InputMaybe<ScheduleConfigurationInput>;
  isExceptionalWeek?: InputMaybe<Scalars['Boolean']>;
  lineId: Scalars['ID'];
  shifts: Array<ShiftInput>;
  validFrom: ScheduleTimeInput;
  weeklyTargets?: InputMaybe<TargetsInput>;
};

export type MutationUpsertTargetArgs = {
  lineId: Scalars['ID'];
  validFrom: ScheduleTimeInput;
  weeklyTargets: TargetsInput;
};

export type MutationUpsertWiFiArgs = {
  input: NetworkInput;
  uuid: Scalars['ID'];
};

export type Network = {
  gateway?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ip?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  ssid: Scalars['String'];
  subnet?: Maybe<Scalars['String']>;
};

export type NetworkConfig = {
  wifi?: Maybe<WiFiConfigShadow>;
};

export type NetworkInput = {
  gateway?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  ip?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  ssid: Scalars['String'];
  subnet?: InputMaybe<Scalars['String']>;
};

export type NewStopCauseCategory = {
  index: Scalars['Int'];
  newCategoryId: Scalars['ID'];
};

export type Node = {
  alarmStatus?: Maybe<NodeAlarmStatus>;
  breadcrumb: Array<Breadcrumb>;
  children: Array<Node>;
  id: Scalars['ID'];
  leafDescendants?: Maybe<NodesWithPageToken>;
  line?: Maybe<Line>;
  peripheral?: Maybe<Peripheral>;
  refId?: Maybe<Scalars['ID']>;
};

export type NodeChildrenArgs = {
  treeId?: InputMaybe<Scalars['String']>;
};

export type NodeLeafDescendantsArgs = {
  nextPageToken?: InputMaybe<Scalars['String']>;
  treeId: Scalars['String'];
};

export enum NodeAlarmStatus {
  Disabled = 'Disabled',
  Normal = 'Normal',
  Ongoing = 'Ongoing',
  Snoozed = 'Snoozed',
}

export enum NodePosition {
  /** Node is downstream from the main peripheral */
  DOWNSTREAM_FROM_MAIN = 'DOWNSTREAM_FROM_MAIN',
  /** Node is upstream from the main peripheral */
  UPSTREAM_FROM_MAIN = 'UPSTREAM_FROM_MAIN',
}

export enum NodeType {
  /** A camera on the line. */
  Camera = 'Camera',
  /** A counter in the line. */
  Counter = 'Counter',
  /** Events from the line. */
  Event = 'Event',
  /** The main counter of the line. */
  Main = 'Main',
  /** A scrap counter in the line. */
  Scrap = 'Scrap',
}

export type NodesWithPageToken = {
  nextPageToken?: Maybe<Scalars['String']>;
  nodes: Array<Node>;
};

export type Oee = {
  mannedTime: Scalars['Float'];
  oee1: Scalars['Float'];
  oee1MaxProduced: Scalars['Float'];
  oee2: Scalars['Float'];
  oee2MaxProduced: Scalars['Float'];
  oee3: Scalars['Float'];
  oee3MaxProduced: Scalars['Float'];
  operatingTime: Scalars['Float'];
  productionTime: Scalars['Float'];
  scrapLoss: Scalars['Float'];
  speedLoss: Scalars['Float'];
  tcu: Scalars['Float'];
  tcuMaxProduced: Scalars['Float'];
  totalEquipmentTime: Scalars['Float'];
  valuedOperatingTime: Scalars['Float'];
};

export enum OeeDocumentType {
  STOPS_LAST_6_DAYS = 'STOPS_LAST_6_DAYS',
  STOPS_LAST_6_WEEKS = 'STOPS_LAST_6_WEEKS',
}

export type OeeInput = {
  preferences?: InputMaybe<OeePreferences>;
};

export type OeePreferences = {
  includeScrap?: InputMaybe<Scalars['Boolean']>;
  includeSpeedLoss?: InputMaybe<Scalars['Boolean']>;
};

export enum OeeType {
  OEE1 = 'OEE1',
  OEE2 = 'OEE2',
  OEE3 = 'OEE3',
  TCU = 'TCU',
}

export type OfflineStatus = {
  expiration?: Maybe<Scalars['Float']>;
  lastReceived: Scalars['Float'];
};

export enum Ordering {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export type OriginalControlDetails = {
  controlId: Scalars['String'];
  timeTriggered: Scalars['Date'];
};

export enum OwnerType {
  GROUP = 'GROUP',
  SENSOR = 'SENSOR',
}

export type Packaging = {
  comment?: Maybe<Scalars['String']>;
  lineId: Scalars['String'];
  name: Scalars['String'];
  packagingId: Scalars['ID'];
  packagingNumber: Scalars['String'];
  unit: Scalars['String'];
};

export type PackagingFilter = {
  comment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  packagingNumber?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type PackagingList = {
  items: Array<Packaging>;
  nextToken?: Maybe<Scalars['ID']>;
};

/** Information about pagination in a connection */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Parameter = {
  alarm?: Maybe<Alarm>;
  key: Scalars['String'];
  setpoint?: Maybe<SetPoint>;
  value: Scalars['String'];
};

export type Peripheral = {
  _id?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  /** @deprecated Use `peripheralId` instead. */
  id: Scalars['ID'];
  index: Scalars['ID'];
  name: Scalars['String'];
  offlineStatus: OfflineStatus;
  owner?: Maybe<Scalars['ID']>;
  peripheralId: Scalars['ID'];
  peripheralType: PeripheralType;
};

export type PeripheralInformation = {
  /** The line that the peripheral is in. */
  line: Line;
  /** The node setup it has in the line. */
  node: LineNode;
};

export type PeripheralMetaInput = {
  description?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum PeripheralType {
  CAMERA = 'CAMERA',
  SENSOR = 'SENSOR',
}

export type Permission = {
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  type: RequestType;
};

export type PermissionInput = {
  key: Scalars['String'];
  type: RequestType;
};

export type PreSignedUrl = {
  objectKey: Scalars['String'];
  url: Scalars['String'];
};

export type Product = BaseProduct & {
  attachedControlReceipts: Array<ControlReceipt>;
  comment?: Maybe<Scalars['String']>;
  dataMultiplier: Scalars['Float'];
  expectedAverageSpeed: Scalars['Float'];
  itemNumber: Scalars['String'];
  lineId: Scalars['ID'];
  name: Scalars['String'];
  overwrittenByBatch?: Maybe<Scalars['Boolean']>;
  packaging: Packaging;
  parameters: Array<Parameter>;
  productId: Scalars['ID'];
  validatedLineSpeed: Scalars['Float'];
};

export type ProductFilter = {
  itemNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductList = {
  items: Array<Product>;
  nextToken?: Maybe<Scalars['ID']>;
};

export type Query = {
  /**
   * Andon is the operator-technician communication maintenance module of
   * Blackbird. See the Andon type for more details.
   */
  andon: Array<Andon>;
  /** Lists API tokens issued for the whole company if permitted to do so. */
  apiTokens: Array<ApiToken>;
  /** Lists auth tokens issued for the whole company if permitted to do so. */
  authTokens: Array<AuthToken>;
  claims: Claims;
  companies: Array<Company>;
  company: Company;
  /** Get consolidated/aggregated data and statistics for a list of lines, over a period of time. */
  consolidatedData: ConsolidatedData;
  controlReceipts: ControlReceiptList;
  device?: Maybe<Device>;
  devices: Array<Maybe<Device>>;
  features: FeatureList;
  getNode?: Maybe<Node>;
  horizontalAnnotations: Array<HorizontalAnnotation>;
  /** Get a specific line. */
  line: Line;
  lineFromPeripheral?: Maybe<Line>;
  /** Get a list of lines, that the user has access to. Only line filters are functional for this query. */
  lines: Array<Line>;
  maintenancePlan?: Maybe<MaintenancePlan>;
  maintenancePlans: MaintenancePlanConnection;
  maintenanceWorkOrders: MaintenanceWorkOrderConnection;
  nextShift?: Maybe<ShiftInstance>;
  pendingControls: BatchControlList;
  /** @deprecated Use `device(uuid) { peripheral(index) }` instead. */
  peripheralByID?: Maybe<Peripheral>;
  /** @deprecated Use `device(uuid) { peripheral(index) }` instead. */
  peripheralByIDs: Array<Maybe<Peripheral>>;
  peripheralEventActionConfiguration: Array<Maybe<EventActionConfiguration>>;
  permissionKeys: Array<Permission>;
  previousShift?: Maybe<ShiftInstance>;
  shifts: Array<ShiftInstance>;
  stopCauseMappings?: Maybe<Array<StopCauseMapping>>;
  /** * LEGACY QUERIES BELOW */
  taskConfiguration?: Maybe<TaskConfiguration>;
  taskConfigurations: TaskConfigurationConnection;
  upcomingTasks: UpcomingTaskConnection;
  user: User;
  verticalAnnotations: Array<VerticalAnnotation>;
};

export type QueryAndonArgs = {
  companyId?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryApiTokensArgs = {
  userSub?: InputMaybe<Scalars['ID']>;
};

export type QueryAuthTokensArgs = {
  userSub?: InputMaybe<Scalars['ID']>;
};

export type QueryConsolidatedDataArgs = {
  lineIds?: InputMaybe<Array<Scalars['ID']>>;
  time: Time;
};

export type QueryControlReceiptsArgs = {
  filter?: InputMaybe<ControlReceiptFilter>;
  maxItems?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['ID']>;
  userPoolId: Scalars['ID'];
};

export type QueryDeviceArgs = {
  uuid: Scalars['ID'];
};

export type QueryDevicesArgs = {
  uuids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryGetNodeArgs = {
  nodeId?: InputMaybe<Scalars['ID']>;
  treeId: Scalars['String'];
};

export type QueryHorizontalAnnotationsArgs = {
  peripheralId: Scalars['ID'];
};

export type QueryLineArgs = {
  filter?: InputMaybe<LineFilterInput>;
  languageCode?: InputMaybe<Scalars['String']>;
  lineId: Scalars['ID'];
};

export type QueryLineFromPeripheralArgs = {
  peripheralId: Scalars['ID'];
};

export type QueryLinesArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<LineFilterInput>;
  languageCode?: InputMaybe<Scalars['String']>;
};

export type QueryMaintenancePlanArgs = {
  peripheralId: Scalars['PeripheralId'];
  planId: Scalars['MaintenancePlanId'];
};

export type QueryMaintenancePlansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryMaintenanceWorkOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MaintenanceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryNextShiftArgs = {
  from: Scalars['Date'];
  lineId: Scalars['ID'];
};

export type QueryPendingControlsArgs = {
  from: Scalars['Date'];
  lineId: Scalars['ID'];
  maxItems?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['ID']>;
  to: Scalars['Date'];
};

export type QueryPeripheralByIdArgs = {
  peripheralId: Scalars['ID'];
};

export type QueryPeripheralByIDsArgs = {
  peripheralIds: Array<Scalars['ID']>;
  peripheralType?: InputMaybe<PeripheralType>;
};

export type QueryPeripheralEventActionConfigurationArgs = {
  peripheralId?: InputMaybe<Scalars['ID']>;
};

export type QueryPreviousShiftArgs = {
  from: Scalars['Date'];
  lineId: Scalars['ID'];
};

export type QueryShiftsArgs = {
  lineId: Scalars['ID'];
  time: TimeRangeInput;
};

export type QueryStopCauseMappingsArgs = {
  peripheralId: Scalars['ID'];
};

export type QueryTaskConfigurationArgs = {
  peripheralId: Scalars['PeripheralId'];
  taskId: Scalars['TaskId'];
};

export type QueryTaskConfigurationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryUpcomingTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryUserArgs = {
  userSub?: InputMaybe<Scalars['ID']>;
};

export type QueryVerticalAnnotationsArgs = {
  input?: InputMaybe<VerticalAnnotationsInput>;
  peripheralId: Scalars['ID'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  timeRange: InputTimeRange;
};

export type RepeatByProduction = {
  produced: Scalars['Int'];
};

export type RepeatByProductionInput = {
  produced: Scalars['Int'];
};

export type RepeatByTime = {
  days: Scalars['Int'];
  hours: Scalars['Int'];
};

export type RepeatByTimeInput = {
  days: Scalars['Int'];
  hours: Scalars['Int'];
};

export type RepeatOptions = {
  production?: Maybe<RepeatByProduction>;
  time?: Maybe<RepeatByTime>;
};

export type RepeatOptionsInput = {
  production?: InputMaybe<RepeatByProductionInput>;
  time?: InputMaybe<RepeatByTimeInput>;
};

export type ReplacePeripheralsInput = {
  comingFullHardwareId: Scalars['ID'];
  currentFullPeripheralId: Scalars['ID'];
};

export type ReportSubscriber = {
  languageCode: Scalars['String'];
  type: SubscriptionType;
  value: Scalars['String'];
};

export type ReportSubscriberInput = {
  languageCode: Scalars['String'];
  type: SubscriptionType;
  value: Scalars['String'];
};

export enum ReportType {
  LINE_GROUP_PRODUCTION = 'LINE_GROUP_PRODUCTION',
  LINE_GROUP_PRODUCTION_DYNAMIC = 'LINE_GROUP_PRODUCTION_DYNAMIC',
  STOPS_LAST_6_DAYS = 'STOPS_LAST_6_DAYS',
  STOPS_LAST_6_WEEKS = 'STOPS_LAST_6_WEEKS',
}

export enum RequestType {
  MUTATION = 'MUTATION',
  QUERY = 'QUERY',
}

export type Resource = {
  claims: Array<Scalars['ClaimId']>;
  key: Scalars['String'];
};

export type RevokeApiTokenInput = {
  token: Scalars['String'];
};

export type Role = {
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  type: RoleType;
};

export type RoleAttributes = {
  name?: InputMaybe<Scalars['String']>;
  permissionKeys?: InputMaybe<Array<PermissionInput>>;
};

export type RoleCreateInput = {
  escalationConfiguration?: InputMaybe<Array<EscalationConfigurationInput>>;
  languageCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum RoleType {
  READONLY = 'READONLY',
  REGULAR = 'REGULAR',
  SUPER = 'SUPER',
}

export type RoleUpdateInput = {
  escalationConfiguration?: InputMaybe<Array<EscalationConfigurationInput>>;
  languageCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Sample = {
  data: SampleData;
  timeRange: TimeRange;
};

export type SampleData = {
  accValue?: Maybe<Scalars['Float']>;
  count: Scalars['Float'];
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
};

export type Schedule = {
  configuration: ScheduleConfiguration;
  id: Scalars['ID'];
  isExceptionalWeek: Scalars['Boolean'];
  isFallbackSchedule: Scalars['Boolean'];
  lineId: Scalars['ID'];
  shifts: Array<Shift>;
  validFrom: ScheduleTime;
  validTo?: Maybe<ScheduleTime>;
  weeklyTargets: Targets;
};

export type ScheduleConfiguration = {
  automaticStopRegistration: AutomaticStopRegistration;
  disableLostConnectionAlarmOutsideWorkingHours: Scalars['Boolean'];
  lineId: Scalars['ID'];
  startDayOfWeek: DayOfWeek;
  timezone: Scalars['String'];
};

export type ScheduleConfigurationInput = {
  automaticStopRegistration?: InputMaybe<AutomaticStopRegistrationInput>;
  disableLostConnectionAlarmOutsideWorkingHours: Scalars['Boolean'];
  startDayOfWeek: DayOfWeek;
  timezone: Scalars['String'];
};

export type ScheduleCreateInput = {
  lineIds: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type ScheduleTime = {
  week: Scalars['Int'];
  year: Scalars['Int'];
};

export type ScheduleTimeInput = {
  excludeExceptionalWeek?: InputMaybe<Scalars['Boolean']>;
  week: Scalars['Int'];
  year: Scalars['Int'];
};

export type ScheduleTimeRangeInput = {
  from: ScheduleTimeInput;
  to?: InputMaybe<ScheduleTimeInput>;
};

export type ScheduleUpdateInput = {
  lineIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type ScheduledReport = {
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  entityId: Scalars['ID'];
  entityType: EntityType;
  id: Scalars['ID'];
  name: Scalars['String'];
  nextTriggerDate: Scalars['Date'];
  subscribers: Array<ReportSubscriber>;
  timezone: Scalars['String'];
  trigger: ScheduledReportTrigger;
  type: ReportType;
};

export type ScheduledReportTrigger = {
  parameters: Scalars['String'];
  type: TriggerType;
};

export type ScrapSample = {
  data: SampleData;
  peripheralId: Scalars['ID'];
  timeRange: TimeRange;
};

export type Sensor = Peripheral & {
  _id?: Maybe<Scalars['ID']>;
  /** Get a specific alarm. */
  alarm: Alarm;
  /** Get a list of all alarms. */
  alarms: Array<Maybe<Alarm>>;
  cameras: Array<Maybe<Camera>>;
  config: SensorConfig;
  customKPIs: Array<CustomKpi>;
  description: Scalars['String'];
  /** Groups the sensor is member of */
  groups?: Maybe<Array<Group>>;
  /** Horizontal annotations on the sensor */
  horizontalAnnotations?: Maybe<Array<HorizontalAnnotation>>;
  /** @deprecated Use `peripheralId` instead. */
  id: Scalars['ID'];
  index: Scalars['ID'];
  /** Get the sensor's upcoming maintenance tasks */
  maintenanceUpcomingTasks: UpcomingTaskConnection;
  /** Get the sensor's maintenance work orders */
  maintenanceWorkOrders: MaintenanceWorkOrderConnection;
  name: Scalars['String'];
  offlineStatus: OfflineStatus;
  owner?: Maybe<Scalars['ID']>;
  peripheralId: Scalars['ID'];
  /** The line information for the peripheral. */
  peripheralInformation?: Maybe<PeripheralInformation>;
  peripheralType: PeripheralType;
  /** Get scheduled reports for the sensor */
  scheduledReports: Array<ScheduledReport>;
  /** Get the stop cause categories of the sensor. */
  stopCauseCategories: Array<Maybe<StopCauseCategory>>;
  /** Get the stop cause categories of the sensor. */
  stopCauseMappings: Array<Maybe<StopCauseMapping>>;
  /** Time dependant fields of the sensor. */
  time?: Maybe<Array<Maybe<SensorTimeData>>>;
  /** Vertical annotations on the sensor */
  verticalAnnotations?: Maybe<Array<VerticalAnnotation>>;
};

export type SensorAlarmArgs = {
  alarmId: Scalars['ID'];
  languageCode?: InputMaybe<Scalars['String']>;
};

export type SensorAlarmsArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
  timeRange?: InputMaybe<Time>;
};

export type SensorMaintenanceUpcomingTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type SensorMaintenanceWorkOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type SensorTimeArgs = {
  time: Array<Time>;
};

export type SensorVerticalAnnotationsArgs = {
  input?: InputMaybe<VerticalAnnotationsInput>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  timeRange: Time;
};

export type SensorConfig = {
  accumulatorConfig?: Maybe<AccumulatorConfig>;
  analogInputRange?: Maybe<AnalogInputRange>;
  analogOffset?: Maybe<Scalars['Float']>;
  chart?: Maybe<ChartConfiguration>;
  /** @deprecated Use `chart.scale` instead. */
  chartTimeScale?: Maybe<ChartTimeScale>;
  dataAlarm?: Maybe<DataAlarm>;
  dataMultiplier?: Maybe<Scalars['Float']>;
  discreteConfig?: Maybe<DiscreteConfig>;
  energyMeter?: Maybe<Scalars['Boolean']>;
  expectedSpeed?: Maybe<Scalars['Float']>;
  findStops?: Maybe<Scalars['Boolean']>;
  /** @deprecated Unused, and will always resolve to null. */
  publishRate?: Maybe<Scalars['Int']>;
  /** @deprecated Unused, and will always resolve to null. */
  sampleRate?: Maybe<Scalars['Int']>;
  sensorUnit?: Maybe<SensorUnit>;
  stopRegisterThreshold?: Maybe<Scalars['Int']>;
  stopThreshold?: Maybe<Scalars['Int']>;
  stopValueThreshold?: Maybe<Scalars['Float']>;
  /** @deprecated Unused, and will always resolve to null. */
  triggerIndex?: Maybe<Scalars['Int']>;
  type?: Maybe<SensorType>;
  validFrom: Scalars['Date'];
  validatedSpeed?: Maybe<Scalars['Float']>;
  wiring?: Maybe<SensorWiringState>;
};

export type SensorConfigInput = {
  accumulatorConfig?: InputMaybe<AccumulatorConfigInput>;
  analogInputRange?: InputMaybe<AnalogInputRange>;
  analogOffset?: InputMaybe<Scalars['Float']>;
  chart?: InputMaybe<ChartConfigurationInput>;
  chartTimeScale?: InputMaybe<ChartTimeScale>;
  dataAlarm?: InputMaybe<DataAlarmInput>;
  dataMultiplier?: InputMaybe<Scalars['Float']>;
  discreteConfig?: InputMaybe<DiscreteConfigInput>;
  energyMeter?: InputMaybe<Scalars['Boolean']>;
  expectedSpeed?: InputMaybe<Scalars['Float']>;
  findStops?: InputMaybe<Scalars['Boolean']>;
  publishRate?: InputMaybe<Scalars['Int']>;
  sampleRate?: InputMaybe<Scalars['Int']>;
  sensorUnit?: InputMaybe<SensorUnitInput>;
  stopRegisterThreshold?: InputMaybe<Scalars['Int']>;
  stopThreshold?: InputMaybe<Scalars['Int']>;
  stopValueThreshold?: InputMaybe<Scalars['Float']>;
  triggerIndex?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<SensorType>;
  validatedSpeed?: InputMaybe<Scalars['Float']>;
  wiring?: InputMaybe<SensorWiring>;
};

export type SensorTimeData = {
  _id?: Maybe<Scalars['ID']>;
  /** Get all the alarm logs. */
  alarmLogs: AlarmLogs;
  /** Get batches of the line, this sensor is part of. */
  batches: BatchList;
  /** Get all the sensor configs within the time range. */
  configs: Array<Maybe<SensorConfig>>;
  /** Get data overrides on the sensor */
  dataOverrides: Array<DataOverride>;
  /** Get samples of the sensor. */
  samples?: Maybe<Array<Maybe<Sample>>>;
  /** Get stats KPIs of the sensor. */
  stats: Stat;
  /** Get stop-related stats of the sensor. */
  stopStats: StopStats;
  /** Get stops of the sensor. */
  stops: Array<Maybe<Stop>>;
  timeRange: TimeRange;
};

export type SensorTimeDataAlarmLogsArgs = {
  exclusiveStartKey?: InputMaybe<AlarmLogExclusiveStartKey>;
  filter?: InputMaybe<AlarmLogFilter>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type SensorTimeDataBatchesArgs = {
  clip?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BatchFilter>;
  paginationToken?: InputMaybe<Scalars['ID']>;
};

export type SensorTimeDataSamplesArgs = {
  points?: InputMaybe<Scalars['Int']>;
  raw?: InputMaybe<Scalars['Boolean']>;
  requestRaw?: InputMaybe<Scalars['Boolean']>;
  resample?: InputMaybe<Scalars['Boolean']>;
};

export type SensorTimeDataStatsArgs = {
  input?: InputMaybe<StatsInput>;
};

export type SensorTimeDataStopStatsArgs = {
  filter?: InputMaybe<StopFilter>;
};

export type SensorTimeDataStopsArgs = {
  filter?: InputMaybe<StopFilter>;
  languageCode?: InputMaybe<Scalars['String']>;
};

export enum SensorType {
  COUNTER = 'COUNTER',
  COUNTER_ACCUMULATE = 'COUNTER_ACCUMULATE',
  COUNTER_SPEED = 'COUNTER_SPEED',
  DISCRETE = 'DISCRETE',
  EVENT = 'EVENT',
  MANUAL_PROCESS = 'MANUAL_PROCESS',
  MEASUREMENT = 'MEASUREMENT',
}

export type SensorUnit = {
  label?: Maybe<Scalars['String']>;
  unit?: Maybe<Unit>;
};

export type SensorUnitInput = {
  label?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Unit>;
};

export enum SensorWiring {
  ANALOG = 'ANALOG',
  INACTIVE = 'INACTIVE',
  NPN = 'NPN',
  PNP = 'PNP',
}

export type SensorWiringState = {
  desired?: Maybe<SensorWiring>;
  reported?: Maybe<SensorWiring>;
};

export type SetAlarmEnablementInput = {
  nodeId: Scalars['ID'];
  setTo: Scalars['Boolean'];
};

export type SetPoint = {
  peripheral: Peripheral;
  peripheralId: Scalars['ID'];
  value: Scalars['Float'];
};

export type Shift = {
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  targets: Targets;
  timeRange: ShiftTimeRange;
};

export type ShiftCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Float'];
  name: Scalars['String'];
  rRuleSet: Scalars['String'];
};

export type ShiftInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  targets: TargetsInput;
  timeRange: ShiftTimeRangeInput;
};

export type ShiftInstance = {
  description: Scalars['String'];
  from: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  shiftId: Scalars['ID'];
  to: Scalars['Date'];
};

export type ShiftTime = {
  day: DayOfWeek;
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type ShiftTimeInput = {
  day: DayOfWeek;
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type ShiftTimeRange = {
  from: ShiftTime;
  to: ShiftTime;
};

export type ShiftTimeRangeInput = {
  from: ShiftTimeInput;
  to: ShiftTimeInput;
};

export type ShiftUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  rRuleSet?: InputMaybe<Scalars['String']>;
};

export enum ShouldRepeat {
  NO = 'NO',
  YES = 'YES',
}

export type StandaloneConfiguration = {
  excludeProduction?: Maybe<Scalars['Boolean']>;
};

export type StandaloneConfigurationInput = {
  excludeProduction?: InputMaybe<Scalars['Boolean']>;
};

export type Stat = {
  data: Data;
  timeRange: TimeRange;
};

export type StatsInput = {
  batchesInput?: InputMaybe<BatchesInput>;
};

export enum Status {
  BATCH_NON_PROD = 'BATCH_NON_PROD',
  DOWNTIME = 'DOWNTIME',
  NON_PROD = 'NON_PROD',
  NO_ACT = 'NO_ACT',
  OFF = 'OFF',
  RUNNING = 'RUNNING',
}

export type Stop = BaseStop & {
  _id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  countermeasure?: Maybe<Scalars['String']>;
  countermeasureInitials?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  initials?: Maybe<Scalars['String']>;
  isAutomaticRegistration?: Maybe<Scalars['Boolean']>;
  isMicroStop: Scalars['Boolean'];
  ongoing: Scalars['Boolean'];
  registeredTime?: Maybe<Scalars['Date']>;
  standalone?: Maybe<StandaloneConfiguration>;
  stopCause?: Maybe<StopCause>;
  target?: Maybe<Target>;
  timeRange: TimeRange;
};

export type StopCause = {
  _id: Scalars['ID'];
  categoryName?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  description: Scalars['String'];
  enableCountermeasure: Scalars['Boolean'];
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  legacyStopCauseId?: Maybe<Scalars['ID']>;
  meta: Array<StopCauseMeta>;
  name: Scalars['String'];
  order: Scalars['Int'];
  requireComment: Scalars['Boolean'];
  requireInitials: Scalars['Boolean'];
  stopType: StopType;
  targetSetup?: Maybe<TargetSetup>;
};

export type StopCauseCategory = {
  _id: Scalars['ID'];
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  legacyCategoryId?: Maybe<Scalars['ID']>;
  meta: Array<StopCauseCategoryMeta>;
  name: Scalars['String'];
  order: Scalars['Int'];
  ownerType: OwnerType;
  stopCauses: Array<Maybe<StopCause>>;
};

export type StopCauseCategoryMeta = {
  languageCode: Scalars['String'];
  name: Scalars['String'];
};

export type StopCauseCategoryMetaInput = {
  languageCode: Scalars['String'];
  name: Scalars['String'];
};

export type StopCauseMapping = {
  _id: Scalars['ID'];
  buffer?: Maybe<Scalars['Int']>;
  lookBack: Scalars['Int'];
  lookForward: Scalars['Int'];
  peripheralIdEvent: Scalars['String'];
  peripheralIdTarget: Scalars['String'];
  regPriority?: Maybe<Scalars['Int']>;
  split?: Maybe<Scalars['Boolean']>;
  stopCauseId: Scalars['ID'];
  userPool?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type StopCauseMappingInput = {
  buffer?: InputMaybe<Scalars['Int']>;
  lookBack?: InputMaybe<Scalars['Int']>;
  lookForward?: InputMaybe<Scalars['Int']>;
  peripheralIdEvent: Scalars['String'];
  peripheralIdTarget: Scalars['String'];
  regPriority?: InputMaybe<Scalars['Int']>;
  split?: InputMaybe<Scalars['Boolean']>;
  stopCauseId: Scalars['ID'];
  userPool?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type StopCauseMeta = {
  description: Scalars['String'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
};

export type StopCauseMetaInput = {
  description: Scalars['String'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
};

export type StopCauseStat = {
  accDuration: Scalars['Float'];
  accTarget: Scalars['Float'];
  isMicroStop: Scalars['Boolean'];
  numberOfStops: Scalars['Int'];
  stopCategoryName: Scalars['String'];
  stopCauseDescription: Scalars['String'];
  stopCauseId: Scalars['ID'];
  stopCauseName: Scalars['String'];
  type?: Maybe<StopType>;
};

export type StopConfig = {
  stopRegisterThreshold: Scalars['Float'];
  stopThreshold: Scalars['Float'];
  validFrom: Scalars['Date'];
};

export type StopFilter = {
  excludeNonChangeoverTrackingStops?: InputMaybe<Scalars['Boolean']>;
  excludeStandaloneStops?: InputMaybe<Scalars['Boolean']>;
  includeMicroStops?: InputMaybe<Scalars['Boolean']>;
  minDuration?: InputMaybe<Scalars['Float']>;
  stopCauseIds?: InputMaybe<Array<Scalars['ID']>>;
  stopCauseName?: InputMaybe<Scalars['String']>;
  stopTypes?: InputMaybe<Array<StopType>>;
};

export type StopStats = {
  lastStop?: Maybe<Stop>;
  longestNonStop: Scalars['Float'];
  stopCauseStats: Array<Maybe<StopCauseStat>>;
};

export enum StopType {
  BATCH_NON_PROD = 'BATCH_NON_PROD',
  DOWNTIME = 'DOWNTIME',
  NON_PROD = 'NON_PROD',
  NO_ACT = 'NO_ACT',
}

export enum StopTypeForAlarms {
  BATCH_NON_PROD = 'BATCH_NON_PROD',
  DOWNTIME = 'DOWNTIME',
  NON_PROD = 'NON_PROD',
  NO_ACT = 'NO_ACT',
  UNREGISTERED = 'UNREGISTERED',
}

export type Subscriber = {
  languageCode: Scalars['String'];
  type: SubscriberType;
  value: Scalars['String'];
};

export type SubscriberInput = {
  languageCode: Scalars['String'];
  type: SubscriberType;
  value: Scalars['String'];
};

export type SubscriberOutput = {
  languageCode: Scalars['String'];
  type: SubscriberType;
  value: Scalars['String'];
};

export enum SubscriberType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum SubscriptionType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export type SupportType = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SwapPeripheralsInput = {
  from: Array<Scalars['ID']>;
  to: Array<Scalars['ID']>;
};

export type Tag = {
  id: Scalars['ID'];
  lineId: Scalars['ID'];
  value: Scalars['String'];
};

export type Target = {
  currentShift?: Maybe<Scalars['ID']>;
  nextBatch?: Maybe<Scalars['ID']>;
  previousBatch?: Maybe<Scalars['ID']>;
  target: Scalars['Float'];
};

export type TargetInput = {
  currentShift?: InputMaybe<Scalars['ID']>;
  nextBatch?: InputMaybe<Scalars['ID']>;
  previousBatch?: InputMaybe<Scalars['ID']>;
  target: Scalars['Float'];
};

export type TargetSetup = {
  constantTime: Scalars['Float'];
  parameterTimes: Array<TargetSetupParameterTime>;
};

export type TargetSetupInput = {
  constantTime: Scalars['Float'];
  parameterTimes: Array<TargetSetupParameterTimeInput>;
};

export type TargetSetupParameterTime = {
  parameter: Scalars['String'];
  time: Scalars['Float'];
  unlessParameter?: Maybe<Scalars['String']>;
};

export type TargetSetupParameterTimeInput = {
  parameter: Scalars['String'];
  time: Scalars['Float'];
  unlessParameter?: InputMaybe<Scalars['String']>;
};

export type Targets = {
  id?: Maybe<Scalars['ID']>;
  lineId: Scalars['ID'];
  mainOeeType?: Maybe<OeeType>;
  numberOfBatches?: Maybe<Scalars['Float']>;
  oee?: Maybe<Scalars['Float']>;
  oee2?: Maybe<Scalars['Float']>;
  oee3?: Maybe<Scalars['Float']>;
  produced?: Maybe<Scalars['Float']>;
  tcu?: Maybe<Scalars['Float']>;
  validFrom?: Maybe<ScheduleTime>;
};

export type TargetsInput = {
  mainOeeType?: InputMaybe<OeeType>;
  numberOfBatches?: InputMaybe<Scalars['Float']>;
  oee?: InputMaybe<Scalars['Float']>;
  oee2?: InputMaybe<Scalars['Float']>;
  oee3?: InputMaybe<Scalars['Float']>;
  produced?: InputMaybe<Scalars['Float']>;
  tcu?: InputMaybe<Scalars['Float']>;
};

export type TaskConfiguration = {
  asset: Scalars['String'];
  component: Scalars['String'];
  firstMaintenance: Scalars['DateTime'];
  instructions?: Maybe<Scalars['String']>;
  log: TaskLogEntryConnection;
  peripheral: Peripheral;
  peripheralId: Scalars['PeripheralId'];
  repeatOptions?: Maybe<RepeatOptions>;
  tagPartNumber?: Maybe<Scalars['String']>;
  taskId: Scalars['TaskId'];
  version: Scalars['Int'];
};

export type TaskConfigurationLogArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TaskConfigurationConnection = {
  /** A list of edges. */
  edges: Array<TaskConfigurationEdge>;
  /** A list of nodes. */
  nodes: Array<TaskConfiguration>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TaskConfigurationEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: TaskConfiguration;
};

export type TaskConfigurationSnapshot = {
  asset: Scalars['String'];
  component: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  repeatOptions?: Maybe<RepeatOptions>;
  tagPartNumber?: Maybe<Scalars['String']>;
};

export type TaskFilter = {
  peripheralIds: Array<Scalars['PeripheralId']>;
};

export type TaskLogEntry = {
  comment: Scalars['String'];
  initials: Scalars['String'];
  onTime: Scalars['Boolean'];
  peripheral: Peripheral;
  peripheralId: Scalars['PeripheralId'];
  snapshot: TaskConfigurationSnapshot;
  status: TaskStatus;
  taskId: Scalars['TaskId'];
  timestamp: Scalars['DateTime'];
};

export type TaskLogEntryConnection = {
  /** A list of edges. */
  edges: Array<TaskLogEntryEdge>;
  /** A list of nodes. */
  nodes: Array<TaskLogEntry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TaskLogEntryEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: TaskLogEntry;
};

export enum TaskStatus {
  COMPLETED = 'COMPLETED',
}

export type Test = {
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
  time: TimeRange;
};

export type Time = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type TimeFilter = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type TimeRange = {
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type TimeRangeInput = {
  from: Scalars['Date'];
  to?: InputMaybe<Scalars['Date']>;
};

export type TimedTriggerPayload = {
  millis: Scalars['Int'];
};

export type TimedTriggerPayloadInput = {
  millis: Scalars['Int'];
};

export type Token = {
  paginationToken: Scalars['ID'];
  userPool: Scalars['ID'];
};

export type TokenInput = {
  paginationToken: Scalars['ID'];
  userPool: Scalars['ID'];
};

export type TrackByOptions = {
  production?: Maybe<TrackByProduction>;
  time?: Maybe<TrackByTime>;
};

export type TrackByOptionsInput = {
  production?: InputMaybe<TrackByProductionInput>;
  time?: InputMaybe<TrackByTimeInput>;
};

export type TrackByProduction = {
  produced: Scalars['Int'];
};

export type TrackByProductionInput = {
  produced: Scalars['Int'];
};

export type TrackByTime = {
  days: Scalars['Int'];
  hours: Scalars['Int'];
};

export type TrackByTimeInput = {
  days: Scalars['Int'];
  hours: Scalars['Int'];
};

export enum TriggerType {
  CRON = 'CRON',
}

export enum Unit {
  CURRENT = 'CURRENT',
  GAS_FLOW = 'GAS_FLOW',
  HYDROCARBON_EMISSION = 'HYDROCARBON_EMISSION',
  PCS = 'PCS',
  POWER = 'POWER',
  PRESSURE = 'PRESSURE',
  RELATIVE_HUMIDITY = 'RELATIVE_HUMIDITY',
  SPEED = 'SPEED',
  TEMPERATURE = 'TEMPERATURE',
  VIBRATION = 'VIBRATION',
  VOLTAGE = 'VOLTAGE',
  WEIGHT = 'WEIGHT',
}

export type UpcomingTask = {
  asset: Scalars['String'];
  component: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  latestMaintenance?: Maybe<TaskLogEntry>;
  peripheral: Peripheral;
  peripheralId: Scalars['PeripheralId'];
  repeatOptions?: Maybe<RepeatOptions>;
  tagPartNumber?: Maybe<Scalars['String']>;
  taskId: Scalars['TaskId'];
  timestamp: Scalars['DateTime'];
};

export type UpcomingTaskConnection = {
  /** A list of edges. */
  edges: Array<UpcomingTaskEdge>;
  /** A list of nodes. */
  nodes: Array<UpcomingTask>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UpcomingTaskEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: UpcomingTask;
};

export type UpdateConfigInput = {
  camera?: InputMaybe<CameraConfigInput>;
  sensor?: InputMaybe<SensorConfigInput>;
};

export type UpdateMaintenancePlanInput = {
  asset?: InputMaybe<Scalars['String']>;
  component?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  startFrom?: InputMaybe<Scalars['DateTime']>;
  tagPartNumber?: InputMaybe<Scalars['String']>;
  trackBy?: InputMaybe<TrackByOptionsInput>;
};

export type UpdateTaskConfigurationInput = {
  asset?: InputMaybe<Scalars['String']>;
  component?: InputMaybe<Scalars['String']>;
  firstMaintenance?: InputMaybe<Scalars['DateTime']>;
  instructions?: InputMaybe<Scalars['String']>;
  repeatOptions?: InputMaybe<RepeatOptionsInput>;
  tagPartNumber?: InputMaybe<Scalars['String']>;
};

export enum Urgency {
  CRITICAL = 'CRITICAL',
  MEDIUM = 'MEDIUM',
}

export type User = {
  company: Company;
  devices: Array<Maybe<Device>>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  groups: Array<Group>;
  lines: Array<Maybe<Line>>;
  sub: Scalars['String'];
  userCreateDate: Scalars['Date'];
  userLastModifiedDate: Scalars['Date'];
  userStatus: Scalars['String'];
  username: Scalars['String'];
};

export type UserAttributes = {
  address?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  preferredUsername?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserFilter = {
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  sub?: InputMaybe<Scalars['String']>;
  userStatus?: InputMaybe<Scalars['String']>;
};

export type UserList = {
  items: Array<User>;
  pagination?: Maybe<Array<Token>>;
};

export type UserSub = {
  sub: Scalars['String'];
  username: Scalars['String'];
};

export type VerticalAnnotation = {
  id: Scalars['ID'];
  label: Scalars['String'];
  tags: Array<Scalars['String']>;
  timestamp: Scalars['Date'];
  timestampEnd?: Maybe<Scalars['Date']>;
};

export type VerticalAnnotationInput = {
  label: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  timestamp: Scalars['Date'];
  timestampEnd?: InputMaybe<Scalars['Date']>;
};

export type VerticalAnnotationsInput = {
  sample?: InputMaybe<Scalars['Boolean']>;
};

export type WiFiConfig = {
  enabled?: Maybe<Scalars['Boolean']>;
  networks?: Maybe<Array<Network>>;
};

export type WiFiConfigShadow = {
  desired?: Maybe<WiFiConfig>;
  reported?: Maybe<WiFiConfig>;
};

export type WorkOrder = {
  id: Scalars['ID'];
};

export type WorkOrderKey = {
  key: Scalars['ID'];
  tagId: Scalars['ID'];
};

export enum WorkOrderStatus {
  COMPLETED = 'COMPLETED',
}

export type Worker = IWorker & {
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  preferredSchedules: Array<AndonSchedule>;
  /** @deprecated Workers may now reference multiple roles. */
  role: AndonRole;
  roles: Array<AndonRole>;
};

export type WorkerCreateInput = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  preferredSchedules?: InputMaybe<Array<Scalars['ID']>>;
  roleId?: InputMaybe<Scalars['ID']>;
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type WorkerUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  preferredSchedules?: InputMaybe<Array<Scalars['ID']>>;
  roleId?: InputMaybe<Scalars['ID']>;
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  kind: __TypeKind;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  specifiedByURL?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  SCALAR = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  OBJECT = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  INTERFACE = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  UNION = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  ENUM = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  INPUT_OBJECT = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  LIST = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NON_NULL = 'NON_NULL',
}

export type AdministrationGroupLineFragment = {
  id: string;
  name: string;
  description?: string | null;
  nodes: Array<{ id: string; type: NodeType }>;
};

type AdministrationGroupPeripheral_Camera_Fragment = {
  _id?: string | null;
  peripheralId: string;
  peripheralType: PeripheralType;
  name: string;
  description: string;
};

type AdministrationGroupPeripheral_Sensor_Fragment = {
  _id?: string | null;
  peripheralId: string;
  peripheralType: PeripheralType;
  name: string;
  description: string;
};

export type AdministrationGroupPeripheralFragment =
  | AdministrationGroupPeripheral_Camera_Fragment
  | AdministrationGroupPeripheral_Sensor_Fragment;

export type AdministrationGroupRoleFragment = { id: string; name: string; type: RoleType };

export type AdministrationGroupSensorFragment = { peripheralId: string; name: string; description: string };

export type AdministrationGroupSimpleFragment = {
  id: string;
  defaultGroup?: boolean | null;
  name: string;
  owner: { id: string };
  role: { id: string; name: string; type: RoleType };
  peripherals: Array<
    | { _id?: string | null; peripheralId: string; peripheralType: PeripheralType; name: string; description: string }
    | { _id?: string | null; peripheralId: string; peripheralType: PeripheralType; name: string; description: string }
    | null
  >;
  lines: Array<{
    id: string;
    name: string;
    description?: string | null;
    nodes: Array<{ id: string; type: NodeType }>;
  } | null>;
  users: { items: Array<{ sub: string }> };
};

export type AdministrationGroupUserFragment = {
  sub: string;
  givenName?: string | null;
  familyName?: string | null;
  username: string;
  email?: string | null;
};

export type AdministrationGroupFragment = {
  id: string;
  defaultGroup?: boolean | null;
  name: string;
  owner: { id: string };
  role: { id: string; name: string; type: RoleType };
  peripherals: Array<
    | { _id?: string | null; peripheralId: string; peripheralType: PeripheralType; name: string; description: string }
    | { _id?: string | null; peripheralId: string; peripheralType: PeripheralType; name: string; description: string }
    | null
  >;
  lines: Array<{
    id: string;
    name: string;
    description?: string | null;
    nodes: Array<{ id: string; type: NodeType }>;
  } | null>;
  users: {
    pagination?: Array<{ paginationToken: string; userPool: string }> | null;
    items: Array<{
      sub: string;
      givenName?: string | null;
      familyName?: string | null;
      username: string;
      email?: string | null;
    }>;
  };
};

export type AdministrationMeFragment = { sub: string; company: { id: string; name?: string | null } };

export type AdministrationUserFragment = {
  sub: string;
  enabled: boolean;
  email?: string | null;
  username: string;
  givenName?: string | null;
  familyName?: string | null;
  userStatus: string;
  userCreateDate: string;
  userLastModifiedDate: string;
  emailVerified?: string | null;
  company: { id: string; name?: string | null };
  groups: Array<{ id: string; name: string; role: { id: string; type: RoleType; name: string } }>;
  lines: Array<{ id: string; name: string; description?: string | null } | null>;
  devices: Array<{
    uuid: string;
    type: string;
    sensors: Array<{ _id?: string | null; index: string; name: string; description: string }>;
  } | null>;
};

export type AttendeeFragment = {
  id: string;
  email?: string | null;
  phoneNumber?: string | null;
  dateExceptions: Array<string>;
  supportId: string;
  messages: Array<{ id: string; type: SubscriptionType; delay: number; takenDelay?: number | null }>;
};

export type MaintenanceEventFragment = {
  id: string;
  rRuleSet: string;
  lineId: string;
  summary: string;
  description?: string | null;
  duration: number;
  attendees: Array<{
    id: string;
    email?: string | null;
    phoneNumber?: string | null;
    dateExceptions: Array<string>;
    supportId: string;
    messages: Array<{ id: string; type: SubscriptionType; delay: number; takenDelay?: number | null }>;
  }>;
};

export type ExtensionFragment = { type: ExtensionType; service: string };

export type MessageFragment = { id: string; type: SubscriptionType; delay: number; takenDelay?: number | null };

export type SupportTypeFragment = { id: string; name: string };

export type WorkOrderKeyFragment = { key: string; tagId: string };

export type AndonRoleFragment = {
  id: string;
  name: string;
  escalationConfiguration?: Array<{ delay?: number | null; takenDelay?: number | null; type: SubscriptionType }> | null;
};

export type AndonScheduleFragment = {
  id: string;
  name: string;
  lines: Array<{ id: string; name: string }>;
  shifts: Array<{
    id: string;
    name: string;
    duration: number;
    rRuleSet: string;
    attendees: Array<{ id: string; name: string }>;
  }>;
};

export type AndonShiftFragment = {
  id: string;
  name: string;
  duration: number;
  rRuleSet: string;
  attendees: Array<{ id: string; name: string; isPermanent?: boolean | null; isExcluded?: boolean | null }>;
};

export type WorkerFragment = {
  id: string;
  name: string;
  email?: string | null;
  phoneNumber?: string | null;
  preferredSchedules: Array<{ id: string; name: string }>;
  role: {
    id: string;
    name: string;
    escalationConfiguration?: Array<{
      delay?: number | null;
      takenDelay?: number | null;
      type: SubscriptionType;
    }> | null;
  };
};

export type BatchControlFragment = {
  batchControlId: string;
  controlReceiptId: string;
  controlReceiptName: string;
  entryId: string;
  title?: string | null;
  batchId: string;
  status: BatchControlStatus;
  timeTriggered: string;
  timeControlled?: string | null;
  timeControlUpdated?: string | null;
  comment?: string | null;
  initials?: string | null;
  fieldValues: Array<{
    value?: string | null;
    controlReceiptField: {
      fieldId: string;
      label: string;
      description?: string | null;
      type: ControlFieldType;
      sensorId?: string | null;
      limits?: { lower?: number | null; upper?: number | null } | null;
      derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
    };
  }>;
  trigger: { delayWhenStopped: boolean; type: ControlTriggerType; payload?: { millis: number } | null };
  followUp: { enabled: boolean; delayMs?: number | null };
  originalControl?: { controlId: string; timeTriggered: string } | null;
  history: Array<{
    status: BatchControlStatus;
    timeControlled?: string | null;
    timeControlUpdated?: string | null;
    comment?: string | null;
    initials?: string | null;
    fieldValues: Array<{
      value?: string | null;
      controlReceiptField: {
        fieldId: string;
        label: string;
        description?: string | null;
        type: ControlFieldType;
        sensorId?: string | null;
        limits?: { lower?: number | null; upper?: number | null } | null;
        derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
      };
    }>;
  }>;
};

export type BatchFragment = {
  batchId: string;
  batchNumber: string;
  state: BatchState;
  amount: number;
  manualScrap?: number | null;
  plannedStart?: string | null;
  actualStart?: string | null;
  actualStop?: string | null;
  comment?: string | null;
  lineId: string;
};

export type CameraFragmentFragment = { streamURL?: string | null };

export type NumberOfUnregisteredStopsFragment = {
  name: string;
  peripheralId: string;
  time?: Array<{ stats: { data: { numberOfUnregisteredStops?: number | null } } } | null> | null;
};

export type PendingControlsFragment = {
  time?: Array<{
    pendingControls: { nextToken?: string | null; items: Array<{ batchControlId: string }> };
  } | null> | null;
};

export type SensorProblemsFragment = {
  id: string;
  name: string;
  time?: Array<{
    stopStats: {
      lastStop?: {
        ongoing: boolean;
        stopCause?: { name: string; description: string } | null;
        timeRange: { from?: string | null };
      } | null;
    };
  } | null> | null;
  alarms: Array<{
    enabled: boolean;
    name: string;
    status: AlarmStatus;
    id: string;
    description: string;
    snoozeDuration?: number | null;
    alarmLogs: {
      entries: Array<{ status: AlarmStatus; timeRange: { from?: string | null; to?: string | null } } | null>;
    };
  } | null>;
};

export type ControlReceiptFragment = {
  controlReceiptId: string;
  name: string;
  description?: string | null;
  entries: Array<{
    entryId: string;
    title?: string | null;
    trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
    followUp: { enabled: boolean; delayMs?: number | null };
    fields: Array<{
      fieldId: string;
      label: string;
      description?: string | null;
      type: ControlFieldType;
      sensorId?: string | null;
      limits?: { lower?: number | null; upper?: number | null } | null;
      derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
    }>;
  }>;
};

export type DataOverrideFragment = {
  peripheralId: string;
  value: number;
  author: string;
  comment: string;
  timeRange: { from?: string | null; to?: string | null };
};

export type NodeFragment = {
  id: string;
  type: NodeType;
  peripheralId: string;
  sensor?: {
    _id?: string | null;
    peripheralId: string;
    name: string;
    description: string;
    config: {
      type?: SensorType | null;
      stopThreshold?: number | null;
      findStops?: boolean | null;
      energyMeter?: boolean | null;
      stopRegisterThreshold?: number | null;
      stopValueThreshold?: number | null;
      dataMultiplier?: number | null;
      validatedSpeed?: number | null;
      expectedSpeed?: number | null;
      analogOffset?: number | null;
      analogInputRange?: AnalogInputRange | null;
      publishRate?: number | null;
      sampleRate?: number | null;
      triggerIndex?: number | null;
      chartTimeScale?: ChartTimeScale | null;
      sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
      accumulatorConfig?: {
        resetsOnPowerOff: boolean;
        rolloverValue: number;
        rolloverThreshold?: number | null;
      } | null;
      discreteConfig?: { onStates: Array<number>; offStates: Array<number>; defaultState: DiscreteDeviceState } | null;
      dataAlarm?: {
        notificationFrequency?: number | null;
        updateBuffer?: number | null;
        repeatNotification?: boolean | null;
        subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
      } | null;
      chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
    };
  } | null;
};

export type NodeSensorFragment = {
  _id?: string | null;
  id: string;
  peripheralId: string;
  name: string;
  description: string;
  config: {
    type?: SensorType | null;
    stopThreshold?: number | null;
    findStops?: boolean | null;
    energyMeter?: boolean | null;
    stopRegisterThreshold?: number | null;
    stopValueThreshold?: number | null;
    dataMultiplier?: number | null;
    validatedSpeed?: number | null;
    expectedSpeed?: number | null;
    analogOffset?: number | null;
    analogInputRange?: AnalogInputRange | null;
    publishRate?: number | null;
    sampleRate?: number | null;
    triggerIndex?: number | null;
    chartTimeScale?: ChartTimeScale | null;
    sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
    accumulatorConfig?: { resetsOnPowerOff: boolean; rolloverValue: number; rolloverThreshold?: number | null } | null;
    discreteConfig?: { onStates: Array<number>; offStates: Array<number>; defaultState: DiscreteDeviceState } | null;
    dataAlarm?: {
      notificationFrequency?: number | null;
      updateBuffer?: number | null;
      repeatNotification?: boolean | null;
      subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
    } | null;
    chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
  };
};

export type ScheduleConfigurationFragmentFragment = {
  lineId: string;
  disableLostConnectionAlarmOutsideWorkingHours: boolean;
  timezone: string;
  startDayOfWeek: DayOfWeek;
  automaticStopRegistration: {
    enabled: boolean;
    stopCauseId?: string | null;
    minimumStopMillis?: number | null;
    splitAtEnds?: boolean | null;
    comment?: string | null;
  };
};

export type ScheduleFragmentFragment = {
  id: string;
  lineId: string;
  isExceptionalWeek: boolean;
  isFallbackSchedule: boolean;
  validFrom: { year: number; week: number };
  validTo?: { year: number; week: number } | null;
  shifts: Array<{
    id: string;
    name: string;
    description: string;
    timeRange: {
      from: { day: DayOfWeek; hour: number; minute: number };
      to: { day: DayOfWeek; hour: number; minute: number };
    };
    targets: {
      oee?: number | null;
      oee2?: number | null;
      oee3?: number | null;
      tcu?: number | null;
      produced?: number | null;
      numberOfBatches?: number | null;
    };
  }>;
  weeklyTargets: {
    lineId: string;
    id?: string | null;
    oee?: number | null;
    oee2?: number | null;
    oee3?: number | null;
    tcu?: number | null;
    produced?: number | null;
    numberOfBatches?: number | null;
    mainOeeType?: OeeType | null;
    validFrom?: { year: number; week: number } | null;
  };
  configuration: {
    lineId: string;
    disableLostConnectionAlarmOutsideWorkingHours: boolean;
    timezone: string;
    startDayOfWeek: DayOfWeek;
    automaticStopRegistration: {
      enabled: boolean;
      stopCauseId?: string | null;
      minimumStopMillis?: number | null;
      splitAtEnds?: boolean | null;
      comment?: string | null;
    };
  };
};

export type ShiftFragmentFragment = {
  id: string;
  name: string;
  description: string;
  timeRange: {
    from: { day: DayOfWeek; hour: number; minute: number };
    to: { day: DayOfWeek; hour: number; minute: number };
  };
  targets: {
    oee?: number | null;
    oee2?: number | null;
    oee3?: number | null;
    tcu?: number | null;
    produced?: number | null;
    numberOfBatches?: number | null;
  };
};

export type TargetsFragmentFragment = {
  lineId: string;
  id?: string | null;
  oee?: number | null;
  oee2?: number | null;
  oee3?: number | null;
  tcu?: number | null;
  produced?: number | null;
  numberOfBatches?: number | null;
  mainOeeType?: OeeType | null;
  validFrom?: { year: number; week: number } | null;
};

export type MinimalBatchFragment = {
  batchId: string;
  batchNumber: string;
  plannedStart?: string | null;
  actualStart?: string | null;
  actualStop?: string | null;
  product: { productId: string; name: string; itemNumber: string; parameters: Array<{ key: string; value: string }> };
};

export type PackagingFragment = {
  packagingId: string;
  lineId: string;
  name: string;
  packagingNumber: string;
  unit: string;
  comment?: string | null;
};

type PeripheralFragment_Camera_Fragment = {
  _id?: string | null;
  id: string;
  name: string;
  index: string;
  peripheralId: string;
  owner?: string | null;
  peripheralType: PeripheralType;
  description: string;
  offlineStatus: { expiration?: number | null; lastReceived: number };
};

type PeripheralFragment_Sensor_Fragment = {
  _id?: string | null;
  id: string;
  name: string;
  index: string;
  peripheralId: string;
  owner?: string | null;
  peripheralType: PeripheralType;
  description: string;
  offlineStatus: { expiration?: number | null; lastReceived: number };
};

export type PeripheralFragmentFragment = PeripheralFragment_Camera_Fragment | PeripheralFragment_Sensor_Fragment;

export type PreSignedUrlFragmentFragment = { url: string; objectKey: string };

export type ProductWithAlarmParametersFragment = {
  productId: string;
  name: string;
  itemNumber: string;
  lineId: string;
  validatedLineSpeed: number;
  expectedAverageSpeed: number;
  dataMultiplier: number;
  comment?: string | null;
  parameters: Array<{
    key: string;
    value: string;
    alarm?: {
      id: string;
      name: string;
      description: string;
      status: AlarmStatus;
      threshold: number;
      repeatNotification: boolean;
      enabled: boolean;
      type: AlarmType;
      languageCode: string;
      snoozeDuration?: number | null;
      alarmConfiguration: { x?: number | null; t?: number | null; y?: number | null; n?: number | null };
      peripheral?: { name: string; _id?: string | null } | { name: string; _id?: string | null } | null;
    } | null;
    setpoint?: {
      value: number;
      peripheral: { name: string; id: string; _id?: string | null } | { name: string; id: string; _id?: string | null };
    } | null;
  }>;
};

export type ProductFragment = {
  productId: string;
  name: string;
  itemNumber: string;
  lineId: string;
  validatedLineSpeed: number;
  expectedAverageSpeed: number;
  dataMultiplier: number;
  comment?: string | null;
  parameters: Array<{ key: string; value: string }>;
};

export type ScheduledReportFragment = {
  id: string;
  enabled: boolean;
  type: ReportType;
  entityId: string;
  entityType: EntityType;
  name: string;
  description?: string | null;
  timezone: string;
  subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
  trigger: { type: TriggerType; parameters: string };
};

export type SensorConfigEnumsFragment = {
  units?: { units?: Array<{ name: string; description?: string | null }> | null } | null;
  analogInputRanges?: { analogInputRanges?: Array<{ name: string; description?: string | null }> | null } | null;
  sensorTypes?: { sensorTypes?: Array<{ name: string; description?: string | null }> | null } | null;
};

export type SensorConfigFragment = {
  type?: SensorType | null;
  stopThreshold?: number | null;
  findStops?: boolean | null;
  energyMeter?: boolean | null;
  stopRegisterThreshold?: number | null;
  stopValueThreshold?: number | null;
  dataMultiplier?: number | null;
  validatedSpeed?: number | null;
  expectedSpeed?: number | null;
  analogOffset?: number | null;
  analogInputRange?: AnalogInputRange | null;
  publishRate?: number | null;
  sampleRate?: number | null;
  triggerIndex?: number | null;
  chartTimeScale?: ChartTimeScale | null;
  sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
  accumulatorConfig?: { resetsOnPowerOff: boolean; rolloverValue: number; rolloverThreshold?: number | null } | null;
  discreteConfig?: { onStates: Array<number>; offStates: Array<number>; defaultState: DiscreteDeviceState } | null;
  dataAlarm?: {
    notificationFrequency?: number | null;
    updateBuffer?: number | null;
    repeatNotification?: boolean | null;
    subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
  } | null;
  chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
};

export type SensorFragmentFragment = {
  config: {
    type?: SensorType | null;
    stopThreshold?: number | null;
    findStops?: boolean | null;
    energyMeter?: boolean | null;
    stopRegisterThreshold?: number | null;
    stopValueThreshold?: number | null;
    dataMultiplier?: number | null;
    validatedSpeed?: number | null;
    expectedSpeed?: number | null;
    analogOffset?: number | null;
    analogInputRange?: AnalogInputRange | null;
    publishRate?: number | null;
    sampleRate?: number | null;
    triggerIndex?: number | null;
    chartTimeScale?: ChartTimeScale | null;
    sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
    accumulatorConfig?: { resetsOnPowerOff: boolean; rolloverValue: number; rolloverThreshold?: number | null } | null;
    discreteConfig?: { onStates: Array<number>; offStates: Array<number>; defaultState: DiscreteDeviceState } | null;
    dataAlarm?: {
      notificationFrequency?: number | null;
      updateBuffer?: number | null;
      repeatNotification?: boolean | null;
      subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
    } | null;
    chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
  };
  peripheralInformation?: { node: { type: NodeType }; line: { id: string; name: string } } | null;
};

export type StatsFragment = {
  produced?: number | null;
  longestNonStop?: number | null;
  numberOfStops?: number | null;
  averageStopLength?: number | null;
  downtime?: number | null;
  valueAddingTime?: number | null;
  valueAddingTimeWhileManned?: number | null;
  mtbf?: number | null;
  averageProducedMinute?: number | null;
  averageProducedHour?: number | null;
  cycleTime?: number | null;
  producedPerStop?: number | null;
  speedWhileManned?: number | null;
  speedWhileProducing?: number | null;
  oee?: { oee1: number; oee2: number; oee3: number; tcu: number } | null;
  lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null;
  customKPIs?: Array<{ id: string; name: string; unit: string; value?: number | null }> | null;
};

export type StopCauseCategoriesFragment = {
  _id: string;
  id: string;
  name: string;
  ownerType: OwnerType;
  stopCauses: Array<{
    _id: string;
    id: string;
    name: string;
    description: string;
    categoryName?: string | null;
    languageCode: string;
    stopType: StopType;
    requireInitials: boolean;
    requireComment: boolean;
    enableCountermeasure: boolean;
    meta: Array<{ name: string; description: string; languageCode: string }>;
    targetSetup?: {
      constantTime: number;
      parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
    } | null;
  } | null>;
  meta: Array<{ name: string; languageCode: string }>;
};

export type StopCauseMappingFragment = {
  peripheralIdTarget: string;
  peripheralIdEvent: string;
  value: string;
  stopCauseId: string;
  buffer?: number | null;
  lookForward: number;
  lookBack: number;
  regPriority?: number | null;
  split?: boolean | null;
};

export type StopCauseFragment = {
  _id: string;
  id: string;
  name: string;
  description: string;
  categoryName?: string | null;
  languageCode: string;
  stopType: StopType;
  requireInitials: boolean;
  requireComment: boolean;
  enableCountermeasure: boolean;
  meta: Array<{ name: string; description: string; languageCode: string }>;
  targetSetup?: {
    constantTime: number;
    parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
  } | null;
};

export type StopStatsFragment = {
  longestNonStop: number;
  stopCauseStats: Array<{
    stopCauseId: string;
    stopCauseName: string;
    stopCauseDescription: string;
    stopCategoryName: string;
    type?: StopType | null;
    accDuration: number;
    accTarget: number;
    numberOfStops: number;
    isMicroStop: boolean;
  } | null>;
};

export type StopsFragment = {
  __typename: 'Stop';
  _id: string;
  duration: number;
  ongoing: boolean;
  isMicroStop: boolean;
  comment?: string | null;
  initials?: string | null;
  registeredTime?: string | null;
  countermeasure?: string | null;
  countermeasureInitials?: string | null;
  standalone?: { excludeProduction?: boolean | null } | null;
  target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
  timeRange: { to?: string | null; from?: string | null };
  stopCause?: {
    _id: string;
    id: string;
    name: string;
    description: string;
    categoryName?: string | null;
    languageCode: string;
    stopType: StopType;
    requireInitials: boolean;
    requireComment: boolean;
    enableCountermeasure: boolean;
    meta: Array<{ name: string; description: string; languageCode: string }>;
    targetSetup?: {
      constantTime: number;
      parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
    } | null;
  } | null;
};

export type TestFragment = {
  id: string;
  name: string;
  description: string;
  time: { from?: string | null; to?: string | null };
};

export type VerticalAnnotationFragment = {
  id: string;
  label: string;
  timestamp: string;
  timestampEnd?: string | null;
  tags: Array<string>;
};

export type AlarmsCreateMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  threshold: Scalars['Int'];
  repeatNotification?: InputMaybe<Scalars['Boolean']>;
  type: AlarmType;
  alarmConfiguration: AlarmConfigurationInput;
  enabled?: InputMaybe<Scalars['Boolean']>;
  snoozeDuration?: InputMaybe<Scalars['Int']>;
  subscribers?: InputMaybe<Array<SubscriberInput> | SubscriberInput>;
}>;

export type AlarmsCreateMutation = {
  createAlarm: {
    id: string;
    name: string;
    description: string;
    status: AlarmStatus;
    peripheralId: string;
    threshold: number;
    repeatNotification: boolean;
    enabled: boolean;
    type: AlarmType;
    languageCode: string;
    snoozeDuration?: number | null;
    alarmConfiguration: {
      x?: number | null;
      t?: number | null;
      y?: number | null;
      n?: number | null;
      stopType?: StopTypeForAlarms | null;
    };
    subscribers: Array<{ value: string; type: SubscriberType; languageCode: string }>;
  };
};

export type AlarmsDeleteMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  alarmId: Scalars['ID'];
}>;

export type AlarmsDeleteMutation = { deleteAlarm: number };

export type AlarmsSnoozeMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  alarmId: Scalars['ID'];
}>;

export type AlarmsSnoozeMutation = { snoozeAlarm: number };

export type AlarmsToggleEnabledMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  alarmId: Scalars['ID'];
  enabled?: InputMaybe<Scalars['Boolean']>;
}>;

export type AlarmsToggleEnabledMutation = { updateAlarm: { enabled: boolean } };

export type AlarmsUpdateSubscribersMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  alarmId: Scalars['ID'];
  subscribers: Array<SubscriberInput> | SubscriberInput;
}>;

export type AlarmsUpdateSubscribersMutation = {
  updateSubscribers: {
    id: string;
    name: string;
    peripheralId: string;
    subscribers: Array<{ value: string; languageCode: string; type: SubscriberType }>;
  };
};

export type AlarmsUpdateMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  alarmId: Scalars['ID'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  threshold?: InputMaybe<Scalars['Int']>;
  repeatNotification?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<AlarmType>;
  alarmConfiguration?: InputMaybe<AlarmConfigurationInput>;
  languageCode: Scalars['String'];
  snoozeDuration?: InputMaybe<Scalars['Int']>;
  subscribers: Array<SubscriberInput> | SubscriberInput;
}>;

export type AlarmsUpdateMutation = {
  updateAlarm: {
    id: string;
    name: string;
    description: string;
    status: AlarmStatus;
    peripheralId: string;
    threshold: number;
    repeatNotification: boolean;
    enabled: boolean;
    type: AlarmType;
    languageCode: string;
    snoozeDuration?: number | null;
    alarmConfiguration: {
      x?: number | null;
      t?: number | null;
      y?: number | null;
      n?: number | null;
      stopType?: StopTypeForAlarms | null;
    };
    subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
  };
};

export type AndonAttendanceDowngradeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  scheduleId: Scalars['ID'];
  shiftId: Scalars['ID'];
  input: AttendanceInput;
}>;

export type AndonAttendanceDowngradeMutation = {
  andonAttendanceDowngrade: Array<{
    id: string;
    name: string;
    duration: number;
    rRuleSet: string;
    attendees: Array<{ id: string; name: string; isPermanent?: boolean | null; isExcluded?: boolean | null }>;
  }>;
};

export type AndonAttendanceUpgradeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  scheduleId: Scalars['ID'];
  shiftId: Scalars['ID'];
  input: AttendanceInput;
}>;

export type AndonAttendanceUpgradeMutation = {
  andonAttendanceUpgrade: Array<{
    id: string;
    name: string;
    duration: number;
    rRuleSet: string;
    attendees: Array<{ id: string; name: string; isPermanent?: boolean | null; isExcluded?: boolean | null }>;
  }>;
};

export type AndonCallCancelMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
}>;

export type AndonCallCancelMutation = { andonCallCancel: string };

export type AndonCallCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  author: Scalars['String'];
  lineId: Scalars['ID'];
  roleId?: InputMaybe<Scalars['ID']>;
  tags: Array<Scalars['ID']> | Scalars['ID'];
  urgency: Urgency;
  details: Scalars['String'];
}>;

export type AndonCallCreateMutation = { andonCallCreate: { id: string } };

export type AndonCallReleaseMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
}>;

export type AndonCallReleaseMutation = {
  andonCallRelease: { id: string; action: { take?: { time: string; author: string; details: string } | null } };
};

export type AndonCallReopenMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  resolutionTime: Scalars['Date'];
}>;

export type AndonCallReopenMutation = {
  andonCallReopen: { id: string; action: { resolve?: { time: string; author: string; details: string } | null } };
};

export type AndonCallResolveMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  details: Scalars['String'];
  author: Scalars['String'];
}>;

export type AndonCallResolveMutation = {
  andonCallResolve: { id: string; action: { resolve?: { time: string; author: string; details: string } | null } };
};

export type AndonCallTakeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  author?: InputMaybe<Scalars['String']>;
}>;

export type AndonCallTakeMutation = {
  andonCallTake: { id: string; action: { take?: { time: string; author: string; details: string } | null } };
};

export type AndonCallUpdateTagsMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  tagIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AndonCallUpdateTagsMutation = {
  andonCallUpdateTags: { id: string; tags?: Array<{ id: string; value: string } | null> | null };
};

export type AndonCallWorkOrderInitiateMutationVariables = Exact<{
  callId: Scalars['ID'];
  companyId: Scalars['ID'];
  tagId: Scalars['ID'];
  details: Scalars['String'];
  author: Scalars['String'];
}>;

export type AndonCallWorkOrderInitiateMutation = {
  andonCallWorkOrderInitiate: {
    id: string;
    action: { pending?: { time: string; author: string; details: string } | null };
  };
};

export type AndonRoleCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  input: RoleCreateInput;
}>;

export type AndonRoleCreateMutation = {
  andonRoleCreate: {
    id: string;
    name: string;
    escalationConfiguration?: Array<{
      delay?: number | null;
      takenDelay?: number | null;
      type: SubscriptionType;
    }> | null;
  };
};

export type AndonRoleDeleteMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type AndonRoleDeleteMutation = {
  andonRoleDelete: Array<{
    id: string;
    name: string;
    escalationConfiguration?: Array<{
      delay?: number | null;
      takenDelay?: number | null;
      type: SubscriptionType;
    }> | null;
  }>;
};

export type AndonRoleUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  input: RoleUpdateInput;
}>;

export type AndonRoleUpdateMutation = {
  andonRoleUpdate: {
    id: string;
    name: string;
    escalationConfiguration?: Array<{
      delay?: number | null;
      takenDelay?: number | null;
      type: SubscriptionType;
    }> | null;
  };
};

export type AndonScheduleCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  input: ScheduleCreateInput;
}>;

export type AndonScheduleCreateMutation = {
  andonScheduleCreate: {
    id: string;
    name: string;
    lines: Array<{ id: string; name: string }>;
    shifts: Array<{
      id: string;
      name: string;
      duration: number;
      rRuleSet: string;
      attendees: Array<{ id: string; name: string }>;
    }>;
  };
};

export type AndonScheduleDeleteMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type AndonScheduleDeleteMutation = { andonScheduleDelete: boolean };

export type AndonScheduleEventAttendMutationVariables = Exact<{
  companyId: Scalars['ID'];
  eventId: Scalars['ID'];
  supportId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
}>;

export type AndonScheduleEventAttendMutation = {
  andonScheduleEventAttend: {
    id: string;
    rRuleSet: string;
    lineId: string;
    summary: string;
    description?: string | null;
    duration: number;
    attendees: Array<{
      id: string;
      email?: string | null;
      phoneNumber?: string | null;
      dateExceptions: Array<string>;
      supportId: string;
      messages: Array<{ id: string; type: SubscriptionType; delay: number; takenDelay?: number | null }>;
    }>;
  };
};

export type AndonScheduleEventAttendeeAddMessageMutationVariables = Exact<{
  companyId: Scalars['ID'];
  eventId: Scalars['ID'];
  attendeeId: Scalars['ID'];
  type?: InputMaybe<SubscriptionType>;
  delay?: InputMaybe<Scalars['Float']>;
  takenDelay?: InputMaybe<Scalars['Float']>;
}>;

export type AndonScheduleEventAttendeeAddMessageMutation = {
  andonScheduleEventAttendeeAddMessage: Array<{
    id: string;
    type: SubscriptionType;
    delay: number;
    takenDelay?: number | null;
  }>;
};

export type AndonScheduleEventAttendeeDeleteMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  eventId: Scalars['ID'];
  attendeeId: Scalars['ID'];
}>;

export type AndonScheduleEventAttendeeDeleteMessageMutation = {
  andonScheduleEventAttendeeDeleteMessage: Array<{
    id: string;
    type: SubscriptionType;
    delay: number;
    takenDelay?: number | null;
  }>;
};

export type AndonScheduleEventCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  lineId: Scalars['ID'];
  summary: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Float'];
  rRuleSet: Scalars['String'];
}>;

export type AndonScheduleEventCreateMutation = {
  andonScheduleEventCreate: {
    id: string;
    rRuleSet: string;
    lineId: string;
    summary: string;
    description?: string | null;
    duration: number;
    attendees: Array<{
      id: string;
      email?: string | null;
      phoneNumber?: string | null;
      dateExceptions: Array<string>;
      supportId: string;
      messages: Array<{ id: string; type: SubscriptionType; delay: number; takenDelay?: number | null }>;
    }>;
  };
};

export type AndonScheduleEventDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
}>;

export type AndonScheduleEventDeleteMutation = { andonScheduleEventDelete: boolean };

export type AndonScheduleEventUnattendMutationVariables = Exact<{
  companyId: Scalars['ID'];
  eventId: Scalars['ID'];
  attendeeId: Scalars['ID'];
  date?: InputMaybe<Scalars['Date']>;
}>;

export type AndonScheduleEventUnattendMutation = {
  andonScheduleEventUnattend: {
    id: string;
    rRuleSet: string;
    lineId: string;
    summary: string;
    description?: string | null;
    duration: number;
    attendees: Array<{
      id: string;
      email?: string | null;
      phoneNumber?: string | null;
      dateExceptions: Array<string>;
      supportId: string;
      messages: Array<{ id: string; type: SubscriptionType; delay: number; takenDelay?: number | null }>;
    }>;
  };
};

export type AndonScheduleEventUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  lineId?: InputMaybe<Scalars['ID']>;
  summary?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  rRuleSet?: InputMaybe<Scalars['String']>;
}>;

export type AndonScheduleEventUpdateMutation = {
  andonScheduleEventUpdate: {
    id: string;
    rRuleSet: string;
    lineId: string;
    summary: string;
    description?: string | null;
    duration: number;
    attendees: Array<{
      id: string;
      email?: string | null;
      phoneNumber?: string | null;
      dateExceptions: Array<string>;
      supportId: string;
      messages: Array<{ id: string; type: SubscriptionType; delay: number; takenDelay?: number | null }>;
    }>;
  };
};

export type AndonScheduleUpdateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: ScheduleUpdateInput;
}>;

export type AndonScheduleUpdateMutation = {
  andonScheduleUpdate: {
    id: string;
    name: string;
    lines: Array<{ id: string; name: string }>;
    shifts: Array<{
      id: string;
      name: string;
      duration: number;
      rRuleSet: string;
      attendees: Array<{ id: string; name: string }>;
    }>;
  };
};

export type AndonShiftCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  scheduleId: Scalars['ID'];
  input: ShiftCreateInput;
}>;

export type AndonShiftCreateMutation = {
  andonShiftCreate: Array<{
    id: string;
    name: string;
    description?: string | null;
    duration: number;
    rRuleSet: string;
  }>;
};

export type AndonShiftDeleteMutationVariables = Exact<{
  companyId: Scalars['ID'];
  scheduleId: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type AndonShiftDeleteMutation = { andonShiftDelete: boolean };

export type AndonShiftUpdateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  scheduleId: Scalars['ID'];
  id: Scalars['ID'];
  input: ShiftUpdateInput;
}>;

export type AndonShiftUpdateMutation = {
  andonShiftUpdate: Array<{
    id: string;
    name: string;
    description?: string | null;
    duration: number;
    rRuleSet: string;
  }>;
};

export type AndonSupportCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  name: Scalars['String'];
  languageCode?: InputMaybe<Scalars['String']>;
}>;

export type AndonSupportCreateMutation = { andonSupportCreate: { id: string; name: string } };

export type AndonSupportDeleteMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type AndonSupportDeleteMutation = { andonSupportDelete: Array<{ id: string; name: string }> };

export type AndonWorkerCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  input: WorkerCreateInput;
}>;

export type AndonWorkerCreateMutation = {
  andonWorkerCreate: {
    id: string;
    name: string;
    email?: string | null;
    phoneNumber?: string | null;
    preferredSchedules: Array<{ id: string; name: string }>;
    role: {
      id: string;
      name: string;
      escalationConfiguration?: Array<{
        delay?: number | null;
        takenDelay?: number | null;
        type: SubscriptionType;
      }> | null;
    };
  };
};

export type AndonWorkerDeleteMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type AndonWorkerDeleteMutation = {
  andonWorkerDelete: Array<{
    id: string;
    name: string;
    email?: string | null;
    phoneNumber?: string | null;
    preferredSchedules: Array<{ id: string; name: string }>;
    role: {
      id: string;
      name: string;
      escalationConfiguration?: Array<{
        delay?: number | null;
        takenDelay?: number | null;
        type: SubscriptionType;
      }> | null;
    };
  }>;
};

export type AndonWorkerUpdateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input: WorkerUpdateInput;
}>;

export type AndonWorkerUpdateMutation = {
  andonWorkerUpdate: {
    id: string;
    name: string;
    email?: string | null;
    phoneNumber?: string | null;
    preferredSchedules: Array<{ id: string; name: string }>;
    role: {
      id: string;
      name: string;
      escalationConfiguration?: Array<{
        delay?: number | null;
        takenDelay?: number | null;
        type: SubscriptionType;
      }> | null;
    };
  };
};

export type AnnotationHorizontalCreateMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  horizontalAnnotation: HorizontalAnnotationInput;
}>;

export type AnnotationHorizontalCreateMutation = {
  addHorizontalAnnotation: { id: string; label: string; axisValue: string };
};

export type AnnotationVerticalCreateMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  verticalAnnotation: VerticalAnnotationInput;
}>;

export type AnnotationVerticalCreateMutation = {
  addVerticalAnnotation: {
    id: string;
    label: string;
    timestamp: string;
    timestampEnd?: string | null;
    tags: Array<string>;
  };
};

export type AnnotationVerticalRemoveMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  timestamp: Scalars['Date'];
  verticalAnnotationId: Scalars['ID'];
}>;

export type AnnotationVerticalRemoveMutation = { removeVerticalAnnotation: boolean };

export type AnnotationVerticalUpdateMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  verticalAnnotationId: Scalars['ID'];
  oldTimestamp: Scalars['Date'];
  newVerticalAnnotation: VerticalAnnotationInput;
}>;

export type AnnotationVerticalUpdateMutation = {
  updateVerticalAnnotation: {
    id: string;
    label: string;
    timestamp: string;
    timestampEnd?: string | null;
    tags: Array<string>;
  };
};

export type BatchCancelBatchControlMutationVariables = Exact<{
  lineId: Scalars['ID'];
  batchId: Scalars['ID'];
  controlId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
}>;

export type BatchCancelBatchControlMutation = {
  cancelBatchControl: {
    batchControlId: string;
    controlReceiptId: string;
    controlReceiptName: string;
    entryId: string;
    title?: string | null;
    batchId: string;
    status: BatchControlStatus;
    timeTriggered: string;
    timeControlled?: string | null;
    timeControlUpdated?: string | null;
    comment?: string | null;
    initials?: string | null;
    fieldValues: Array<{
      value?: string | null;
      controlReceiptField: {
        fieldId: string;
        label: string;
        description?: string | null;
        type: ControlFieldType;
        sensorId?: string | null;
        limits?: { lower?: number | null; upper?: number | null } | null;
        derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
      };
    }>;
    trigger: { delayWhenStopped: boolean; type: ControlTriggerType; payload?: { millis: number } | null };
    followUp: { enabled: boolean; delayMs?: number | null };
    originalControl?: { controlId: string; timeTriggered: string } | null;
    history: Array<{
      status: BatchControlStatus;
      timeControlled?: string | null;
      timeControlUpdated?: string | null;
      comment?: string | null;
      initials?: string | null;
      fieldValues: Array<{
        value?: string | null;
        controlReceiptField: {
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        };
      }>;
    }>;
  };
};

export type BatchCancelPendingBatchControlsMutationVariables = Exact<{
  lineId: Scalars['ID'];
  batchId: Scalars['ID'];
  controlIds: Array<Scalars['ID']> | Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
}>;

export type BatchCancelPendingBatchControlsMutation = {
  cancelPendingBatchControls: Array<{
    batchControlId: string;
    controlReceiptId: string;
    controlReceiptName: string;
    entryId: string;
    title?: string | null;
    batchId: string;
    status: BatchControlStatus;
    timeTriggered: string;
    timeControlled?: string | null;
    timeControlUpdated?: string | null;
    comment?: string | null;
    initials?: string | null;
    fieldValues: Array<{
      value?: string | null;
      controlReceiptField: {
        fieldId: string;
        label: string;
        description?: string | null;
        type: ControlFieldType;
        sensorId?: string | null;
        limits?: { lower?: number | null; upper?: number | null } | null;
        derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
      };
    }>;
    trigger: { delayWhenStopped: boolean; type: ControlTriggerType; payload?: { millis: number } | null };
    followUp: { enabled: boolean; delayMs?: number | null };
    originalControl?: { controlId: string; timeTriggered: string } | null;
    history: Array<{
      status: BatchControlStatus;
      timeControlled?: string | null;
      timeControlUpdated?: string | null;
      comment?: string | null;
      initials?: string | null;
      fieldValues: Array<{
        value?: string | null;
        controlReceiptField: {
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        };
      }>;
    }>;
  }>;
};

export type BatchControlReceiptCreateMutationVariables = Exact<{
  userPoolId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  entries: Array<ControlReceiptEntryInput> | ControlReceiptEntryInput;
}>;

export type BatchControlReceiptCreateMutation = {
  createControlReceipt: {
    controlReceiptId: string;
    name: string;
    description?: string | null;
    entries: Array<{
      entryId: string;
      title?: string | null;
      trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
      followUp: { enabled: boolean; delayMs?: number | null };
      fields: Array<{
        fieldId: string;
        label: string;
        description?: string | null;
        type: ControlFieldType;
        sensorId?: string | null;
        limits?: { lower?: number | null; upper?: number | null } | null;
        derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
      }>;
    }>;
  };
};

export type BatchControlReceiptDeleteMutationVariables = Exact<{
  controlReceiptId: Scalars['ID'];
  userPoolId: Scalars['ID'];
}>;

export type BatchControlReceiptDeleteMutation = { deleteControlReceipt: string };

export type BatchControlReceiptUpdateMutationVariables = Exact<{
  controlReceiptId: Scalars['ID'];
  userPoolId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  entries?: InputMaybe<Array<ControlReceiptEntryInput> | ControlReceiptEntryInput>;
  updateRecipeAndPendingBatches?: InputMaybe<Scalars['Boolean']>;
}>;

export type BatchControlReceiptUpdateMutation = {
  updateControlReceipt: {
    controlReceiptId: string;
    name: string;
    description?: string | null;
    entries: Array<{
      entryId: string;
      title?: string | null;
      trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
      followUp: { enabled: boolean; delayMs?: number | null };
      fields: Array<{
        fieldId: string;
        label: string;
        description?: string | null;
        type: ControlFieldType;
        sensorId?: string | null;
        limits?: { lower?: number | null; upper?: number | null } | null;
        derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
      }>;
    }>;
  };
};

export type BatchCreateMutationVariables = Exact<{
  lineId: Scalars['ID'];
  batchNumber: Scalars['String'];
  plannedStart: Scalars['Date'];
  actualStart?: InputMaybe<Scalars['Date']>;
  actualStop?: InputMaybe<Scalars['Date']>;
  amount: Scalars['Float'];
  manualScrap?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
  dataMultiplier?: InputMaybe<Scalars['Float']>;
  validatedLineSpeed?: InputMaybe<Scalars['Float']>;
  expectedAverageSpeed?: InputMaybe<Scalars['Float']>;
}>;

export type BatchCreateMutation = {
  createBatch: {
    batchId: string;
    batchNumber: string;
    state: BatchState;
    amount: number;
    manualScrap?: number | null;
    plannedStart?: string | null;
    actualStart?: string | null;
    actualStop?: string | null;
    comment?: string | null;
    lineId: string;
    product: {
      productId: string;
      name: string;
      itemNumber: string;
      lineId: string;
      validatedLineSpeed: number;
      expectedAverageSpeed: number;
      dataMultiplier: number;
      comment?: string | null;
      packaging: {
        packagingId: string;
        lineId: string;
        name: string;
        packagingNumber: string;
        unit: string;
        comment?: string | null;
      };
      attachedControlReceipts: Array<{
        controlReceiptId: string;
        name: string;
        description?: string | null;
        entries: Array<{
          entryId: string;
          title?: string | null;
          trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
          followUp: { enabled: boolean; delayMs?: number | null };
          fields: Array<{
            fieldId: string;
            label: string;
            description?: string | null;
            type: ControlFieldType;
            sensorId?: string | null;
            limits?: { lower?: number | null; upper?: number | null } | null;
            derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
          }>;
        }>;
      }>;
      parameters: Array<{ key: string; value: string }>;
    };
  };
};

export type BatchDeleteMutationVariables = Exact<{
  lineId?: InputMaybe<Scalars['ID']>;
  batchId: Scalars['ID'];
}>;

export type BatchDeleteMutation = { deleteBatch: string };

export type BatchPackagingCreateMutationVariables = Exact<{
  lineId: Scalars['ID'];
  name: Scalars['String'];
  packagingNumber: Scalars['String'];
  unit: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
}>;

export type BatchPackagingCreateMutation = {
  createPackaging: {
    packagingId: string;
    lineId: string;
    name: string;
    packagingNumber: string;
    unit: string;
    comment?: string | null;
  };
};

export type BatchPackagingDeleteMutationVariables = Exact<{
  packagingId: Scalars['ID'];
  lineId: Scalars['ID'];
}>;

export type BatchPackagingDeleteMutation = { deletePackaging: string };

export type BatchPackagingUpdateMutationVariables = Exact<{
  packagingId: Scalars['ID'];
  lineId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  packagingNumber: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
}>;

export type BatchPackagingUpdateMutation = {
  updatePackaging: {
    packagingId: string;
    lineId: string;
    name: string;
    packagingNumber: string;
    unit: string;
    comment?: string | null;
  };
};

export type BatchProductAttachControlReceiptMutationVariables = Exact<{
  lineId: Scalars['ID'];
  productId: Scalars['ID'];
  userPoolId: Scalars['ID'];
  controlReceiptId: Scalars['ID'];
}>;

export type BatchProductAttachControlReceiptMutation = {
  attachControlReceipt: {
    productId: string;
    name: string;
    itemNumber: string;
    lineId: string;
    validatedLineSpeed: number;
    expectedAverageSpeed: number;
    dataMultiplier: number;
    comment?: string | null;
    packaging: {
      packagingId: string;
      lineId: string;
      name: string;
      packagingNumber: string;
      unit: string;
      comment?: string | null;
    };
    attachedControlReceipts: Array<{
      controlReceiptId: string;
      name: string;
      description?: string | null;
      entries: Array<{
        entryId: string;
        title?: string | null;
        trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
        followUp: { enabled: boolean; delayMs?: number | null };
        fields: Array<{
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        }>;
      }>;
    }>;
    parameters: Array<{ key: string; value: string }>;
  };
};

export type BatchProductCreateMutationVariables = Exact<{
  lineId: Scalars['ID'];
  name: Scalars['String'];
  itemNumber: Scalars['String'];
  validatedSpeed: Scalars['Float'];
  expectedSpeed: Scalars['Float'];
  comment?: InputMaybe<Scalars['String']>;
  packagingId: Scalars['ID'];
  parameters?: InputMaybe<Array<InputParameter> | InputParameter>;
  dataMultiplier: Scalars['Float'];
}>;

export type BatchProductCreateMutation = {
  createProduct: {
    productId: string;
    name: string;
    itemNumber: string;
    lineId: string;
    validatedLineSpeed: number;
    expectedAverageSpeed: number;
    dataMultiplier: number;
    comment?: string | null;
    packaging: {
      packagingId: string;
      lineId: string;
      name: string;
      packagingNumber: string;
      unit: string;
      comment?: string | null;
    };
    attachedControlReceipts: Array<{
      controlReceiptId: string;
      name: string;
      description?: string | null;
      entries: Array<{
        entryId: string;
        title?: string | null;
        trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
        followUp: { enabled: boolean; delayMs?: number | null };
        fields: Array<{
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        }>;
      }>;
    }>;
    parameters: Array<{ key: string; value: string }>;
  };
};

export type BatchProductDeleteMutationVariables = Exact<{
  productId: Scalars['ID'];
  lineId: Scalars['ID'];
}>;

export type BatchProductDeleteMutation = { deleteProduct: string };

export type BatchProductDetachControlReceiptMutationVariables = Exact<{
  lineId: Scalars['ID'];
  productId: Scalars['ID'];
  userPoolId: Scalars['ID'];
  controlReceiptId: Scalars['ID'];
}>;

export type BatchProductDetachControlReceiptMutation = {
  detachControlReceipt: {
    productId: string;
    name: string;
    itemNumber: string;
    lineId: string;
    validatedLineSpeed: number;
    expectedAverageSpeed: number;
    dataMultiplier: number;
    comment?: string | null;
    packaging: {
      packagingId: string;
      lineId: string;
      name: string;
      packagingNumber: string;
      unit: string;
      comment?: string | null;
    };
    attachedControlReceipts: Array<{
      controlReceiptId: string;
      name: string;
      description?: string | null;
      entries: Array<{
        entryId: string;
        title?: string | null;
        trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
        followUp: { enabled: boolean; delayMs?: number | null };
        fields: Array<{
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        }>;
      }>;
    }>;
    parameters: Array<{ key: string; value: string }>;
  };
};

export type BatchProductUpdateProductParametersMutationVariables = Exact<{
  productId: Scalars['ID'];
  lineId: Scalars['ID'];
  parameters: Array<InputParameter> | InputParameter;
}>;

export type BatchProductUpdateProductParametersMutation = {
  updateProduct: {
    productId: string;
    name: string;
    itemNumber: string;
    lineId: string;
    validatedLineSpeed: number;
    expectedAverageSpeed: number;
    dataMultiplier: number;
    comment?: string | null;
    packaging: {
      packagingId: string;
      lineId: string;
      name: string;
      packagingNumber: string;
      unit: string;
      comment?: string | null;
    };
    attachedControlReceipts: Array<{
      controlReceiptId: string;
      name: string;
      description?: string | null;
      entries: Array<{
        entryId: string;
        title?: string | null;
        trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
        followUp: { enabled: boolean; delayMs?: number | null };
        fields: Array<{
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        }>;
      }>;
    }>;
    parameters: Array<{
      key: string;
      value: string;
      alarm?: {
        id: string;
        name: string;
        description: string;
        status: AlarmStatus;
        threshold: number;
        repeatNotification: boolean;
        enabled: boolean;
        type: AlarmType;
        languageCode: string;
        snoozeDuration?: number | null;
        alarmConfiguration: { x?: number | null; t?: number | null; y?: number | null; n?: number | null };
        peripheral?: { name: string; _id?: string | null } | { name: string; _id?: string | null } | null;
      } | null;
      setpoint?: {
        value: number;
        peripheral:
          | { name: string; id: string; _id?: string | null }
          | { name: string; id: string; _id?: string | null };
      } | null;
    }>;
  };
};

export type BatchProductUpdateMutationVariables = Exact<{
  productId: Scalars['ID'];
  lineId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  itemNumber?: InputMaybe<Scalars['String']>;
  validatedSpeed?: InputMaybe<Scalars['Float']>;
  expectedSpeed?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
  packagingId?: InputMaybe<Scalars['ID']>;
  updateBatches?: InputMaybe<Scalars['Boolean']>;
  parameters?: InputMaybe<Array<InputParameter> | InputParameter>;
  dataMultiplier?: InputMaybe<Scalars['Float']>;
}>;

export type BatchProductUpdateMutation = {
  updateProduct: {
    productId: string;
    name: string;
    itemNumber: string;
    lineId: string;
    validatedLineSpeed: number;
    expectedAverageSpeed: number;
    dataMultiplier: number;
    comment?: string | null;
    packaging: {
      packagingId: string;
      lineId: string;
      name: string;
      packagingNumber: string;
      unit: string;
      comment?: string | null;
    };
    attachedControlReceipts: Array<{
      controlReceiptId: string;
      name: string;
      description?: string | null;
      entries: Array<{
        entryId: string;
        title?: string | null;
        trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
        followUp: { enabled: boolean; delayMs?: number | null };
        fields: Array<{
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        }>;
      }>;
    }>;
    parameters: Array<{
      key: string;
      value: string;
      alarm?: {
        id: string;
        name: string;
        description: string;
        status: AlarmStatus;
        threshold: number;
        repeatNotification: boolean;
        enabled: boolean;
        type: AlarmType;
        languageCode: string;
        snoozeDuration?: number | null;
        alarmConfiguration: { x?: number | null; t?: number | null; y?: number | null; n?: number | null };
        peripheral?: { name: string; _id?: string | null } | { name: string; _id?: string | null } | null;
      } | null;
      setpoint?: {
        value: number;
        peripheral:
          | { name: string; id: string; _id?: string | null }
          | { name: string; id: string; _id?: string | null };
      } | null;
    }>;
  };
};

export type BatchUpdateBatchControlMutationVariables = Exact<{
  lineId: Scalars['ID'];
  batchId: Scalars['ID'];
  controlId: Scalars['ID'];
  fields?: InputMaybe<Array<BatchControlFieldInput> | BatchControlFieldInput>;
  comment?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
}>;

export type BatchUpdateBatchControlMutation = {
  updateBatchControl: {
    batchControlId: string;
    controlReceiptId: string;
    controlReceiptName: string;
    entryId: string;
    title?: string | null;
    batchId: string;
    status: BatchControlStatus;
    timeTriggered: string;
    timeControlled?: string | null;
    timeControlUpdated?: string | null;
    comment?: string | null;
    initials?: string | null;
    fieldValues: Array<{
      value?: string | null;
      controlReceiptField: {
        fieldId: string;
        label: string;
        description?: string | null;
        type: ControlFieldType;
        sensorId?: string | null;
        limits?: { lower?: number | null; upper?: number | null } | null;
        derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
      };
    }>;
    trigger: { delayWhenStopped: boolean; type: ControlTriggerType; payload?: { millis: number } | null };
    followUp: { enabled: boolean; delayMs?: number | null };
    originalControl?: { controlId: string; timeTriggered: string } | null;
    history: Array<{
      status: BatchControlStatus;
      timeControlled?: string | null;
      timeControlUpdated?: string | null;
      comment?: string | null;
      initials?: string | null;
      fieldValues: Array<{
        value?: string | null;
        controlReceiptField: {
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        };
      }>;
    }>;
  };
};

export type BatchUpdatePendingBatchControlMutationVariables = Exact<{
  lineId: Scalars['ID'];
  batchId: Scalars['ID'];
  controlId: Scalars['ID'];
  fields?: InputMaybe<Array<BatchControlFieldInput> | BatchControlFieldInput>;
  comment?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
}>;

export type BatchUpdatePendingBatchControlMutation = {
  updatePendingBatchControl: {
    batchControlId: string;
    controlReceiptId: string;
    controlReceiptName: string;
    entryId: string;
    title?: string | null;
    batchId: string;
    status: BatchControlStatus;
    timeTriggered: string;
    timeControlled?: string | null;
    timeControlUpdated?: string | null;
    comment?: string | null;
    initials?: string | null;
    fieldValues: Array<{
      value?: string | null;
      controlReceiptField: {
        fieldId: string;
        label: string;
        description?: string | null;
        type: ControlFieldType;
        sensorId?: string | null;
        limits?: { lower?: number | null; upper?: number | null } | null;
        derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
      };
    }>;
    trigger: { delayWhenStopped: boolean; type: ControlTriggerType; payload?: { millis: number } | null };
    followUp: { enabled: boolean; delayMs?: number | null };
    originalControl?: { controlId: string; timeTriggered: string } | null;
    history: Array<{
      status: BatchControlStatus;
      timeControlled?: string | null;
      timeControlUpdated?: string | null;
      comment?: string | null;
      initials?: string | null;
      fieldValues: Array<{
        value?: string | null;
        controlReceiptField: {
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        };
      }>;
    }>;
  };
};

export type BatchUpdateTimesMutationVariables = Exact<{
  lineId: Scalars['ID'];
  batchId: Scalars['ID'];
  startTime?: InputMaybe<Scalars['Date']>;
  endTime?: InputMaybe<Scalars['Date']>;
  manualScrap?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
}>;

export type BatchUpdateTimesMutation = {
  updateBatch: {
    batchId: string;
    lineId: string;
    actualStart?: string | null;
    actualStop?: string | null;
    manualScrap?: number | null;
    comment?: string | null;
  };
};

export type BatchUpdateMutationVariables = Exact<{
  lineId: Scalars['ID'];
  batchId: Scalars['ID'];
  batchNumber?: InputMaybe<Scalars['String']>;
  plannedStart?: InputMaybe<Scalars['Date']>;
  actualStart?: InputMaybe<Scalars['Date']>;
  actualStop?: InputMaybe<Scalars['Date']>;
  amount?: InputMaybe<Scalars['Float']>;
  manualScrap?: InputMaybe<Scalars['Float']>;
  productId?: InputMaybe<Scalars['ID']>;
  comment?: InputMaybe<Scalars['String']>;
  dataMultiplier?: InputMaybe<Scalars['Float']>;
  validatedLineSpeed?: InputMaybe<Scalars['Float']>;
  expectedAverageSpeed?: InputMaybe<Scalars['Float']>;
}>;

export type BatchUpdateMutation = {
  updateBatch: {
    batchId: string;
    batchNumber: string;
    state: BatchState;
    amount: number;
    manualScrap?: number | null;
    plannedStart?: string | null;
    actualStart?: string | null;
    actualStop?: string | null;
    comment?: string | null;
    lineId: string;
    product: {
      productId: string;
      name: string;
      itemNumber: string;
      lineId: string;
      validatedLineSpeed: number;
      expectedAverageSpeed: number;
      dataMultiplier: number;
      comment?: string | null;
      packaging: {
        packagingId: string;
        lineId: string;
        name: string;
        packagingNumber: string;
        unit: string;
        comment?: string | null;
      };
      attachedControlReceipts: Array<{
        controlReceiptId: string;
        name: string;
        description?: string | null;
        entries: Array<{
          entryId: string;
          title?: string | null;
          trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
          followUp: { enabled: boolean; delayMs?: number | null };
          fields: Array<{
            fieldId: string;
            label: string;
            description?: string | null;
            type: ControlFieldType;
            sensorId?: string | null;
            limits?: { lower?: number | null; upper?: number | null } | null;
            derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
          }>;
        }>;
      }>;
      parameters: Array<{ key: string; value: string }>;
    };
  };
};

export type ClaimDeviceMutationVariables = Exact<{
  input: ClaimDeviceInput;
}>;

export type ClaimDeviceMutation = { claimDevice: { uuid: string; hardwareId: string; type: string } };

export type CockpitBatchSetAlarmEnablementMutationVariables = Exact<{
  input: SetAlarmEnablementInput;
}>;

export type CockpitBatchSetAlarmEnablementMutation = { batchSetAlarmEnablement: string };

export type CreateAnalyticsDocumentMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  documentInputHeaders: DocumentInputHeaders;
  documentInputs: DocumentInputAnalytics;
}>;

export type CreateAnalyticsDocumentMutation = {
  createAnalyticsDocument: { url: string; name: string; generatedAt: string };
};

export type CreateBatchesDocumentMutationVariables = Exact<{
  lineId: Scalars['ID'];
  documentInputHeaders: DocumentInputHeaders;
  documentInputs: DocumentInputBatches;
}>;

export type CreateBatchesDocumentMutation = {
  createBatchesDocument: { url: string; name: string; generatedAt: string };
};

export type CreateExportStopsDocumentMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  documentInputHeaders: DocumentInputHeaders;
}>;

export type CreateExportStopsDocumentMutation = {
  createExportStopsDocument: { url: string; name: string; generatedAt: string };
};

export type CreateGroupOeeDocumentMutationVariables = Exact<{
  groupId: Scalars['ID'];
  documentInputHeaders: DocumentInputHeaders;
  documentInputs: DocumentInputLinesGroupOee;
}>;

export type CreateGroupOeeDocumentMutation = {
  createGroupOEEDocument: { url: string; name: string; generatedAt: string };
};

export type CreateLiveDataDocumentMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  documentInputHeaders: DocumentInputHeaders;
  documentInputs: DocumentInputLiveData;
}>;

export type CreateLiveDataDocumentMutation = {
  createLiveDataDocument: { url: string; name: string; generatedAt: string };
};

export type CreateOeeDocumentMutationVariables = Exact<{
  lineId: Scalars['ID'];
  documentInputHeaders: DocumentInputHeaders;
  documentInputs: DocumentInputLineOee;
}>;

export type CreateOeeDocumentMutation = { createOEEDocument: { url: string; name: string; generatedAt: string } };

export type CreatePeripheralMutationVariables = Exact<{
  softwareId: Scalars['ID'];
  input: CreatePeripheralInput;
  initialGroups?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type CreatePeripheralMutation = {
  createPeripheral?:
    | {
        _id?: string | null;
        id: string;
        peripheralId: string;
        peripheralType: PeripheralType;
        name: string;
        description: string;
      }
    | {
        _id?: string | null;
        id: string;
        peripheralId: string;
        peripheralType: PeripheralType;
        name: string;
        description: string;
        config: {
          type?: SensorType | null;
          stopThreshold?: number | null;
          findStops?: boolean | null;
          energyMeter?: boolean | null;
          stopRegisterThreshold?: number | null;
          stopValueThreshold?: number | null;
          dataMultiplier?: number | null;
          validatedSpeed?: number | null;
          expectedSpeed?: number | null;
          analogOffset?: number | null;
          analogInputRange?: AnalogInputRange | null;
          publishRate?: number | null;
          sampleRate?: number | null;
          triggerIndex?: number | null;
          chartTimeScale?: ChartTimeScale | null;
          sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
          accumulatorConfig?: {
            resetsOnPowerOff: boolean;
            rolloverValue: number;
            rolloverThreshold?: number | null;
          } | null;
          discreteConfig?: {
            onStates: Array<number>;
            offStates: Array<number>;
            defaultState: DiscreteDeviceState;
          } | null;
          dataAlarm?: {
            notificationFrequency?: number | null;
            updateBuffer?: number | null;
            repeatNotification?: boolean | null;
            subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
          } | null;
          chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
        };
      }
    | null;
};

export type CreatePreSignedUrlMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
}>;

export type CreatePreSignedUrlMutation = { createPreSignedURL: { url: string; objectKey: string } };

export type DeleteDeviceMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type DeleteDeviceMutation = { deleteDevice: boolean };

export type DeletePeripheralMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
}>;

export type DeletePeripheralMutation = { deletePeripheral: boolean };

export type DeviceAttachCameraMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  sensorPeripheralId: Scalars['ID'];
}>;

export type DeviceAttachCameraMutation = {
  attachCamera: {
    _id?: string | null;
    peripheralId: string;
    attachedSensors: Array<{
      _id?: string | null;
      peripheralId: string;
      peripheralType: PeripheralType;
      index: string;
      name: string;
      description: string;
    } | null>;
  };
};

export type DeviceCameraDownloadVideoClipMutationVariables = Exact<{
  uuid: Scalars['ID'];
  startTime: Scalars['Date'];
}>;

export type DeviceCameraDownloadVideoClipMutation = { downloadVideoClip: { videoClipUrl: string } };

export type DeviceDataOverrideDeleteMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  from: Scalars['Date'];
}>;

export type DeviceDataOverrideDeleteMutation = {
  dataOverrideDelete: {
    peripheralId: string;
    value: number;
    author: string;
    comment: string;
    timeRange: { from?: string | null; to?: string | null };
  };
};

export type DeviceDataOverrideUpsertMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  input: DataOverrideUpsertInput;
}>;

export type DeviceDataOverrideUpsertMutation = {
  dataOverrideUpsert: {
    peripheralId: string;
    value: number;
    author: string;
    comment: string;
    timeRange: { from?: string | null; to?: string | null };
  };
};

export type DeviceDetachCameraMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  sensorPeripheralId: Scalars['ID'];
}>;

export type DeviceDetachCameraMutation = {
  detachCamera: {
    _id?: string | null;
    peripheralId: string;
    attachedSensors: Array<{
      _id?: string | null;
      peripheralId: string;
      peripheralType: PeripheralType;
      index: string;
      name: string;
      description: string;
    } | null>;
  };
};

export type DeviceUpdateDeviceMutationVariables = Exact<{
  uuid: Scalars['ID'];
  meta?: InputMaybe<DeviceMetaInput>;
}>;

export type DeviceUpdateDeviceMutation = {
  updateDevice: { _id?: string | null; uuid: string; hardwareId: string; type: string; name?: string | null };
};

export type DeviceUpdatePeripheralMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  meta?: InputMaybe<PeripheralMetaInput>;
  config?: InputMaybe<UpdateConfigInput>;
}>;

export type DeviceUpdatePeripheralMutation = {
  updatePeripheral?:
    | {
        _id?: string | null;
        id: string;
        peripheralId: string;
        peripheralType: PeripheralType;
        name: string;
        description: string;
      }
    | {
        _id?: string | null;
        id: string;
        peripheralId: string;
        peripheralType: PeripheralType;
        name: string;
        description: string;
        config: {
          type?: SensorType | null;
          stopThreshold?: number | null;
          findStops?: boolean | null;
          energyMeter?: boolean | null;
          stopRegisterThreshold?: number | null;
          stopValueThreshold?: number | null;
          dataMultiplier?: number | null;
          validatedSpeed?: number | null;
          expectedSpeed?: number | null;
          analogOffset?: number | null;
          analogInputRange?: AnalogInputRange | null;
          publishRate?: number | null;
          sampleRate?: number | null;
          triggerIndex?: number | null;
          chartTimeScale?: ChartTimeScale | null;
          sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
          accumulatorConfig?: {
            resetsOnPowerOff: boolean;
            rolloverValue: number;
            rolloverThreshold?: number | null;
          } | null;
          discreteConfig?: {
            onStates: Array<number>;
            offStates: Array<number>;
            defaultState: DiscreteDeviceState;
          } | null;
          dataAlarm?: {
            notificationFrequency?: number | null;
            updateBuffer?: number | null;
            repeatNotification?: boolean | null;
            subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
          } | null;
          chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
        };
      }
    | null;
};

export type DeviceUpdateSensorConfigMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  newName?: InputMaybe<Scalars['String']>;
  newDescription?: InputMaybe<Scalars['String']>;
  newSensorConfig?: InputMaybe<SensorConfigInput>;
}>;

export type DeviceUpdateSensorConfigMutation = {
  updateSensor?: {
    _id?: string | null;
    id: string;
    name: string;
    description: string;
    config: {
      type?: SensorType | null;
      stopThreshold?: number | null;
      findStops?: boolean | null;
      energyMeter?: boolean | null;
      stopRegisterThreshold?: number | null;
      stopValueThreshold?: number | null;
      dataMultiplier?: number | null;
      validatedSpeed?: number | null;
      expectedSpeed?: number | null;
      analogOffset?: number | null;
      analogInputRange?: AnalogInputRange | null;
      publishRate?: number | null;
      sampleRate?: number | null;
      triggerIndex?: number | null;
      chartTimeScale?: ChartTimeScale | null;
      sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
      accumulatorConfig?: {
        resetsOnPowerOff: boolean;
        rolloverValue: number;
        rolloverThreshold?: number | null;
      } | null;
      discreteConfig?: { onStates: Array<number>; offStates: Array<number>; defaultState: DiscreteDeviceState } | null;
      dataAlarm?: {
        notificationFrequency?: number | null;
        updateBuffer?: number | null;
        repeatNotification?: boolean | null;
        subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
      } | null;
      chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
    };
  } | null;
};

export type DeviceUpdateStopCauseMappingMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId: Scalars['ID'];
  createStopCauseMapping: Array<StopCauseMappingInput> | StopCauseMappingInput;
  deleteStopCauseMapping: Array<StopCauseMappingInput> | StopCauseMappingInput;
}>;

export type DeviceUpdateStopCauseMappingMutation = { updateStopCauseMapping: boolean };

export type ForgetWiFiMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: Scalars['ID'];
}>;

export type ForgetWiFiMutation = { forgetWiFi: boolean };

export type GroupAddLineMutationVariables = Exact<{
  companyId: Scalars['ID'];
  lineId: Scalars['ID'];
  groupIds: Array<Scalars['ID']> | Scalars['ID'];
  peripheralIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GroupAddLineMutation = { addLineToGroups?: string | null };

export type GroupAddPeripheralMutationVariables = Exact<{
  companyId: Scalars['ID'];
  peripheralId: Scalars['ID'];
  groupIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GroupAddPeripheralMutation = { addPeripheralToGroups?: string | null };

export type GroupCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  attributes: GroupAttributes;
}>;

export type GroupCreateMutation = {
  createGroup?: {
    id: string;
    defaultGroup?: boolean | null;
    name: string;
    owner: { id: string };
    role: { id: string; name: string; type: RoleType };
    peripherals: Array<
      | { _id?: string | null; peripheralId: string; peripheralType: PeripheralType; name: string; description: string }
      | { _id?: string | null; peripheralId: string; peripheralType: PeripheralType; name: string; description: string }
      | null
    >;
    lines: Array<{
      id: string;
      name: string;
      description?: string | null;
      nodes: Array<{ id: string; type: NodeType }>;
    } | null>;
    users: {
      pagination?: Array<{ paginationToken: string; userPool: string }> | null;
      items: Array<{
        sub: string;
        givenName?: string | null;
        familyName?: string | null;
        username: string;
        email?: string | null;
      }>;
    };
  } | null;
};

export type GroupDeleteMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type GroupDeleteMutation = { deleteGroup?: string | null };

export type GroupRemoveLineMutationVariables = Exact<{
  companyId: Scalars['ID'];
  lineId: Scalars['ID'];
  groupIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GroupRemoveLineMutation = { removeLineFromGroups?: string | null };

export type GroupRemovePeripheralMutationVariables = Exact<{
  companyId: Scalars['ID'];
  peripheralId: Scalars['ID'];
  groupIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GroupRemovePeripheralMutation = { removePeripheralFromGroups?: string | null };

export type GroupRemoveUserMutationVariables = Exact<{
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
  groupIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GroupRemoveUserMutation = { removeUserFromGroups?: { sub: string } | null };

export type GroupUpdateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  attributes: GroupAttributes;
}>;

export type GroupUpdateMutation = {
  updateGroup?: {
    id: string;
    defaultGroup?: boolean | null;
    name: string;
    owner: { id: string };
    role: { id: string; name: string; type: RoleType };
    peripherals: Array<
      | { _id?: string | null; peripheralId: string; peripheralType: PeripheralType; name: string; description: string }
      | { _id?: string | null; peripheralId: string; peripheralType: PeripheralType; name: string; description: string }
      | null
    >;
    lines: Array<{
      id: string;
      name: string;
      description?: string | null;
      nodes: Array<{ id: string; type: NodeType }>;
    } | null>;
    users: {
      pagination?: Array<{ paginationToken: string; userPool: string }> | null;
      items: Array<{
        sub: string;
        givenName?: string | null;
        familyName?: string | null;
        username: string;
        email?: string | null;
      }>;
    };
  } | null;
};

export type ImportStopCauseCategoriesMutationVariables = Exact<{
  peripheralId: Scalars['ID'];
  objectKey: Scalars['String'];
}>;

export type ImportStopCauseCategoriesMutation = { importStopCauseCategories?: boolean | null };

export type LineCreateMutationVariables = Exact<{
  groupId: Scalars['ID'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  nodes: Array<LineNodeInput> | LineNodeInput;
  edges: Array<LineEdgeInput> | LineEdgeInput;
}>;

export type LineCreateMutation = {
  createLineWithTopology: {
    id: string;
    name: string;
    description?: string | null;
    nodes: Array<{
      id: string;
      type: NodeType;
      peripheralId: string;
      sensor?: {
        _id?: string | null;
        peripheralId: string;
        name: string;
        description: string;
        id: string;
        config: {
          type?: SensorType | null;
          stopThreshold?: number | null;
          findStops?: boolean | null;
          energyMeter?: boolean | null;
          stopRegisterThreshold?: number | null;
          stopValueThreshold?: number | null;
          dataMultiplier?: number | null;
          validatedSpeed?: number | null;
          expectedSpeed?: number | null;
          analogOffset?: number | null;
          analogInputRange?: AnalogInputRange | null;
          publishRate?: number | null;
          sampleRate?: number | null;
          triggerIndex?: number | null;
          chartTimeScale?: ChartTimeScale | null;
          sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
          accumulatorConfig?: {
            resetsOnPowerOff: boolean;
            rolloverValue: number;
            rolloverThreshold?: number | null;
          } | null;
          discreteConfig?: {
            onStates: Array<number>;
            offStates: Array<number>;
            defaultState: DiscreteDeviceState;
          } | null;
          dataAlarm?: {
            notificationFrequency?: number | null;
            updateBuffer?: number | null;
            repeatNotification?: boolean | null;
            subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
          } | null;
          chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
        };
      } | null;
    }>;
    edges: Array<{ from?: string | null; to: string }>;
  };
};

export type LineDeleteScheduleMutationVariables = Exact<{
  lineId: Scalars['ID'];
  scheduleId: Scalars['ID'];
  isExceptionalWeek?: InputMaybe<Scalars['Boolean']>;
}>;

export type LineDeleteScheduleMutation = { deleteSchedule: boolean };

export type LineDeleteMutationVariables = Exact<{
  lineId: Scalars['ID'];
}>;

export type LineDeleteMutation = { deleteLine: boolean };

export type LineUpdateScheduleMutationVariables = Exact<{
  lineId: Scalars['ID'];
  scheduleId: Scalars['ID'];
  shifts?: InputMaybe<Array<ShiftInput> | ShiftInput>;
  weeklyTargets?: InputMaybe<TargetsInput>;
  configuration?: InputMaybe<ScheduleConfigurationInput>;
}>;

export type LineUpdateScheduleMutation = {
  updateSchedule: {
    id: string;
    lineId: string;
    isExceptionalWeek: boolean;
    isFallbackSchedule: boolean;
    validFrom: { year: number; week: number };
    validTo?: { year: number; week: number } | null;
    shifts: Array<{
      id: string;
      name: string;
      description: string;
      timeRange: {
        from: { day: DayOfWeek; hour: number; minute: number };
        to: { day: DayOfWeek; hour: number; minute: number };
      };
      targets: {
        oee?: number | null;
        oee2?: number | null;
        oee3?: number | null;
        tcu?: number | null;
        produced?: number | null;
        numberOfBatches?: number | null;
      };
    }>;
    weeklyTargets: {
      lineId: string;
      id?: string | null;
      oee?: number | null;
      oee2?: number | null;
      oee3?: number | null;
      tcu?: number | null;
      produced?: number | null;
      numberOfBatches?: number | null;
      mainOeeType?: OeeType | null;
      validFrom?: { year: number; week: number } | null;
    };
    configuration: {
      lineId: string;
      disableLostConnectionAlarmOutsideWorkingHours: boolean;
      timezone: string;
      startDayOfWeek: DayOfWeek;
      automaticStopRegistration: {
        enabled: boolean;
        stopCauseId?: string | null;
        minimumStopMillis?: number | null;
        splitAtEnds?: boolean | null;
        comment?: string | null;
      };
    };
  };
};

export type LineUpdateMutationVariables = Exact<{
  lineId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  nodes: Array<LineNodeInput> | LineNodeInput;
  edges: Array<LineEdgeInput> | LineEdgeInput;
  languageCode: Scalars['String'];
}>;

export type LineUpdateMutation = {
  updateLineWithTopology: {
    id: string;
    name: string;
    description?: string | null;
    nodes: Array<{
      id: string;
      type: NodeType;
      peripheralId: string;
      sensor?: {
        _id?: string | null;
        peripheralId: string;
        name: string;
        description: string;
        id: string;
        config: {
          type?: SensorType | null;
          stopThreshold?: number | null;
          findStops?: boolean | null;
          energyMeter?: boolean | null;
          stopRegisterThreshold?: number | null;
          stopValueThreshold?: number | null;
          dataMultiplier?: number | null;
          validatedSpeed?: number | null;
          expectedSpeed?: number | null;
          analogOffset?: number | null;
          analogInputRange?: AnalogInputRange | null;
          publishRate?: number | null;
          sampleRate?: number | null;
          triggerIndex?: number | null;
          chartTimeScale?: ChartTimeScale | null;
          sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
          accumulatorConfig?: {
            resetsOnPowerOff: boolean;
            rolloverValue: number;
            rolloverThreshold?: number | null;
          } | null;
          discreteConfig?: {
            onStates: Array<number>;
            offStates: Array<number>;
            defaultState: DiscreteDeviceState;
          } | null;
          dataAlarm?: {
            notificationFrequency?: number | null;
            updateBuffer?: number | null;
            repeatNotification?: boolean | null;
            subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
          } | null;
          chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
        };
      } | null;
    }>;
    edges: Array<{ from?: string | null; to: string }>;
  };
};

export type LineUpsertConfigurationMutationVariables = Exact<{
  lineId: Scalars['ID'];
  configuration: ScheduleConfigurationInput;
}>;

export type LineUpsertConfigurationMutation = {
  upsertConfiguration: {
    lineId: string;
    disableLostConnectionAlarmOutsideWorkingHours: boolean;
    timezone: string;
    startDayOfWeek: DayOfWeek;
    automaticStopRegistration: {
      enabled: boolean;
      stopCauseId?: string | null;
      minimumStopMillis?: number | null;
      splitAtEnds?: boolean | null;
      comment?: string | null;
    };
  };
};

export type LineUpsertScheduleMutationVariables = Exact<{
  lineId: Scalars['ID'];
  validFrom: ScheduleTimeInput;
  shifts: Array<ShiftInput> | ShiftInput;
  isExceptionalWeek?: InputMaybe<Scalars['Boolean']>;
}>;

export type LineUpsertScheduleMutation = {
  upsertSchedule: {
    id: string;
    lineId: string;
    isExceptionalWeek: boolean;
    isFallbackSchedule: boolean;
    validFrom: { year: number; week: number };
    validTo?: { year: number; week: number } | null;
    shifts: Array<{
      id: string;
      name: string;
      description: string;
      timeRange: {
        from: { day: DayOfWeek; hour: number; minute: number };
        to: { day: DayOfWeek; hour: number; minute: number };
      };
      targets: {
        oee?: number | null;
        oee2?: number | null;
        oee3?: number | null;
        tcu?: number | null;
        produced?: number | null;
        numberOfBatches?: number | null;
      };
    }>;
    weeklyTargets: {
      lineId: string;
      id?: string | null;
      oee?: number | null;
      oee2?: number | null;
      oee3?: number | null;
      tcu?: number | null;
      produced?: number | null;
      numberOfBatches?: number | null;
      mainOeeType?: OeeType | null;
      validFrom?: { year: number; week: number } | null;
    };
    configuration: {
      lineId: string;
      disableLostConnectionAlarmOutsideWorkingHours: boolean;
      timezone: string;
      startDayOfWeek: DayOfWeek;
      automaticStopRegistration: {
        enabled: boolean;
        stopCauseId?: string | null;
        minimumStopMillis?: number | null;
        splitAtEnds?: boolean | null;
        comment?: string | null;
      };
    };
  };
};

export type LineUpsertTargetMutationVariables = Exact<{
  lineId: Scalars['ID'];
  validFrom: ScheduleTimeInput;
  weeklyTargets: TargetsInput;
}>;

export type LineUpsertTargetMutation = {
  upsertTarget: {
    lineId: string;
    id?: string | null;
    oee?: number | null;
    oee2?: number | null;
    oee3?: number | null;
    tcu?: number | null;
    produced?: number | null;
    numberOfBatches?: number | null;
    mainOeeType?: OeeType | null;
    validFrom?: { year: number; week: number } | null;
  };
};

export type CreateMaintenanceLogEntryMutationVariables = Exact<{
  input: CreateMaintenanceLogEntryInput;
}>;

export type CreateMaintenanceLogEntryMutation = { createMaintenanceLogEntry: { planId: any } };

export type CreateMaintenancePlanMutationVariables = Exact<{
  input: CreateMaintenancePlanInput;
}>;

export type CreateMaintenancePlanMutation = {
  createMaintenancePlan: {
    planId: any;
    peripheralId: string;
    asset: string;
    component: string;
    tagPartNumber?: string | null;
    instructions?: string | null;
    startFrom: string;
    trackBy: { time?: { days: number; hours: number } | null; production?: { produced: number } | null };
  };
};

export type DeleteMaintenancePlanMutationVariables = Exact<{
  planId: Scalars['MaintenancePlanId'];
  peripheralId: Scalars['PeripheralId'];
}>;

export type DeleteMaintenancePlanMutation = { deleteMaintenancePlan: { planId: any } };

export type UpdateMaintenancePlanMutationVariables = Exact<{
  planId: Scalars['MaintenancePlanId'];
  peripheralId: Scalars['PeripheralId'];
  version: Scalars['Int'];
  toUpdate: UpdateMaintenancePlanInput;
}>;

export type UpdateMaintenancePlanMutation = {
  updateMaintenancePlan: {
    planId: any;
    peripheralId: string;
    asset: string;
    component: string;
    tagPartNumber?: string | null;
    instructions?: string | null;
    startFrom: string;
    version: number;
    trackBy: { time?: { days: number; hours: number } | null; production?: { produced: number } | null };
  };
};

export type ManuallyTriggerBatchControlMutationVariables = Exact<{
  lineId: Scalars['ID'];
  batchId: Scalars['ID'];
  controlId: Scalars['ID'];
  controlReceiptId: Scalars['ID'];
}>;

export type ManuallyTriggerBatchControlMutation = {
  manuallyTriggerBatchControl: {
    batchControlId: string;
    controlReceiptId: string;
    controlReceiptName: string;
    entryId: string;
    title?: string | null;
    batchId: string;
    status: BatchControlStatus;
    timeTriggered: string;
    timeControlled?: string | null;
    timeControlUpdated?: string | null;
    comment?: string | null;
    initials?: string | null;
    fieldValues: Array<{
      value?: string | null;
      controlReceiptField: {
        fieldId: string;
        label: string;
        description?: string | null;
        type: ControlFieldType;
        sensorId?: string | null;
        limits?: { lower?: number | null; upper?: number | null } | null;
        derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
      };
    }>;
    trigger: { delayWhenStopped: boolean; type: ControlTriggerType; payload?: { millis: number } | null };
    followUp: { enabled: boolean; delayMs?: number | null };
    originalControl?: { controlId: string; timeTriggered: string } | null;
    history: Array<{
      status: BatchControlStatus;
      timeControlled?: string | null;
      timeControlUpdated?: string | null;
      comment?: string | null;
      initials?: string | null;
      fieldValues: Array<{
        value?: string | null;
        controlReceiptField: {
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        };
      }>;
    }>;
  };
};

export type ReportsCreateForGroupMutationVariables = Exact<{
  entityId: Scalars['ID'];
  type: ReportType;
  name: Scalars['String'];
  description: Scalars['String'];
  trigger: TriggerType;
  triggerParameters: Scalars['String'];
  subscribers: Array<ReportSubscriberInput> | ReportSubscriberInput;
  enabled: Scalars['Boolean'];
  timezone: Scalars['String'];
}>;

export type ReportsCreateForGroupMutation = {
  createScheduledReportForGroup: {
    id: string;
    enabled: boolean;
    type: ReportType;
    entityId: string;
    entityType: EntityType;
    name: string;
    description?: string | null;
    timezone: string;
    subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
    trigger: { type: TriggerType; parameters: string };
  };
};

export type ReportsCreateForLineMutationVariables = Exact<{
  entityId: Scalars['ID'];
  type: ReportType;
  name: Scalars['String'];
  description: Scalars['String'];
  trigger: TriggerType;
  triggerParameters: Scalars['String'];
  subscribers: Array<ReportSubscriberInput> | ReportSubscriberInput;
  enabled: Scalars['Boolean'];
  timezone: Scalars['String'];
}>;

export type ReportsCreateForLineMutation = {
  createScheduledReportForLine: {
    id: string;
    enabled: boolean;
    type: ReportType;
    entityId: string;
    entityType: EntityType;
    name: string;
    description?: string | null;
    timezone: string;
    subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
    trigger: { type: TriggerType; parameters: string };
  };
};

export type ReportsCreateForSensorMutationVariables = Exact<{
  entityId: Scalars['ID'];
  type: ReportType;
  name: Scalars['String'];
  description: Scalars['String'];
  trigger: TriggerType;
  triggerParameters: Scalars['String'];
  subscribers: Array<ReportSubscriberInput> | ReportSubscriberInput;
  enabled: Scalars['Boolean'];
  timezone: Scalars['String'];
}>;

export type ReportsCreateForSensorMutation = {
  createScheduledReportForSensor: {
    id: string;
    enabled: boolean;
    type: ReportType;
    entityId: string;
    entityType: EntityType;
    name: string;
    description?: string | null;
    timezone: string;
    subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
    trigger: { type: TriggerType; parameters: string };
  };
};

export type ReportsDeleteForGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  entityId: Scalars['ID'];
}>;

export type ReportsDeleteForGroupMutation = { deleteScheduledReportForGroup: number };

export type ReportsDeleteForLineMutationVariables = Exact<{
  id: Scalars['ID'];
  entityId: Scalars['ID'];
}>;

export type ReportsDeleteForLineMutation = { deleteScheduledReportForLine: number };

export type ReportsDeleteForSensorMutationVariables = Exact<{
  id: Scalars['ID'];
  entityId: Scalars['ID'];
}>;

export type ReportsDeleteForSensorMutation = { deleteScheduledReportForSensor: number };

export type ReportsUpdateForGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  entityId: Scalars['ID'];
  type?: InputMaybe<ReportType>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<TriggerType>;
  triggerParameters?: InputMaybe<Scalars['String']>;
  subscribers?: InputMaybe<Array<ReportSubscriberInput> | ReportSubscriberInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
}>;

export type ReportsUpdateForGroupMutation = {
  updateScheduledReportForGroup: {
    id: string;
    enabled: boolean;
    type: ReportType;
    entityId: string;
    entityType: EntityType;
    name: string;
    description?: string | null;
    timezone: string;
    subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
    trigger: { type: TriggerType; parameters: string };
  };
};

export type ReportsUpdateForLineMutationVariables = Exact<{
  id: Scalars['ID'];
  entityId: Scalars['ID'];
  type?: InputMaybe<ReportType>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<TriggerType>;
  triggerParameters?: InputMaybe<Scalars['String']>;
  subscribers?: InputMaybe<Array<ReportSubscriberInput> | ReportSubscriberInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
}>;

export type ReportsUpdateForLineMutation = {
  updateScheduledReportForLine: {
    id: string;
    enabled: boolean;
    type: ReportType;
    entityId: string;
    entityType: EntityType;
    name: string;
    description?: string | null;
    timezone: string;
    subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
    trigger: { type: TriggerType; parameters: string };
  };
};

export type ReportsUpdateForSensorMutationVariables = Exact<{
  id: Scalars['ID'];
  entityId: Scalars['ID'];
  type?: InputMaybe<ReportType>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<TriggerType>;
  triggerParameters?: InputMaybe<Scalars['String']>;
  subscribers?: InputMaybe<Array<ReportSubscriberInput> | ReportSubscriberInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
}>;

export type ReportsUpdateForSensorMutation = {
  updateScheduledReportForSensor: {
    id: string;
    enabled: boolean;
    type: ReportType;
    entityId: string;
    entityType: EntityType;
    name: string;
    description?: string | null;
    timezone: string;
    subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
    trigger: { type: TriggerType; parameters: string };
  };
};

export type RoleCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  attributes: RoleAttributes;
}>;

export type RoleCreateMutation = {
  createRole?: {
    id: string;
    name: string;
    type: RoleType;
    permissions: Array<{ key: string; type: RequestType }>;
  } | null;
};

export type RoleDeleteMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type RoleDeleteMutation = { deleteRole?: string | null };

export type RoleUpdateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  attributes: RoleAttributes;
}>;

export type RoleUpdateMutation = {
  updateRole?: {
    id: string;
    name: string;
    type: RoleType;
    permissions: Array<{ key: string; type: RequestType }>;
  } | null;
};

export type StopCauseCategoryCreateMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
}>;

export type StopCauseCategoryCreateMutation = {
  createStopCauseCategory?: Array<{
    _id: string;
    id: string;
    name: string;
    ownerType: OwnerType;
    stopCauses: Array<{
      _id: string;
      id: string;
      name: string;
      description: string;
      categoryName?: string | null;
      languageCode: string;
      stopType: StopType;
      requireInitials: boolean;
      requireComment: boolean;
      enableCountermeasure: boolean;
      meta: Array<{ name: string; description: string; languageCode: string }>;
      targetSetup?: {
        constantTime: number;
        parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
      } | null;
    } | null>;
    meta: Array<{ name: string; languageCode: string }>;
  }> | null;
};

export type StopCauseCategoryDeleteMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseCategoryId: Scalars['ID'];
}>;

export type StopCauseCategoryDeleteMutation = {
  deleteStopCauseCategory?: Array<{
    _id: string;
    id: string;
    name: string;
    ownerType: OwnerType;
    stopCauses: Array<{
      _id: string;
      id: string;
      name: string;
      description: string;
      categoryName?: string | null;
      languageCode: string;
      stopType: StopType;
      requireInitials: boolean;
      requireComment: boolean;
      enableCountermeasure: boolean;
      meta: Array<{ name: string; description: string; languageCode: string }>;
      targetSetup?: {
        constantTime: number;
        parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
      } | null;
    } | null>;
    meta: Array<{ name: string; languageCode: string }>;
  }> | null;
};

export type StopCauseCategorySortMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseCategoryIdsInOrder: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type StopCauseCategorySortMutation = {
  setStopCauseCategoryOrder?: Array<{
    _id: string;
    id: string;
    name: string;
    ownerType: OwnerType;
    stopCauses: Array<{
      _id: string;
      id: string;
      name: string;
      description: string;
      categoryName?: string | null;
      languageCode: string;
      stopType: StopType;
      requireInitials: boolean;
      requireComment: boolean;
      enableCountermeasure: boolean;
      meta: Array<{ name: string; description: string; languageCode: string }>;
      targetSetup?: {
        constantTime: number;
        parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
      } | null;
    } | null>;
    meta: Array<{ name: string; languageCode: string }>;
  }> | null;
};

export type StopCauseCategoryUpdateMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseCategoryId: Scalars['ID'];
  newName: Scalars['String'];
}>;

export type StopCauseCategoryUpdateMutation = {
  modifyStopCauseCategory?: Array<{
    _id: string;
    id: string;
    name: string;
    ownerType: OwnerType;
    stopCauses: Array<{
      _id: string;
      id: string;
      name: string;
      description: string;
      categoryName?: string | null;
      languageCode: string;
      stopType: StopType;
      requireInitials: boolean;
      requireComment: boolean;
      enableCountermeasure: boolean;
      meta: Array<{ name: string; description: string; languageCode: string }>;
      targetSetup?: {
        constantTime: number;
        parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
      } | null;
    } | null>;
    meta: Array<{ name: string; languageCode: string }>;
  }> | null;
};

export type StopCauseCreateMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId?: InputMaybe<Scalars['ID']>;
  meta: Array<StopCauseMetaInput> | StopCauseMetaInput;
  stopType: StopType;
  requireInitials?: InputMaybe<Scalars['Boolean']>;
  requireComment?: InputMaybe<Scalars['Boolean']>;
  enableCountermeasure?: InputMaybe<Scalars['Boolean']>;
  stopCauseCategoryId: Scalars['ID'];
  targetSetup?: InputMaybe<TargetSetupInput>;
}>;

export type StopCauseCreateMutation = {
  createStopCause?: Array<{
    _id: string;
    id: string;
    name: string;
    ownerType: OwnerType;
    stopCauses: Array<{
      _id: string;
      id: string;
      name: string;
      description: string;
      categoryName?: string | null;
      languageCode: string;
      stopType: StopType;
      requireInitials: boolean;
      requireComment: boolean;
      enableCountermeasure: boolean;
      meta: Array<{ name: string; description: string; languageCode: string }>;
      targetSetup?: {
        constantTime: number;
        parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
      } | null;
    } | null>;
    meta: Array<{ name: string; languageCode: string }>;
  }> | null;
};

export type StopCauseDeleteMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseId: Scalars['ID'];
}>;

export type StopCauseDeleteMutation = {
  deleteStopCause?: Array<{
    _id: string;
    id: string;
    name: string;
    ownerType: OwnerType;
    stopCauses: Array<{
      _id: string;
      id: string;
      name: string;
      description: string;
      categoryName?: string | null;
      languageCode: string;
      stopType: StopType;
      requireInitials: boolean;
      requireComment: boolean;
      enableCountermeasure: boolean;
      meta: Array<{ name: string; description: string; languageCode: string }>;
      targetSetup?: {
        constantTime: number;
        parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
      } | null;
    } | null>;
    meta: Array<{ name: string; languageCode: string }>;
  } | null> | null;
};

export type StopCauseSortMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseCategoryId: Scalars['ID'];
  stopCauseIdsInOrder: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type StopCauseSortMutation = {
  setStopCauseOrder?: Array<{
    _id: string;
    id: string;
    name: string;
    ownerType: OwnerType;
    stopCauses: Array<{
      _id: string;
      id: string;
      name: string;
      description: string;
      categoryName?: string | null;
      languageCode: string;
      stopType: StopType;
      requireInitials: boolean;
      requireComment: boolean;
      enableCountermeasure: boolean;
      meta: Array<{ name: string; description: string; languageCode: string }>;
      targetSetup?: {
        constantTime: number;
        parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
      } | null;
    } | null>;
    meta: Array<{ name: string; languageCode: string }>;
  }> | null;
};

export type StopCauseUpdateMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId?: InputMaybe<Scalars['ID']>;
  stopCauseId: Scalars['ID'];
  meta?: InputMaybe<Array<StopCauseMetaInput> | StopCauseMetaInput>;
  stopType?: InputMaybe<StopType>;
  requireInitials?: InputMaybe<Scalars['Boolean']>;
  requireComment?: InputMaybe<Scalars['Boolean']>;
  enableCountermeasure?: InputMaybe<Scalars['Boolean']>;
  stopCauseCategory?: InputMaybe<NewStopCauseCategory>;
  targetSetup?: InputMaybe<TargetSetupInput>;
}>;

export type StopCauseUpdateMutation = {
  modifyStopCause?: Array<{
    _id: string;
    id: string;
    name: string;
    ownerType: OwnerType;
    stopCauses: Array<{
      _id: string;
      id: string;
      name: string;
      description: string;
      categoryName?: string | null;
      languageCode: string;
      stopType: StopType;
      requireInitials: boolean;
      requireComment: boolean;
      enableCountermeasure: boolean;
      meta: Array<{ name: string; description: string; languageCode: string }>;
      targetSetup?: {
        constantTime: number;
        parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
      } | null;
    } | null>;
    meta: Array<{ name: string; languageCode: string }>;
  }> | null;
};

export type StopRegisterStopMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId: Scalars['ID'];
  time: Array<Time> | Time;
  stopCauseId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  countermeasure?: InputMaybe<Scalars['String']>;
  countermeasureInitials?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<TargetInput>;
  standalone?: InputMaybe<StandaloneConfigurationInput>;
}>;

export type StopRegisterStopMutation = {
  registerStop: Array<{
    __typename: 'Stop';
    _id: string;
    duration: number;
    ongoing: boolean;
    isMicroStop: boolean;
    comment?: string | null;
    initials?: string | null;
    registeredTime?: string | null;
    countermeasure?: string | null;
    countermeasureInitials?: string | null;
    standalone?: { excludeProduction?: boolean | null } | null;
    target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
    timeRange: { to?: string | null; from?: string | null };
    stopCause?: {
      _id: string;
      id: string;
      name: string;
      description: string;
      categoryName?: string | null;
      languageCode: string;
      stopType: StopType;
      requireInitials: boolean;
      requireComment: boolean;
      enableCountermeasure: boolean;
      meta: Array<{ name: string; description: string; languageCode: string }>;
      targetSetup?: {
        constantTime: number;
        parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
      } | null;
    } | null;
  }>;
};

export type StopRegistrationDeleteMutationVariables = Exact<{
  deviceOwner: Scalars['ID'];
  peripheralId: Scalars['ID'];
  time: Array<Time> | Time;
}>;

export type StopRegistrationDeleteMutation = {
  registerStop: Array<{
    __typename: 'Stop';
    _id: string;
    duration: number;
    ongoing: boolean;
    isMicroStop: boolean;
    comment?: string | null;
    initials?: string | null;
    registeredTime?: string | null;
    countermeasure?: string | null;
    countermeasureInitials?: string | null;
    standalone?: { excludeProduction?: boolean | null } | null;
    target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
    timeRange: { to?: string | null; from?: string | null };
    stopCause?: {
      _id: string;
      id: string;
      name: string;
      description: string;
      categoryName?: string | null;
      languageCode: string;
      stopType: StopType;
      requireInitials: boolean;
      requireComment: boolean;
      enableCountermeasure: boolean;
      meta: Array<{ name: string; description: string; languageCode: string }>;
      targetSetup?: {
        constantTime: number;
        parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
      } | null;
    } | null;
  }>;
};

export type TestCreateMutationVariables = Exact<{
  lineId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type TestCreateMutation = {
  createTest: { id: string; name: string; description: string; time: { from?: string | null; to?: string | null } };
};

export type TestDeleteMutationVariables = Exact<{
  lineId: Scalars['ID'];
  testId: Scalars['ID'];
  originalFromDate: Scalars['Date'];
}>;

export type TestDeleteMutation = { deleteTest: boolean };

export type TestUpdateMutationVariables = Exact<{
  lineId: Scalars['ID'];
  testId: Scalars['ID'];
  originalFromDate: Scalars['Date'];
  name?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
  languageCode: Scalars['String'];
}>;

export type TestUpdateMutation = {
  updateTest: { id: string; name: string; description: string; time: { from?: string | null; to?: string | null } };
};

export type UpsertWiFiMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: NetworkInput;
}>;

export type UpsertWiFiMutation = { upsertWiFi: boolean };

export type UserCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  username: Scalars['String'];
  attributes: UserAttributes;
  groupIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type UserCreateMutation = {
  createUser?: {
    sub: string;
    enabled: boolean;
    email?: string | null;
    username: string;
    givenName?: string | null;
    familyName?: string | null;
    userStatus: string;
    userCreateDate: string;
    userLastModifiedDate: string;
    emailVerified?: string | null;
    company: { id: string; name?: string | null };
    groups: Array<{ id: string; name: string; role: { id: string; type: RoleType; name: string } }>;
    lines: Array<{ id: string; name: string; description?: string | null } | null>;
    devices: Array<{
      uuid: string;
      type: string;
      sensors: Array<{ _id?: string | null; index: string; name: string; description: string }>;
    } | null>;
  } | null;
};

export type UserDeleteMutationVariables = Exact<{
  companyId: Scalars['ID'];
  username: Scalars['String'];
}>;

export type UserDeleteMutation = { deleteUser?: { sub: string } | null };

export type UserDisableMutationVariables = Exact<{
  companyId: Scalars['ID'];
  username: Scalars['String'];
}>;

export type UserDisableMutation = { disableUser: { sub: string } };

export type UserEnableMutationVariables = Exact<{
  companyId: Scalars['ID'];
  username: Scalars['String'];
}>;

export type UserEnableMutation = { enableUser: { sub: string } };

export type UserResetPasswordMutationVariables = Exact<{
  companyId: Scalars['ID'];
  username: Scalars['String'];
}>;

export type UserResetPasswordMutation = { resetUserPassword?: { sub: string } | null };

export type UserUpdateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  username: Scalars['String'];
  attributes: UserAttributes;
  groupIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  removedGroupIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type UserUpdateMutation = {
  updateUser?: {
    sub: string;
    enabled: boolean;
    email?: string | null;
    username: string;
    givenName?: string | null;
    familyName?: string | null;
    userStatus: string;
    userCreateDate: string;
    userLastModifiedDate: string;
    emailVerified?: string | null;
    company: { id: string; name?: string | null };
    groups: Array<{ id: string; name: string; role: { id: string; type: RoleType; name: string } }>;
    lines: Array<{ id: string; name: string; description?: string | null } | null>;
    devices: Array<{
      uuid: string;
      type: string;
      sensors: Array<{ _id?: string | null; index: string; name: string; description: string }>;
    } | null>;
  } | null;
};

export type AdministrationDetailedGroupQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;

export type AdministrationDetailedGroupQuery = {
  company: {
    id: string;
    group: {
      id: string;
      defaultGroup?: boolean | null;
      name: string;
      owner: { id: string };
      role: { id: string; name: string; type: RoleType };
      peripherals: Array<
        | {
            _id?: string | null;
            peripheralId: string;
            peripheralType: PeripheralType;
            name: string;
            description: string;
          }
        | {
            _id?: string | null;
            peripheralId: string;
            peripheralType: PeripheralType;
            name: string;
            description: string;
          }
        | null
      >;
      lines: Array<{
        id: string;
        name: string;
        description?: string | null;
        nodes: Array<{ id: string; type: NodeType }>;
      } | null>;
      users: {
        pagination?: Array<{ paginationToken: string; userPool: string }> | null;
        items: Array<{
          sub: string;
          givenName?: string | null;
          familyName?: string | null;
          username: string;
          email?: string | null;
        }>;
      };
    };
  };
};

export type AdministrationDetailedGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type AdministrationDetailedGroupsQuery = {
  user: { sub: string; company: { id: string; name?: string | null } };
  company: {
    id: string;
    groups: Array<{
      id: string;
      defaultGroup?: boolean | null;
      name: string;
      owner: { id: string };
      role: { id: string; name: string; type: RoleType };
      peripherals: Array<
        | {
            _id?: string | null;
            peripheralId: string;
            peripheralType: PeripheralType;
            name: string;
            description: string;
          }
        | {
            _id?: string | null;
            peripheralId: string;
            peripheralType: PeripheralType;
            name: string;
            description: string;
          }
        | null
      >;
      lines: Array<{
        id: string;
        name: string;
        description?: string | null;
        nodes: Array<{ id: string; type: NodeType }>;
      } | null>;
      users: { items: Array<{ sub: string }> };
    }>;
    roles: Array<{
      id: string;
      name: string;
      type: RoleType;
      permissions: Array<{ key: string; type: RequestType; description?: string | null }>;
    }>;
  };
  devices: Array<{
    _id?: string | null;
    peripherals: Array<
      | { _id?: string | null; peripheralId: string; peripheralType: PeripheralType; name: string; description: string }
      | { _id?: string | null; peripheralId: string; peripheralType: PeripheralType; name: string; description: string }
      | null
    >;
  } | null>;
  lines: Array<{ id: string; name: string; description?: string | null; nodes: Array<{ id: string; type: NodeType }> }>;
};

export type AdministrationDevicePeripheralsQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type AdministrationDevicePeripheralsQuery = {
  device?: {
    _id?: string | null;
    uuid: string;
    hardwareId: string;
    type: string;
    name?: string | null;
    owner?: string | null;
    status: {
      hardwareVersion?: string | null;
      firmwareVersions?: {
        application?: string | null;
        bootloader?: string | null;
        wifi?: string | null;
        cellular?: string | null;
      } | null;
    };
    network?: {
      wifi?: {
        desired?: {
          enabled?: boolean | null;
          networks?: Array<{ id: string; ssid: string; password?: string | null }> | null;
        } | null;
        reported?: {
          enabled?: boolean | null;
          networks?: Array<{ id: string; ssid: string; password?: string | null }> | null;
        } | null;
      } | null;
    } | null;
    peripherals: Array<
      | {
          _id?: string | null;
          name: string;
          index: string;
          peripheralId: string;
          peripheralType: PeripheralType;
          description: string;
          offlineStatus: { expiration?: number | null; lastReceived: number };
        }
      | {
          _id?: string | null;
          name: string;
          index: string;
          peripheralId: string;
          peripheralType: PeripheralType;
          description: string;
          offlineStatus: { expiration?: number | null; lastReceived: number };
        }
      | null
    >;
  } | null;
};

export type AdministrationDevicesQueryVariables = Exact<{ [key: string]: never }>;

export type AdministrationDevicesQuery = {
  devices: Array<{
    _id?: string | null;
    hardwareId: string;
    uuid: string;
    type: string;
    name?: string | null;
    owner?: string | null;
    peripherals: Array<
      | {
          _id?: string | null;
          peripheralId: string;
          index: string;
          name: string;
          description: string;
          offlineStatus: { expiration?: number | null; lastReceived: number };
        }
      | {
          _id?: string | null;
          peripheralId: string;
          index: string;
          name: string;
          description: string;
          offlineStatus: { expiration?: number | null; lastReceived: number };
        }
      | null
    >;
  } | null>;
};

export type AdministrationGroupsDevicesQueryVariables = Exact<{
  time: Array<Time> | Time;
}>;

export type AdministrationGroupsDevicesQuery = {
  company: {
    id: string;
    groups: Array<{
      id: string;
      defaultGroup?: boolean | null;
      name: string;
      role: { id: string; name: string; type: RoleType };
      sensors: Array<{ _id?: string | null; peripheralId: string } | null>;
    }>;
  };
  devices: Array<{
    hardwareId: string;
    peripherals: Array<
      | { _id?: string | null; peripheralId: string; name: string; description: string }
      | {
          _id?: string | null;
          peripheralId: string;
          name: string;
          description: string;
          peripheralInformation?: { line: { id: string; name: string }; node: { type: NodeType } } | null;
          config: {
            type?: SensorType | null;
            stopThreshold?: number | null;
            findStops?: boolean | null;
            energyMeter?: boolean | null;
            stopRegisterThreshold?: number | null;
            stopValueThreshold?: number | null;
            dataMultiplier?: number | null;
            validatedSpeed?: number | null;
            expectedSpeed?: number | null;
            analogOffset?: number | null;
            analogInputRange?: AnalogInputRange | null;
            publishRate?: number | null;
            sampleRate?: number | null;
            triggerIndex?: number | null;
            chartTimeScale?: ChartTimeScale | null;
            sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
            accumulatorConfig?: {
              resetsOnPowerOff: boolean;
              rolloverValue: number;
              rolloverThreshold?: number | null;
            } | null;
            discreteConfig?: {
              onStates: Array<number>;
              offStates: Array<number>;
              defaultState: DiscreteDeviceState;
            } | null;
            dataAlarm?: {
              notificationFrequency?: number | null;
              updateBuffer?: number | null;
              repeatNotification?: boolean | null;
              subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
            } | null;
            chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
          };
          time?: Array<{
            stats: {
              data: { lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null };
            };
          } | null> | null;
        }
      | null
    >;
  } | null>;
  units?: { units?: Array<{ name: string; description?: string | null }> | null } | null;
  analogInputRanges?: { analogInputRanges?: Array<{ name: string; description?: string | null }> | null } | null;
  sensorTypes?: { sensorTypes?: Array<{ name: string; description?: string | null }> | null } | null;
};

export type AdministrationGroupsLinesQueryVariables = Exact<{ [key: string]: never }>;

export type AdministrationGroupsLinesQuery = {
  company: {
    id: string;
    groups: Array<{
      id: string;
      defaultGroup?: boolean | null;
      name: string;
      role: { id: string; name: string; type: RoleType };
      lines: Array<{ id: string; name: string } | null>;
    }>;
  };
  lines: Array<{
    id: string;
    name: string;
    description?: string | null;
    edges: Array<{ from?: string | null; to: string }>;
    nodes: Array<{
      id: string;
      type: NodeType;
      sensor?: { _id?: string | null; name: string; description: string } | null;
    }>;
  }>;
};

export type AdministrationGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type AdministrationGroupsQuery = {
  user: { sub: string; company: { id: string; name?: string | null } };
  company: {
    id: string;
    groups: Array<{
      id: string;
      defaultGroup?: boolean | null;
      name: string;
      role: { id: string; name: string; type: RoleType };
    }>;
  };
};

export type AdministrationRolesQueryVariables = Exact<{ [key: string]: never }>;

export type AdministrationRolesQuery = {
  user: { sub: string; company: { id: string; name?: string | null } };
  permissionKeys: Array<{ key: string; type: RequestType; description?: string | null }>;
  company: {
    id: string;
    roles: Array<{ id: string; name: string; type: RoleType; permissions: Array<{ key: string; type: RequestType }> }>;
  };
};

export type AdministrationUserQueryVariables = Exact<{
  userSub: Scalars['ID'];
}>;

export type AdministrationUserQuery = {
  user: {
    groups: Array<{ id: string; name: string; role: { id: string; type: RoleType; name: string } }>;
    lines: Array<{ id: string; name: string; description?: string | null } | null>;
    devices: Array<{
      uuid: string;
      type: string;
      sensors: Array<{ _id?: string | null; index: string; name: string; description: string }>;
    } | null>;
  };
};

export type AdministrationUsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<TokenInput>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AdministrationUsersQuery = {
  user: { sub: string; company: { id: string; name?: string | null } };
  company: {
    id: string;
    users: {
      pagination?: Array<{ paginationToken: string; userPool: string }> | null;
      items: Array<{
        sub: string;
        enabled: boolean;
        email?: string | null;
        username: string;
        givenName?: string | null;
        familyName?: string | null;
        userStatus: string;
        userCreateDate: string;
        userLastModifiedDate: string;
        company: { id: string; name?: string | null };
        groups: Array<{ id: string }>;
      }>;
    };
  };
};

export type AlarmsWithLogsQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  exclusiveStartKey?: InputMaybe<AlarmLogExclusiveStartKey>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AlarmsWithLogsQuery = {
  device?: {
    _id?: string | null;
    uuid: string;
    sensor?: {
      _id?: string | null;
      id: string;
      config: { type?: SensorType | null };
      alarms: Array<{
        id: string;
        name: string;
        description: string;
        status: AlarmStatus;
        threshold: number;
        repeatNotification: boolean;
        enabled: boolean;
        type: AlarmType;
        languageCode: string;
        snoozeDuration?: number | null;
        alarmConfiguration: {
          x?: number | null;
          t?: number | null;
          y?: number | null;
          n?: number | null;
          stopType?: StopTypeForAlarms | null;
        };
        subscribers: Array<{ type: SubscriberType; languageCode: string; value: string }>;
        alarmLogs: {
          lastEvaluatedKey?: { alarmId: string; start: string } | null;
          entries: Array<{
            status: AlarmStatus;
            createdAt?: string | null;
            timeRange: { from?: string | null; to?: string | null };
            config?: { t?: number | null; stopType?: StopTypeForAlarms | null } | null;
          } | null>;
        };
      } | null>;
    } | null;
  } | null;
};

export type AndonAttendeesForCurrentShiftQueryVariables = Exact<{
  lineId: Scalars['ID'];
  input: AndonShiftInput;
}>;

export type AndonAttendeesForCurrentShiftQuery = {
  line: {
    id: string;
    andonSchedules: Array<{
      shifts: Array<{ id: string; attendees: Array<{ id: string; role: { id: string; name: string } }> }>;
    }>;
  };
};

export type AndonListCallsQueryVariables = Exact<{
  time?: InputMaybe<Scalars['Date']>;
}>;

export type AndonListCallsQuery = {
  andon: Array<{
    companyId: string;
    extensions?: Array<{ type: ExtensionType; service: string }> | null;
    calls: Array<{
      id: string;
      requestedSupport?: string | null;
      urgency: Urgency;
      line?: { id: string; name: string; description?: string | null } | null;
      role?: { name: string } | null;
      action: {
        make: { author: string; details: string; time: string };
        take?: { author: string; details: string; time: string } | null;
        resolve?: { author: string; details: string; time: string } | null;
        pending?: { author: string; details: string; time: string } | null;
      };
      tags?: Array<{ id: string; value: string } | null> | null;
    }>;
  }>;
};

export type AndonListEventsQueryVariables = Exact<{
  companyId?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type AndonListEventsQuery = {
  andon: Array<{
    companyId: string;
    supportTypes: Array<{ id: string; name: string }>;
    schedule?: {
      events: Array<{
        id: string;
        rRuleSet: string;
        lineId: string;
        summary: string;
        description?: string | null;
        duration: number;
        attendees: Array<{
          id: string;
          email?: string | null;
          phoneNumber?: string | null;
          dateExceptions: Array<string>;
          supportId: string;
          messages: Array<{ id: string; type: SubscriptionType; delay: number; takenDelay?: number | null }>;
        }>;
      }>;
    } | null;
  }>;
};

export type AndonListRolesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type AndonListRolesQuery = {
  andon: Array<{
    companyId: string;
    roles: Array<{
      id: string;
      name: string;
      escalationConfiguration?: Array<{
        delay?: number | null;
        takenDelay?: number | null;
        type: SubscriptionType;
      }> | null;
    }>;
  }>;
};

export type AndonListSchedulesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type AndonListSchedulesQuery = {
  andon: Array<{
    companyId: string;
    schedules: Array<{ id: string; name: string; lines: Array<{ id: string; name: string }> }>;
  }>;
};

export type AndonListSettingsQueryVariables = Exact<{
  companyId?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type AndonListSettingsQuery = {
  andon: Array<{
    companyId: string;
    extensions?: Array<{ type: ExtensionType; service: string }> | null;
    supportTypes: Array<{ id: string; name: string }>;
  }>;
};

export type AndonListUnresolvedCallsForLineQueryVariables = Exact<{
  lineId: Scalars['ID'];
}>;

export type AndonListUnresolvedCallsForLineQuery = {
  andon: Array<{
    companyId: string;
    supportTypes: Array<{ id: string; name: string }>;
    extensions?: Array<{ type: ExtensionType; service: string }> | null;
    tags: Array<{ id: string; value: string }>;
    calls: Array<{
      id: string;
      urgency: Urgency;
      action: { make: { time: string; details: string }; take?: { time: string } | null };
    }>;
  }>;
};

export type AndonListWorkOrderKeysQueryVariables = Exact<{
  companyId?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  lineId: Scalars['ID'];
  tagIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type AndonListWorkOrderKeysQuery = {
  andon: Array<{
    companyId: string;
    tags: Array<{ id: string; value: string }>;
    workOrderKeys?: Array<{ key: string; tagId: string }> | null;
  }>;
};

export type AndonListWorkersQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type AndonListWorkersQuery = {
  andon: Array<{
    companyId: string;
    workers: Array<{
      id: string;
      name: string;
      email?: string | null;
      phoneNumber?: string | null;
      preferredSchedules: Array<{ id: string; name: string }>;
      role: {
        id: string;
        name: string;
        escalationConfiguration?: Array<{
          delay?: number | null;
          takenDelay?: number | null;
          type: SubscriptionType;
        }> | null;
      };
    }>;
  }>;
};

export type AndonScheduleQueryVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  input?: InputMaybe<AndonShiftInput>;
  includeExcluded?: InputMaybe<Scalars['Boolean']>;
}>;

export type AndonScheduleQuery = {
  andon: Array<{
    companyId: string;
    _schedule: {
      id: string;
      name: string;
      lines: Array<{ id: string; name: string }>;
      shifts: Array<{
        id: string;
        name: string;
        duration: number;
        rRuleSet: string;
        attendees: Array<{ id: string; name: string; isPermanent?: boolean | null; isExcluded?: boolean | null }>;
      }>;
    };
  }>;
};

export type BatchWithControlsQueryVariables = Exact<{
  lineId: Scalars['ID'];
  batchId: Scalars['ID'];
}>;

export type BatchWithControlsQuery = {
  line: {
    id: string;
    batches: {
      nextToken?: string | null;
      items: Array<{
        batchId: string;
        batchNumber: string;
        state: BatchState;
        amount: number;
        manualScrap?: number | null;
        plannedStart?: string | null;
        actualStart?: string | null;
        actualStop?: string | null;
        comment?: string | null;
        lineId: string;
        product: {
          productId: string;
          name: string;
          itemNumber: string;
          lineId: string;
          validatedLineSpeed: number;
          expectedAverageSpeed: number;
          dataMultiplier: number;
          comment?: string | null;
          packaging: {
            packagingId: string;
            lineId: string;
            name: string;
            packagingNumber: string;
            unit: string;
            comment?: string | null;
          };
          attachedControlReceipts: Array<{
            controlReceiptId: string;
            name: string;
            description?: string | null;
            entries: Array<{
              entryId: string;
              title?: string | null;
              trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
              followUp: { enabled: boolean; delayMs?: number | null };
              fields: Array<{
                fieldId: string;
                label: string;
                description?: string | null;
                type: ControlFieldType;
                sensorId?: string | null;
                limits?: { lower?: number | null; upper?: number | null } | null;
                derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
              }>;
            }>;
          }>;
          parameters: Array<{ key: string; value: string }>;
        };
        controls: Array<{
          batchControlId: string;
          controlReceiptId: string;
          controlReceiptName: string;
          entryId: string;
          title?: string | null;
          batchId: string;
          status: BatchControlStatus;
          timeTriggered: string;
          timeControlled?: string | null;
          timeControlUpdated?: string | null;
          comment?: string | null;
          initials?: string | null;
          fieldValues: Array<{
            value?: string | null;
            controlReceiptField: {
              fieldId: string;
              label: string;
              description?: string | null;
              type: ControlFieldType;
              sensorId?: string | null;
              limits?: { lower?: number | null; upper?: number | null } | null;
              derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
            };
          }>;
          trigger: { delayWhenStopped: boolean; type: ControlTriggerType; payload?: { millis: number } | null };
          followUp: { enabled: boolean; delayMs?: number | null };
          originalControl?: { controlId: string; timeTriggered: string } | null;
          history: Array<{
            status: BatchControlStatus;
            timeControlled?: string | null;
            timeControlUpdated?: string | null;
            comment?: string | null;
            initials?: string | null;
            fieldValues: Array<{
              value?: string | null;
              controlReceiptField: {
                fieldId: string;
                label: string;
                description?: string | null;
                type: ControlFieldType;
                sensorId?: string | null;
                limits?: { lower?: number | null; upper?: number | null } | null;
                derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
              };
            }>;
          }>;
        }>;
      }>;
    };
  };
};

export type BatchesPeripheralsFromLineSimpleQueryVariables = Exact<{
  lineId: Scalars['ID'];
}>;

export type BatchesPeripheralsFromLineSimpleQuery = {
  line: {
    nodes: Array<{ peripheralId: string; sensor?: { name: string; config: { type?: SensorType | null } } | null }>;
  };
};

export type CockpitOverviewTreeNodeStopsInfoQueryVariables = Exact<{
  nodeId: Scalars['ID'];
  time: Array<Time> | Time;
}>;

export type CockpitOverviewTreeNodeStopsInfoQuery = {
  getNode?: {
    leafDescendants?: {
      nodes: Array<{
        peripheral?:
          | {
              name: string;
              peripheralId: string;
              time?: Array<{ stats: { data: { numberOfUnregisteredStops?: number | null } } } | null> | null;
            }
          | {}
          | null;
        line?: {
          id: string;
          name: string;
          nodes: Array<{
            sensor?: {
              name: string;
              peripheralId: string;
              time?: Array<{ stats: { data: { numberOfUnregisteredStops?: number | null } } } | null> | null;
            } | null;
          }>;
        } | null;
      }>;
    } | null;
  } | null;
};

export type CockpitOverviewTreeNodeQueryVariables = Exact<{
  nodeId: Scalars['ID'];
}>;

export type CockpitOverviewTreeNodeQuery = {
  getNode?: {
    id: string;
    breadcrumb: Array<{ name: string; id: string }>;
    children: Array<{
      id: string;
      alarmStatus?: NodeAlarmStatus | null;
      peripheral?:
        | { id: string; name: string }
        | {
            id: string;
            name: string;
            alarms: Array<{ id: string } | null>;
            peripheralInformation?: { line: { batches: { items: Array<{ product: { name: string } }> } } } | null;
          }
        | null;
      line?: {
        id: string;
        name: string;
        nodes: Array<{
          peripheralId: string;
          sensor?: { name: string; alarms: Array<{ id: string; status: AlarmStatus; enabled: boolean } | null> } | null;
        }>;
        batches: { items: Array<{ product: { name: string } }> };
      } | null;
      breadcrumb: Array<{ name: string; id: string }>;
    }>;
  } | null;
};

export type CockpitProblemsTreeNodeQueryVariables = Exact<{
  nodeId: Scalars['ID'];
  time: Array<Time> | Time;
}>;

export type CockpitProblemsTreeNodeQuery = {
  getNode?: {
    id: string;
    breadcrumb: Array<{ name: string; id: string }>;
    leafDescendants?: {
      nodes: Array<{
        id: string;
        alarmStatus?: NodeAlarmStatus | null;
        peripheral?:
          | {
              id: string;
              name: string;
              time?: Array<{
                stopStats: {
                  lastStop?: {
                    ongoing: boolean;
                    stopCause?: { name: string; description: string } | null;
                    timeRange: { from?: string | null };
                  } | null;
                };
              } | null> | null;
              alarms: Array<{
                enabled: boolean;
                name: string;
                status: AlarmStatus;
                id: string;
                description: string;
                snoozeDuration?: number | null;
                alarmLogs: {
                  entries: Array<{
                    status: AlarmStatus;
                    timeRange: { from?: string | null; to?: string | null };
                  } | null>;
                };
              } | null>;
            }
          | {}
          | null;
        line?: {
          id: string;
          name: string;
          nodes: Array<{
            sensor?: {
              id: string;
              name: string;
              time?: Array<{
                stopStats: {
                  lastStop?: {
                    ongoing: boolean;
                    stopCause?: { name: string; description: string } | null;
                    timeRange: { from?: string | null };
                  } | null;
                };
              } | null> | null;
              alarms: Array<{
                enabled: boolean;
                name: string;
                status: AlarmStatus;
                id: string;
                description: string;
                snoozeDuration?: number | null;
                alarmLogs: {
                  entries: Array<{
                    status: AlarmStatus;
                    timeRange: { from?: string | null; to?: string | null };
                  } | null>;
                };
              } | null>;
            } | null;
          }>;
        } | null;
        breadcrumb: Array<{ name: string; id: string }>;
      }>;
    } | null;
  } | null;
};

export type CockpitTreeNodePendingControlsQueryVariables = Exact<{
  nodeId: Scalars['ID'];
  time: Array<Time> | Time;
}>;

export type CockpitTreeNodePendingControlsQuery = {
  getNode?: {
    leafDescendants?: {
      nodes: Array<{
        peripheral?:
          | {
              peripheralInformation?: {
                line: {
                  id: string;
                  name: string;
                  time?: Array<{
                    pendingControls: { nextToken?: string | null; items: Array<{ batchControlId: string }> };
                  } | null> | null;
                };
              } | null;
            }
          | {}
          | null;
        line?: {
          id: string;
          name: string;
          time?: Array<{
            pendingControls: { nextToken?: string | null; items: Array<{ batchControlId: string }> };
          } | null> | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type CompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type CompaniesQuery = { companies: Array<{ id: string; name?: string | null }> };

export type CompanyInfoQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyInfoQuery = { company: { id: string; name?: string | null } };

export type ConsolidatedGroupsLinesQueryVariables = Exact<{
  validIn: ScheduleTimeInput;
}>;

export type ConsolidatedGroupsLinesQuery = {
  user: {
    groups: Array<{
      id: string;
      name: string;
      lines: Array<{
        id: string;
        name: string;
        schedule?: {
          weeklyTargets: { oee?: number | null; oee2?: number | null; oee3?: number | null; tcu?: number | null };
        } | null;
      } | null>;
    }>;
  };
};

export type ControlReceiptsFromCompanyQueryVariables = Exact<{
  userPoolId: Scalars['ID'];
  filter?: InputMaybe<ControlReceiptFilter>;
  maxItems?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['ID']>;
}>;

export type ControlReceiptsFromCompanyQuery = {
  controlReceipts: {
    nextToken?: string | null;
    items: Array<{
      controlReceiptId: string;
      name: string;
      description?: string | null;
      entries: Array<{
        entryId: string;
        title?: string | null;
        trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
        followUp: { enabled: boolean; delayMs?: number | null };
        fields: Array<{
          fieldId: string;
          label: string;
          description?: string | null;
          type: ControlFieldType;
          sensorId?: string | null;
          limits?: { lower?: number | null; upper?: number | null } | null;
          derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
        }>;
      }>;
    }>;
  };
};

export type DeviceCameraQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  startTime?: InputMaybe<Scalars['Date']>;
}>;

export type DeviceCameraQuery = {
  device?: {
    _id?: string | null;
    peripheral?:
      | {
          streamURL?: string | null;
          _id?: string | null;
          peripheralId: string;
          peripheralType: PeripheralType;
          index: string;
          name: string;
          description: string;
          attachedSensors: Array<{
            _id?: string | null;
            peripheralId: string;
            peripheralType: PeripheralType;
            index: string;
            name: string;
            description: string;
          } | null>;
        }
      | {
          _id?: string | null;
          peripheralId: string;
          peripheralType: PeripheralType;
          index: string;
          name: string;
          description: string;
        }
      | null;
  } | null;
};

export type DeviceChangeoverBatchesQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  from: Scalars['Date'];
  nextToken?: InputMaybe<Scalars['ID']>;
}>;

export type DeviceChangeoverBatchesQuery = {
  device?: {
    uuid: string;
    _id?: string | null;
    owner?: string | null;
    sensor?: {
      _id?: string | null;
      id: string;
      peripheralId: string;
      peripheralInformation?: {
        line: {
          recentBatches: {
            nextToken?: string | null;
            items: Array<{
              batchId: string;
              batchNumber: string;
              state: BatchState;
              actualStart?: string | null;
              actualStop?: string | null;
              plannedStart?: string | null;
              amount: number;
              product: {
                productId: string;
                name: string;
                itemNumber: string;
                expectedAverageSpeed: number;
                parameters: Array<{ key: string; value: string }>;
              };
            }>;
          };
          pendingBatches: {
            nextToken?: string | null;
            items: Array<{
              batchId: string;
              batchNumber: string;
              state: BatchState;
              actualStart?: string | null;
              actualStop?: string | null;
              plannedStart?: string | null;
              amount: number;
              product: {
                productId: string;
                name: string;
                itemNumber: string;
                expectedAverageSpeed: number;
                parameters: Array<{ key: string; value: string }>;
              };
            }>;
          };
        };
      } | null;
    } | null;
  } | null;
};

export type ChangeoverBatchInformationFragment = {
  batchId: string;
  batchNumber: string;
  state: BatchState;
  actualStart?: string | null;
  actualStop?: string | null;
  plannedStart?: string | null;
  amount: number;
  product: {
    productId: string;
    name: string;
    itemNumber: string;
    expectedAverageSpeed: number;
    parameters: Array<{ key: string; value: string }>;
  };
};

export type DeviceDataAnalogQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  points?: InputMaybe<Scalars['Int']>;
}>;

export type DeviceDataAnalogQuery = {
  device?: {
    _id?: string | null;
    sensor?: {
      id: string;
      _id?: string | null;
      peripheralId: string;
      index: string;
      name: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      alarms: Array<{
        id: string;
        name: string;
        description: string;
        repeatNotification: boolean;
        threshold: number;
        status: AlarmStatus;
        enabled: boolean;
        type: AlarmType;
        alarmConfiguration: { x?: number | null; t?: number | null; y?: number | null; n?: number | null };
      } | null>;
      time?: Array<{
        samples?: Array<{
          data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
          timeRange: { from?: string | null; to?: string | null };
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeviceDataStatsOptionalStopsQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  timeRange: Time;
  showStops: Scalars['Boolean'];
  showAnnotations: Scalars['Boolean'];
  points?: InputMaybe<Scalars['Int']>;
  annotationInput?: InputMaybe<VerticalAnnotationsInput>;
  OEEInput: OeeInput;
}>;

export type DeviceDataStatsOptionalStopsQuery = {
  device?: {
    _id?: string | null;
    sensor?: {
      id: string;
      _id?: string | null;
      peripheralId: string;
      index: string;
      name: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      horizontalAnnotations?: Array<{ label: string; id: string; axisValue: string }> | null;
      verticalAnnotations?: Array<{
        id: string;
        label: string;
        timestamp: string;
        timestampEnd?: string | null;
        tags: Array<string>;
      }> | null;
      alarms: Array<{
        id: string;
        name: string;
        description: string;
        status: AlarmStatus;
        enabled: boolean;
        threshold: number;
        repeatNotification: boolean;
        type: AlarmType;
        alarmConfiguration: { x?: number | null; t?: number | null; y?: number | null; n?: number | null };
        timeRange?: { from?: string | null; to?: string | null } | null;
      } | null>;
      cameras: Array<{ _id?: string | null; name: string; peripheralId: string } | null>;
      time?: Array<{
        timeRange: { from?: string | null; to?: string | null };
        samples?: Array<{
          data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
          timeRange: { from?: string | null; to?: string | null };
        } | null> | null;
        dataOverrides: Array<{
          peripheralId: string;
          value: number;
          author: string;
          comment: string;
          timeRange: { from?: string | null; to?: string | null };
        }>;
        stats: {
          data: {
            produced?: number | null;
            longestNonStop?: number | null;
            numberOfStops?: number | null;
            averageStopLength?: number | null;
            downtime?: number | null;
            valueAddingTime?: number | null;
            valueAddingTimeWhileManned?: number | null;
            mtbf?: number | null;
            averageProducedMinute?: number | null;
            averageProducedHour?: number | null;
            cycleTime?: number | null;
            producedPerStop?: number | null;
            speedWhileManned?: number | null;
            speedWhileProducing?: number | null;
            oee?: { oee1: number; oee2: number; oee3: number; tcu: number } | null;
            lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null;
            customKPIs?: Array<{ id: string; name: string; unit: string; value?: number | null }> | null;
          };
        };
        batches: {
          nextToken?: string | null;
          items: Array<{
            batchId: string;
            batchNumber: string;
            state: BatchState;
            amount: number;
            manualScrap?: number | null;
            plannedStart?: string | null;
            actualStart?: string | null;
            actualStop?: string | null;
            comment?: string | null;
            lineId: string;
            stats: { data: { produced?: number | null } };
            product: {
              productId: string;
              name: string;
              itemNumber: string;
              lineId: string;
              validatedLineSpeed: number;
              expectedAverageSpeed: number;
              dataMultiplier: number;
              comment?: string | null;
              packaging: {
                packagingId: string;
                lineId: string;
                name: string;
                packagingNumber: string;
                unit: string;
                comment?: string | null;
              };
              parameters: Array<{ key: string; value: string }>;
            };
          }>;
        };
        stops?: Array<{
          __typename: 'Stop';
          _id: string;
          duration: number;
          ongoing: boolean;
          isMicroStop: boolean;
          comment?: string | null;
          initials?: string | null;
          registeredTime?: string | null;
          countermeasure?: string | null;
          countermeasureInitials?: string | null;
          standalone?: { excludeProduction?: boolean | null } | null;
          target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
          timeRange: { to?: string | null; from?: string | null };
          stopCause?: {
            _id: string;
            id: string;
            name: string;
            description: string;
            categoryName?: string | null;
            languageCode: string;
            stopType: StopType;
            requireInitials: boolean;
            requireComment: boolean;
            enableCountermeasure: boolean;
            meta: Array<{ name: string; description: string; languageCode: string }>;
            targetSetup?: {
              constantTime: number;
              parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
            } | null;
          } | null;
        } | null>;
      } | null> | null;
    } | null;
  } | null;
};

export type DeviceDataWithStatsQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  points?: InputMaybe<Scalars['Int']>;
  OEEInput: OeeInput;
}>;

export type DeviceDataWithStatsQuery = {
  device?: {
    _id?: string | null;
    sensor?: {
      id: string;
      _id?: string | null;
      peripheralId: string;
      index: string;
      name: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      alarms: Array<{
        id: string;
        name: string;
        description: string;
        status: AlarmStatus;
        enabled: boolean;
        threshold: number;
        repeatNotification: boolean;
        type: AlarmType;
        alarmConfiguration: { x?: number | null; t?: number | null; y?: number | null; n?: number | null };
      } | null>;
      cameras: Array<{ _id?: string | null; name: string; peripheralId: string } | null>;
      time?: Array<{
        samples?: Array<{
          data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
          timeRange: { from?: string | null; to?: string | null };
        } | null> | null;
        stats: {
          data: {
            produced?: number | null;
            longestNonStop?: number | null;
            numberOfStops?: number | null;
            averageStopLength?: number | null;
            downtime?: number | null;
            valueAddingTime?: number | null;
            valueAddingTimeWhileManned?: number | null;
            mtbf?: number | null;
            averageProducedMinute?: number | null;
            averageProducedHour?: number | null;
            cycleTime?: number | null;
            producedPerStop?: number | null;
            speedWhileManned?: number | null;
            speedWhileProducing?: number | null;
            oee?: { oee1: number; oee2: number; oee3: number; tcu: number } | null;
            lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null;
            customKPIs?: Array<{ id: string; name: string; unit: string; value?: number | null }> | null;
          };
        };
        batches: {
          nextToken?: string | null;
          items: Array<{
            batchId: string;
            batchNumber: string;
            state: BatchState;
            amount: number;
            manualScrap?: number | null;
            plannedStart?: string | null;
            actualStart?: string | null;
            actualStop?: string | null;
            comment?: string | null;
            lineId: string;
            stats: { data: { produced?: number | null } };
            product: {
              productId: string;
              name: string;
              itemNumber: string;
              lineId: string;
              validatedLineSpeed: number;
              expectedAverageSpeed: number;
              dataMultiplier: number;
              comment?: string | null;
              packaging: {
                packagingId: string;
                lineId: string;
                name: string;
                packagingNumber: string;
                unit: string;
                comment?: string | null;
              };
              parameters: Array<{ key: string; value: string }>;
            };
          }>;
        };
        stops: Array<{
          __typename: 'Stop';
          _id: string;
          duration: number;
          ongoing: boolean;
          isMicroStop: boolean;
          comment?: string | null;
          initials?: string | null;
          registeredTime?: string | null;
          countermeasure?: string | null;
          countermeasureInitials?: string | null;
          standalone?: { excludeProduction?: boolean | null } | null;
          target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
          timeRange: { to?: string | null; from?: string | null };
          stopCause?: {
            _id: string;
            id: string;
            name: string;
            description: string;
            categoryName?: string | null;
            languageCode: string;
            stopType: StopType;
            requireInitials: boolean;
            requireComment: boolean;
            enableCountermeasure: boolean;
            meta: Array<{ name: string; description: string; languageCode: string }>;
            targetSetup?: {
              constantTime: number;
              parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
            } | null;
          } | null;
        } | null>;
      } | null> | null;
    } | null;
  } | null;
};

export type DeviceDataQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  points: Scalars['Int'];
  filter?: InputMaybe<StopFilter>;
}>;

export type DeviceDataQuery = {
  device?: {
    uuid: string;
    _id?: string | null;
    owner?: string | null;
    sensor?: {
      _id?: string | null;
      id: string;
      peripheralId: string;
      name: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      peripheralInformation?: { line: { id: string } } | null;
      time?: Array<{
        timeRange: { from?: string | null; to?: string | null };
        samples?: Array<{
          timeRange: { to?: string | null; from?: string | null };
          data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
        } | null> | null;
        stops: Array<{
          __typename: 'Stop';
          _id: string;
          duration: number;
          ongoing: boolean;
          isMicroStop: boolean;
          comment?: string | null;
          initials?: string | null;
          registeredTime?: string | null;
          countermeasure?: string | null;
          countermeasureInitials?: string | null;
          standalone?: { excludeProduction?: boolean | null } | null;
          target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
          timeRange: { to?: string | null; from?: string | null };
          stopCause?: {
            _id: string;
            id: string;
            name: string;
            description: string;
            categoryName?: string | null;
            languageCode: string;
            stopType: StopType;
            requireInitials: boolean;
            requireComment: boolean;
            enableCountermeasure: boolean;
            meta: Array<{ name: string; description: string; languageCode: string }>;
            targetSetup?: {
              constantTime: number;
              parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
            } | null;
          } | null;
        } | null>;
      } | null> | null;
    } | null;
  } | null;
};

export type DeviceInfoQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
}>;

export type DeviceInfoQuery = {
  device?: {
    _id?: string | null;
    owner?: string | null;
    peripheral?:
      | {
          _id?: string | null;
          peripheralId: string;
          peripheralType: PeripheralType;
          name: string;
          description: string;
          attachedSensors: Array<{
            _id?: string | null;
            peripheralId: string;
            peripheralType: PeripheralType;
            name: string;
            description: string;
          } | null>;
        }
      | {
          id: string;
          _id?: string | null;
          peripheralId: string;
          peripheralType: PeripheralType;
          name: string;
          description: string;
          peripheralInformation?: { node: { type: NodeType }; line: { id: string; name: string } } | null;
          config: {
            type?: SensorType | null;
            stopThreshold?: number | null;
            findStops?: boolean | null;
            energyMeter?: boolean | null;
            stopRegisterThreshold?: number | null;
            stopValueThreshold?: number | null;
            dataMultiplier?: number | null;
            validatedSpeed?: number | null;
            expectedSpeed?: number | null;
            analogOffset?: number | null;
            analogInputRange?: AnalogInputRange | null;
            publishRate?: number | null;
            sampleRate?: number | null;
            triggerIndex?: number | null;
            chartTimeScale?: ChartTimeScale | null;
            sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
            accumulatorConfig?: {
              resetsOnPowerOff: boolean;
              rolloverValue: number;
              rolloverThreshold?: number | null;
            } | null;
            discreteConfig?: {
              onStates: Array<number>;
              offStates: Array<number>;
              defaultState: DiscreteDeviceState;
            } | null;
            dataAlarm?: {
              notificationFrequency?: number | null;
              updateBuffer?: number | null;
              repeatNotification?: boolean | null;
              subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
            } | null;
            chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
          };
        }
      | null;
  } | null;
  units?: { units?: Array<{ name: string; description?: string | null }> | null } | null;
  analogInputRanges?: { analogInputRanges?: Array<{ name: string; description?: string | null }> | null } | null;
  sensorTypes?: { sensorTypes?: Array<{ name: string; description?: string | null }> | null } | null;
};

export type DeviceLineSensorDataQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  timeRange: Time;
  points: Scalars['Int'];
  annotationInput?: InputMaybe<VerticalAnnotationsInput>;
  showAnnotations: Scalars['Boolean'];
}>;

export type DeviceLineSensorDataQuery = {
  device?: {
    uuid: string;
    _id?: string | null;
    owner?: string | null;
    sensor?: {
      _id?: string | null;
      id: string;
      peripheralId: string;
      name: string;
      description: string;
      peripheralInformation?: { node: { type: NodeType }; line: { id: string; name: string } } | null;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      verticalAnnotations?: Array<{
        id: string;
        label: string;
        timestamp: string;
        timestampEnd?: string | null;
        tags: Array<string>;
      }> | null;
      time?: Array<{
        timeRange: { from?: string | null; to?: string | null };
        samples?: Array<{
          timeRange: { to?: string | null; from?: string | null };
          data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
        } | null> | null;
        dataOverrides: Array<{
          peripheralId: string;
          value: number;
          author: string;
          comment: string;
          timeRange: { from?: string | null; to?: string | null };
        }>;
      } | null> | null;
    } | null;
  } | null;
  units?: { units?: Array<{ name: string; description?: string | null }> | null } | null;
  analogInputRanges?: { analogInputRanges?: Array<{ name: string; description?: string | null }> | null } | null;
  sensorTypes?: { sensorTypes?: Array<{ name: string; description?: string | null }> | null } | null;
};

export type DeviceMinimumDataQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  points: Scalars['Int'];
}>;

export type DeviceMinimumDataQuery = {
  device?: {
    _id?: string | null;
    owner?: string | null;
    peripheral?:
      | {
          _id?: string | null;
          id: string;
          peripheralId: string;
          index: string;
          name: string;
          peripheralType: PeripheralType;
          description: string;
        }
      | {
          _id?: string | null;
          id: string;
          peripheralId: string;
          index: string;
          name: string;
          peripheralType: PeripheralType;
          description: string;
          peripheralInformation?: { node: { type: NodeType }; line: { id: string; name: string } } | null;
          config: {
            type?: SensorType | null;
            stopThreshold?: number | null;
            findStops?: boolean | null;
            energyMeter?: boolean | null;
            stopRegisterThreshold?: number | null;
            stopValueThreshold?: number | null;
            dataMultiplier?: number | null;
            validatedSpeed?: number | null;
            expectedSpeed?: number | null;
            analogOffset?: number | null;
            analogInputRange?: AnalogInputRange | null;
            publishRate?: number | null;
            sampleRate?: number | null;
            triggerIndex?: number | null;
            chartTimeScale?: ChartTimeScale | null;
            sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
            accumulatorConfig?: {
              resetsOnPowerOff: boolean;
              rolloverValue: number;
              rolloverThreshold?: number | null;
            } | null;
            discreteConfig?: {
              onStates: Array<number>;
              offStates: Array<number>;
              defaultState: DiscreteDeviceState;
            } | null;
            dataAlarm?: {
              notificationFrequency?: number | null;
              updateBuffer?: number | null;
              repeatNotification?: boolean | null;
              subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
            } | null;
            chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
          };
          time?: Array<{
            timeRange: { from?: string | null; to?: string | null };
            samples?: Array<{
              data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
              timeRange: { from?: string | null; to?: string | null };
            } | null> | null;
            batches: {
              nextToken?: string | null;
              items: Array<{
                batchId: string;
                batchNumber: string;
                state: BatchState;
                amount: number;
                manualScrap?: number | null;
                plannedStart?: string | null;
                actualStart?: string | null;
                actualStop?: string | null;
                comment?: string | null;
                lineId: string;
                stats: { data: { produced?: number | null } };
                product: {
                  productId: string;
                  name: string;
                  itemNumber: string;
                  lineId: string;
                  validatedLineSpeed: number;
                  expectedAverageSpeed: number;
                  dataMultiplier: number;
                  comment?: string | null;
                  packaging: {
                    packagingId: string;
                    lineId: string;
                    name: string;
                    packagingNumber: string;
                    unit: string;
                    comment?: string | null;
                  };
                  parameters: Array<{ key: string; value: string }>;
                };
              }>;
            };
            stats: {
              data: { lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null };
            };
          } | null> | null;
        }
      | null;
  } | null;
  units?: { units?: Array<{ name: string; description?: string | null }> | null } | null;
  analogInputRanges?: { analogInputRanges?: Array<{ name: string; description?: string | null }> | null } | null;
  sensorTypes?: { sensorTypes?: Array<{ name: string; description?: string | null }> | null } | null;
};

export type DeviceOeeDataQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
}>;

export type DeviceOeeDataQuery = {
  device?: {
    _id?: string | null;
    uuid: string;
    sensor?: {
      _id?: string | null;
      id: string;
      peripheralId: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      time?: Array<{
        timeRange: { from?: string | null; to?: string | null };
        stopStats: {
          longestNonStop: number;
          stopCauseStats: Array<{
            stopCauseId: string;
            stopCauseName: string;
            stopCauseDescription: string;
            stopCategoryName: string;
            type?: StopType | null;
            accDuration: number;
            accTarget: number;
            numberOfStops: number;
            isMicroStop: boolean;
          } | null>;
        };
        stats: {
          timeRange: { from?: string | null; to?: string | null };
          data: {
            oee?: {
              oee1: number;
              oee2: number;
              oee3: number;
              tcu: number;
              totalEquipmentTime: number;
              mannedTime: number;
              productionTime: number;
              operatingTime: number;
              valuedOperatingTime: number;
              speedLoss: number;
              scrapLoss: number;
            } | null;
          };
        };
      } | null> | null;
    } | null;
  } | null;
};

export type DeviceSampleQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Time;
}>;

export type DeviceSampleQuery = {
  device?: {
    _id?: string | null;
    sensor?: {
      id: string;
      _id?: string | null;
      time?: Array<{
        samples?: Array<{
          data: { count: number; accValue?: number | null };
          timeRange: { from?: string | null; to?: string | null };
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DevicesSimpleQueryVariables = Exact<{ [key: string]: never }>;

export type DevicesSimpleQuery = {
  devices: Array<{
    _id?: string | null;
    uuid: string;
    type: string;
    peripherals: Array<
      | {
          streamURL?: string | null;
          _id?: string | null;
          index: string;
          peripheralId: string;
          name: string;
          description: string;
          peripheralType: PeripheralType;
          attachedSensors: Array<{
            _id?: string | null;
            peripheralId: string;
            peripheralType: PeripheralType;
            index: string;
            name: string;
            description: string;
          } | null>;
        }
      | {
          _id?: string | null;
          index: string;
          peripheralId: string;
          name: string;
          description: string;
          peripheralType: PeripheralType;
          config: {
            type?: SensorType | null;
            stopThreshold?: number | null;
            findStops?: boolean | null;
            energyMeter?: boolean | null;
            stopRegisterThreshold?: number | null;
            stopValueThreshold?: number | null;
            dataMultiplier?: number | null;
            validatedSpeed?: number | null;
            expectedSpeed?: number | null;
            analogOffset?: number | null;
            analogInputRange?: AnalogInputRange | null;
            publishRate?: number | null;
            sampleRate?: number | null;
            triggerIndex?: number | null;
            chartTimeScale?: ChartTimeScale | null;
            sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
            accumulatorConfig?: {
              resetsOnPowerOff: boolean;
              rolloverValue: number;
              rolloverThreshold?: number | null;
            } | null;
            discreteConfig?: {
              onStates: Array<number>;
              offStates: Array<number>;
              defaultState: DiscreteDeviceState;
            } | null;
            dataAlarm?: {
              notificationFrequency?: number | null;
              updateBuffer?: number | null;
              repeatNotification?: boolean | null;
              subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
            } | null;
            chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
          };
        }
      | null
    >;
  } | null>;
};

export type EnergyMeterStatsQueryVariables = Exact<{
  peripheralIds: Array<Scalars['ID']> | Scalars['ID'];
  time: Array<Time> | Time;
}>;

export type EnergyMeterStatsQuery = {
  peripheralByIDs: Array<
    | { _id?: string | null; id: string; name: string }
    | {
        _id?: string | null;
        id: string;
        name: string;
        config: {
          type?: SensorType | null;
          stopThreshold?: number | null;
          findStops?: boolean | null;
          energyMeter?: boolean | null;
          stopRegisterThreshold?: number | null;
          stopValueThreshold?: number | null;
          dataMultiplier?: number | null;
          validatedSpeed?: number | null;
          expectedSpeed?: number | null;
          analogOffset?: number | null;
          analogInputRange?: AnalogInputRange | null;
          publishRate?: number | null;
          sampleRate?: number | null;
          triggerIndex?: number | null;
          chartTimeScale?: ChartTimeScale | null;
          sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
          accumulatorConfig?: {
            resetsOnPowerOff: boolean;
            rolloverValue: number;
            rolloverThreshold?: number | null;
          } | null;
          discreteConfig?: {
            onStates: Array<number>;
            offStates: Array<number>;
            defaultState: DiscreteDeviceState;
          } | null;
          dataAlarm?: {
            notificationFrequency?: number | null;
            updateBuffer?: number | null;
            repeatNotification?: boolean | null;
            subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
          } | null;
          chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
        };
        time?: Array<{
          samples?: Array<{
            data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
            timeRange: { from?: string | null; to?: string | null };
          } | null> | null;
        } | null> | null;
      }
    | null
  >;
};

export type GroupsAndLinesQueryVariables = Exact<{ [key: string]: never }>;

export type GroupsAndLinesQuery = {
  company: {
    id: string;
    groups: Array<{
      id: string;
      defaultGroup?: boolean | null;
      name: string;
      role: { id: string; name: string; type: RoleType };
      lines: Array<{ id: string; name: string } | null>;
    }>;
  };
};

export type GroupsWithLineIdsQueryVariables = Exact<{ [key: string]: never }>;

export type GroupsWithLineIdsQuery = {
  company: { groups: Array<{ id: string; name: string; lines: Array<{ id: string; name: string } | null> }> };
};

export type LineBatchesQueryVariables = Exact<{
  lineId: Scalars['ID'];
  filter?: InputMaybe<BatchFilter>;
  maxItems?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['ID']>;
}>;

export type LineBatchesQuery = {
  line: {
    id: string;
    batches: {
      nextToken?: string | null;
      pages: Array<string>;
      count: number;
      items: Array<{
        batchId: string;
        batchNumber: string;
        state: BatchState;
        amount: number;
        manualScrap?: number | null;
        plannedStart?: string | null;
        actualStart?: string | null;
        actualStop?: string | null;
        comment?: string | null;
        lineId: string;
        product: {
          productId: string;
          name: string;
          itemNumber: string;
          lineId: string;
          validatedLineSpeed: number;
          expectedAverageSpeed: number;
          dataMultiplier: number;
          comment?: string | null;
          packaging: {
            packagingId: string;
            lineId: string;
            name: string;
            packagingNumber: string;
            unit: string;
            comment?: string | null;
          };
          parameters: Array<{ key: string; value: string }>;
        };
        stats: { data: { produced?: number | null } };
      }>;
    };
  };
};

export type LineChangeoverStopsQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  filter?: InputMaybe<StopFilter>;
}>;

export type LineChangeoverStopsQuery = {
  device?: {
    _id?: string | null;
    sensor?: {
      _id?: string | null;
      id: string;
      peripheralId: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      peripheralInformation?: {
        line: {
          id: string;
          name: string;
          time?: Array<{
            changeoverStops: Array<{
              duration: number;
              ongoing: boolean;
              isMicroStop: boolean;
              comment?: string | null;
              initials?: string | null;
              registeredTime?: string | null;
              target?: {
                target: number;
                previousBatch?: {
                  batchId: string;
                  batchNumber: string;
                  plannedStart?: string | null;
                  actualStart?: string | null;
                  actualStop?: string | null;
                  product: {
                    productId: string;
                    name: string;
                    itemNumber: string;
                    parameters: Array<{ key: string; value: string }>;
                  };
                } | null;
                nextBatch?: {
                  batchId: string;
                  batchNumber: string;
                  plannedStart?: string | null;
                  actualStart?: string | null;
                  actualStop?: string | null;
                  product: {
                    productId: string;
                    name: string;
                    itemNumber: string;
                    parameters: Array<{ key: string; value: string }>;
                  };
                } | null;
                shift?: {
                  id: string;
                  name: string;
                  description: string;
                  timeRange: {
                    from: { day: DayOfWeek; hour: number; minute: number };
                    to: { day: DayOfWeek; hour: number; minute: number };
                  };
                  targets: {
                    oee?: number | null;
                    oee2?: number | null;
                    oee3?: number | null;
                    tcu?: number | null;
                    produced?: number | null;
                    numberOfBatches?: number | null;
                  };
                } | null;
              } | null;
              timeRange: { to?: string | null; from?: string | null };
              stopCause?: {
                _id: string;
                id: string;
                name: string;
                description: string;
                categoryName?: string | null;
                languageCode: string;
                stopType: StopType;
                requireInitials: boolean;
                requireComment: boolean;
                enableCountermeasure: boolean;
                meta: Array<{ name: string; description: string; languageCode: string }>;
                targetSetup?: {
                  constantTime: number;
                  parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
                } | null;
              } | null;
            } | null>;
          } | null> | null;
        };
      } | null;
    } | null;
  } | null;
};

export type LineConsolidatedOeeQueryVariables = Exact<{
  lineIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  time: Time;
  includeScrap?: InputMaybe<Scalars['Boolean']>;
}>;

export type LineConsolidatedOeeQuery = {
  consolidatedData: {
    oee?: {
      oee1: number;
      oee2: number;
      oee3: number;
      tcu: number;
      totalEquipmentTime: number;
      mannedTime: number;
      productionTime: number;
      operatingTime: number;
      valuedOperatingTime: number;
      speedLoss: number;
      scrapLoss: number;
    } | null;
    stopsData?: Array<{
      lineId: string;
      _id: string;
      stopStats: {
        longestNonStop: number;
        stopCauseStats: Array<{
          stopCauseId: string;
          stopCauseName: string;
          stopCauseDescription: string;
          stopCategoryName: string;
          type?: StopType | null;
          accDuration: number;
          accTarget: number;
          numberOfStops: number;
          isMicroStop: boolean;
        } | null>;
      };
    } | null> | null;
  };
};

export type LineControlsFromBatchQueryVariables = Exact<{
  lineId: Scalars['ID'];
  batchId: Scalars['ID'];
}>;

export type LineControlsFromBatchQuery = {
  line: {
    id: string;
    batch: {
      batchId: string;
      product: {
        attachedControlReceipts: Array<{
          controlReceiptId: string;
          name: string;
          description?: string | null;
          entries: Array<{
            entryId: string;
            title?: string | null;
            trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
            followUp: { enabled: boolean; delayMs?: number | null };
            fields: Array<{
              fieldId: string;
              label: string;
              description?: string | null;
              type: ControlFieldType;
              sensorId?: string | null;
              limits?: { lower?: number | null; upper?: number | null } | null;
              derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
            }>;
          }>;
        }>;
      };
      controls: Array<{
        batchControlId: string;
        controlReceiptId: string;
        controlReceiptName: string;
        entryId: string;
        title?: string | null;
        batchId: string;
        status: BatchControlStatus;
        timeTriggered: string;
        timeControlled?: string | null;
        timeControlUpdated?: string | null;
        comment?: string | null;
        initials?: string | null;
        fieldValues: Array<{
          value?: string | null;
          controlReceiptField: {
            fieldId: string;
            label: string;
            description?: string | null;
            type: ControlFieldType;
            sensorId?: string | null;
            limits?: { lower?: number | null; upper?: number | null } | null;
            derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
          };
        }>;
        trigger: { delayWhenStopped: boolean; type: ControlTriggerType; payload?: { millis: number } | null };
        followUp: { enabled: boolean; delayMs?: number | null };
        originalControl?: { controlId: string; timeTriggered: string } | null;
        history: Array<{
          status: BatchControlStatus;
          timeControlled?: string | null;
          timeControlUpdated?: string | null;
          comment?: string | null;
          initials?: string | null;
          fieldValues: Array<{
            value?: string | null;
            controlReceiptField: {
              fieldId: string;
              label: string;
              description?: string | null;
              type: ControlFieldType;
              sensorId?: string | null;
              limits?: { lower?: number | null; upper?: number | null } | null;
              derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
            };
          }>;
        }>;
      }>;
    };
  };
};

export type LineDashboardQueryVariables = Exact<{
  lineId: Scalars['ID'];
  timeDay: Array<Time> | Time;
  timeWeek: Array<Time> | Time;
  points: Scalars['Int'];
  timeHour: Array<Time> | Time;
  validIn: ScheduleTimeInput;
  OEEInput: OeeInput;
}>;

export type LineDashboardQuery = {
  lineDay: {
    id: string;
    name: string;
    description?: string | null;
    mainSensor?: {
      _id?: string | null;
      id: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
    } | null;
    time?: Array<{
      _id?: string | null;
      timeRange: { from?: string | null; to?: string | null };
      samples?: Array<{
        timeRange: { to?: string | null; from?: string | null };
        data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
      } | null> | null;
      stops: Array<{
        __typename: 'Stop';
        _id: string;
        duration: number;
        ongoing: boolean;
        isMicroStop: boolean;
        comment?: string | null;
        initials?: string | null;
        registeredTime?: string | null;
        countermeasure?: string | null;
        countermeasureInitials?: string | null;
        standalone?: { excludeProduction?: boolean | null } | null;
        target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
        timeRange: { to?: string | null; from?: string | null };
        stopCause?: {
          _id: string;
          id: string;
          name: string;
          description: string;
          categoryName?: string | null;
          languageCode: string;
          stopType: StopType;
          requireInitials: boolean;
          requireComment: boolean;
          enableCountermeasure: boolean;
          meta: Array<{ name: string; description: string; languageCode: string }>;
          targetSetup?: {
            constantTime: number;
            parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
          } | null;
        } | null;
      } | null>;
      stats: {
        data: {
          produced?: number | null;
          longestNonStop?: number | null;
          numberOfStops?: number | null;
          averageStopLength?: number | null;
          downtime?: number | null;
          valueAddingTime?: number | null;
          valueAddingTimeWhileManned?: number | null;
          mtbf?: number | null;
          averageProducedMinute?: number | null;
          averageProducedHour?: number | null;
          cycleTime?: number | null;
          producedPerStop?: number | null;
          speedWhileManned?: number | null;
          speedWhileProducing?: number | null;
          oee?: { oee1: number; oee2: number; oee3: number; tcu: number } | null;
          lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null;
          customKPIs?: Array<{ id: string; name: string; unit: string; value?: number | null }> | null;
        };
      };
      batches: {
        nextToken?: string | null;
        items: Array<{
          batchId: string;
          batchNumber: string;
          state: BatchState;
          amount: number;
          manualScrap?: number | null;
          plannedStart?: string | null;
          actualStart?: string | null;
          actualStop?: string | null;
          comment?: string | null;
          lineId: string;
          samples?: Array<{
            timeRange: { to?: string | null; from?: string | null };
            data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
          } | null> | null;
          stats: { data: { produced?: number | null } };
          stops: Array<{
            __typename: 'Stop';
            _id: string;
            duration: number;
            ongoing: boolean;
            isMicroStop: boolean;
            comment?: string | null;
            initials?: string | null;
            registeredTime?: string | null;
            countermeasure?: string | null;
            countermeasureInitials?: string | null;
            standalone?: { excludeProduction?: boolean | null } | null;
            target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
            timeRange: { to?: string | null; from?: string | null };
            stopCause?: {
              _id: string;
              id: string;
              name: string;
              description: string;
              categoryName?: string | null;
              languageCode: string;
              stopType: StopType;
              requireInitials: boolean;
              requireComment: boolean;
              enableCountermeasure: boolean;
              meta: Array<{ name: string; description: string; languageCode: string }>;
              targetSetup?: {
                constantTime: number;
                parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
              } | null;
            } | null;
          } | null>;
          product: {
            productId: string;
            name: string;
            itemNumber: string;
            lineId: string;
            validatedLineSpeed: number;
            expectedAverageSpeed: number;
            dataMultiplier: number;
            comment?: string | null;
            packaging: {
              packagingId: string;
              lineId: string;
              name: string;
              packagingNumber: string;
              unit: string;
              comment?: string | null;
            };
            parameters: Array<{ key: string; value: string }>;
          };
        }>;
      };
    } | null> | null;
  };
  lineHour: {
    id: string;
    name: string;
    description?: string | null;
    time?: Array<{
      _id?: string | null;
      timeRange: { to?: string | null; from?: string | null };
      stats: {
        data: {
          produced?: number | null;
          longestNonStop?: number | null;
          numberOfStops?: number | null;
          averageStopLength?: number | null;
          downtime?: number | null;
          valueAddingTime?: number | null;
          valueAddingTimeWhileManned?: number | null;
          mtbf?: number | null;
          averageProducedMinute?: number | null;
          averageProducedHour?: number | null;
          cycleTime?: number | null;
          producedPerStop?: number | null;
          speedWhileManned?: number | null;
          speedWhileProducing?: number | null;
          oee?: { oee1: number; oee2: number; oee3: number; tcu: number } | null;
          lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null;
          customKPIs?: Array<{ id: string; name: string; unit: string; value?: number | null }> | null;
        };
      };
    } | null> | null;
  };
  lineWeek: {
    id: string;
    name: string;
    description?: string | null;
    schedule?: {
      id: string;
      lineId: string;
      isExceptionalWeek: boolean;
      isFallbackSchedule: boolean;
      validFrom: { year: number; week: number };
      validTo?: { year: number; week: number } | null;
      shifts: Array<{
        id: string;
        name: string;
        description: string;
        timeRange: {
          from: { day: DayOfWeek; hour: number; minute: number };
          to: { day: DayOfWeek; hour: number; minute: number };
        };
        targets: {
          oee?: number | null;
          oee2?: number | null;
          oee3?: number | null;
          tcu?: number | null;
          produced?: number | null;
          numberOfBatches?: number | null;
        };
      }>;
      weeklyTargets: {
        lineId: string;
        id?: string | null;
        oee?: number | null;
        oee2?: number | null;
        oee3?: number | null;
        tcu?: number | null;
        produced?: number | null;
        numberOfBatches?: number | null;
        mainOeeType?: OeeType | null;
        validFrom?: { year: number; week: number } | null;
      };
      configuration: {
        lineId: string;
        disableLostConnectionAlarmOutsideWorkingHours: boolean;
        timezone: string;
        startDayOfWeek: DayOfWeek;
        automaticStopRegistration: {
          enabled: boolean;
          stopCauseId?: string | null;
          minimumStopMillis?: number | null;
          splitAtEnds?: boolean | null;
          comment?: string | null;
        };
      };
    } | null;
    time?: Array<{
      _id?: string | null;
      timeRange: { to?: string | null; from?: string | null };
      stats: {
        data: {
          produced?: number | null;
          longestNonStop?: number | null;
          numberOfStops?: number | null;
          averageStopLength?: number | null;
          downtime?: number | null;
          valueAddingTime?: number | null;
          valueAddingTimeWhileManned?: number | null;
          mtbf?: number | null;
          averageProducedMinute?: number | null;
          averageProducedHour?: number | null;
          cycleTime?: number | null;
          producedPerStop?: number | null;
          speedWhileManned?: number | null;
          speedWhileProducing?: number | null;
          oee?: { oee1: number; oee2: number; oee3: number; tcu: number } | null;
          lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null;
          customKPIs?: Array<{ id: string; name: string; unit: string; value?: number | null }> | null;
        };
      };
    } | null> | null;
  };
  andon: Array<{ companyId: string; calls: Array<{ id: string; urgency: Urgency }> }>;
};

export type LineGroupShiftDashboardQueryVariables = Exact<{
  groupId: Scalars['ID'];
  currentShiftTime: Time;
  points: Scalars['Int'];
  previousShiftTime: Time;
  hasPreviousShift: Scalars['Boolean'];
}>;

export type LineGroupShiftDashboardQuery = {
  company: {
    group: {
      name: string;
      lines: Array<{
        id: string;
        name: string;
        description?: string | null;
        mainSensor?: {
          _id?: string | null;
          id: string;
          config: {
            type?: SensorType | null;
            stopThreshold?: number | null;
            findStops?: boolean | null;
            energyMeter?: boolean | null;
            stopRegisterThreshold?: number | null;
            stopValueThreshold?: number | null;
            dataMultiplier?: number | null;
            validatedSpeed?: number | null;
            expectedSpeed?: number | null;
            analogOffset?: number | null;
            analogInputRange?: AnalogInputRange | null;
            publishRate?: number | null;
            sampleRate?: number | null;
            triggerIndex?: number | null;
            chartTimeScale?: ChartTimeScale | null;
            sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
            accumulatorConfig?: {
              resetsOnPowerOff: boolean;
              rolloverValue: number;
              rolloverThreshold?: number | null;
            } | null;
            discreteConfig?: {
              onStates: Array<number>;
              offStates: Array<number>;
              defaultState: DiscreteDeviceState;
            } | null;
            dataAlarm?: {
              notificationFrequency?: number | null;
              updateBuffer?: number | null;
              repeatNotification?: boolean | null;
              subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
            } | null;
            chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
          };
        } | null;
        currentShift?: Array<{
          _id?: string | null;
          samples?: Array<{
            timeRange: { to?: string | null; from?: string | null };
            data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
          } | null> | null;
          stats: { data: { produced?: number | null } };
          stops: Array<{
            __typename: 'Stop';
            _id: string;
            duration: number;
            ongoing: boolean;
            isMicroStop: boolean;
            comment?: string | null;
            initials?: string | null;
            registeredTime?: string | null;
            countermeasure?: string | null;
            countermeasureInitials?: string | null;
            standalone?: { excludeProduction?: boolean | null } | null;
            target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
            timeRange: { to?: string | null; from?: string | null };
            stopCause?: {
              _id: string;
              id: string;
              name: string;
              description: string;
              categoryName?: string | null;
              languageCode: string;
              stopType: StopType;
              requireInitials: boolean;
              requireComment: boolean;
              enableCountermeasure: boolean;
              meta: Array<{ name: string; description: string; languageCode: string }>;
              targetSetup?: {
                constantTime: number;
                parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
              } | null;
            } | null;
          } | null>;
          batches: {
            nextToken?: string | null;
            items: Array<{
              batchId: string;
              batchNumber: string;
              state: BatchState;
              amount: number;
              manualScrap?: number | null;
              plannedStart?: string | null;
              actualStart?: string | null;
              actualStop?: string | null;
              comment?: string | null;
              lineId: string;
              product: {
                productId: string;
                name: string;
                itemNumber: string;
                lineId: string;
                validatedLineSpeed: number;
                expectedAverageSpeed: number;
                dataMultiplier: number;
                comment?: string | null;
                packaging: {
                  packagingId: string;
                  lineId: string;
                  name: string;
                  packagingNumber: string;
                  unit: string;
                  comment?: string | null;
                };
                parameters: Array<{ key: string; value: string }>;
              };
            }>;
          };
        } | null> | null;
        previousShift?: Array<{
          _id?: string | null;
          stats: { data: { produced?: number | null } };
          stops: Array<{
            __typename: 'Stop';
            _id: string;
            duration: number;
            ongoing: boolean;
            isMicroStop: boolean;
            comment?: string | null;
            initials?: string | null;
            registeredTime?: string | null;
            countermeasure?: string | null;
            countermeasureInitials?: string | null;
            standalone?: { excludeProduction?: boolean | null } | null;
            target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
            timeRange: { to?: string | null; from?: string | null };
            stopCause?: {
              _id: string;
              id: string;
              name: string;
              description: string;
              categoryName?: string | null;
              languageCode: string;
              stopType: StopType;
              requireInitials: boolean;
              requireComment: boolean;
              enableCountermeasure: boolean;
              meta: Array<{ name: string; description: string; languageCode: string }>;
              targetSetup?: {
                constantTime: number;
                parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
              } | null;
            } | null;
          } | null>;
          batches: {
            items: Array<{
              batchId: string;
              batchNumber: string;
              state: BatchState;
              amount: number;
              manualScrap?: number | null;
              plannedStart?: string | null;
              actualStart?: string | null;
              actualStop?: string | null;
              comment?: string | null;
              lineId: string;
              product: {
                productId: string;
                name: string;
                itemNumber: string;
                lineId: string;
                validatedLineSpeed: number;
                expectedAverageSpeed: number;
                dataMultiplier: number;
                comment?: string | null;
                packaging: {
                  packagingId: string;
                  lineId: string;
                  name: string;
                  packagingNumber: string;
                  unit: string;
                  comment?: string | null;
                };
                parameters: Array<{ key: string; value: string }>;
              };
            }>;
          };
        } | null> | null;
        pendingBatches: {
          nextToken?: string | null;
          items: Array<{
            batchId: string;
            plannedStart?: string | null;
            amount: number;
            product: { expectedAverageSpeed: number; packaging: { unit: string } };
          }>;
        };
      } | null>;
    };
  };
};

export type LineHourlyQueryVariables = Exact<{
  lineId: Scalars['ID'];
  fullTime: Time;
  times: Array<Time> | Time;
  timeFromAccumulatedStart: Time;
  validIn: ScheduleTimeInput;
  annotationTime: Time;
  annotationTags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type LineHourlyQuery = {
  line: {
    id: string;
    mainSensor?: {
      _id?: string | null;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      verticalAnnotations?: Array<{
        id: string;
        label: string;
        timestamp: string;
        timestampEnd?: string | null;
        tags: Array<string>;
      }> | null;
    } | null;
    schedule?: {
      id: string;
      lineId: string;
      isExceptionalWeek: boolean;
      isFallbackSchedule: boolean;
      validFrom: { year: number; week: number };
      validTo?: { year: number; week: number } | null;
      shifts: Array<{
        id: string;
        name: string;
        description: string;
        timeRange: {
          from: { day: DayOfWeek; hour: number; minute: number };
          to: { day: DayOfWeek; hour: number; minute: number };
        };
        targets: {
          oee?: number | null;
          oee2?: number | null;
          oee3?: number | null;
          tcu?: number | null;
          produced?: number | null;
          numberOfBatches?: number | null;
        };
      }>;
      weeklyTargets: {
        lineId: string;
        id?: string | null;
        oee?: number | null;
        oee2?: number | null;
        oee3?: number | null;
        tcu?: number | null;
        produced?: number | null;
        numberOfBatches?: number | null;
        mainOeeType?: OeeType | null;
        validFrom?: { year: number; week: number } | null;
      };
      configuration: {
        lineId: string;
        disableLostConnectionAlarmOutsideWorkingHours: boolean;
        timezone: string;
        startDayOfWeek: DayOfWeek;
        automaticStopRegistration: {
          enabled: boolean;
          stopCauseId?: string | null;
          minimumStopMillis?: number | null;
          splitAtEnds?: boolean | null;
          comment?: string | null;
        };
      };
    } | null;
    timeFromAccumulatedStart?: Array<{
      stats: {
        data: {
          produced?: number | null;
          oee?: {
            oee1: number;
            oee2: number;
            oee3: number;
            tcu: number;
            totalEquipmentTime: number;
            mannedTime: number;
            operatingTime: number;
            productionTime: number;
            valuedOperatingTime: number;
            scrapLoss: number;
            speedLoss: number;
            oee1MaxProduced: number;
            oee2MaxProduced: number;
            oee3MaxProduced: number;
            tcuMaxProduced: number;
          } | null;
        };
      };
    } | null> | null;
    timeFull?: Array<{
      stats: {
        data: {
          produced?: number | null;
          oee?: {
            oee1: number;
            oee2: number;
            oee3: number;
            tcu: number;
            totalEquipmentTime: number;
            mannedTime: number;
            operatingTime: number;
            productionTime: number;
            valuedOperatingTime: number;
            scrapLoss: number;
            speedLoss: number;
          } | null;
        };
      };
      batches: {
        items: Array<{
          batchId: string;
          batchNumber: string;
          state: BatchState;
          amount: number;
          manualScrap?: number | null;
          plannedStart?: string | null;
          actualStart?: string | null;
          actualStop?: string | null;
          comment?: string | null;
          lineId: string;
          product: {
            productId: string;
            name: string;
            itemNumber: string;
            lineId: string;
            validatedLineSpeed: number;
            expectedAverageSpeed: number;
            dataMultiplier: number;
            comment?: string | null;
            packaging: {
              packagingId: string;
              lineId: string;
              name: string;
              packagingNumber: string;
              unit: string;
              comment?: string | null;
            };
            parameters: Array<{ key: string; value: string }>;
          };
        }>;
      };
      stops: Array<{
        __typename: 'Stop';
        _id: string;
        duration: number;
        ongoing: boolean;
        isMicroStop: boolean;
        comment?: string | null;
        initials?: string | null;
        registeredTime?: string | null;
        countermeasure?: string | null;
        countermeasureInitials?: string | null;
        standalone?: { excludeProduction?: boolean | null } | null;
        target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
        timeRange: { to?: string | null; from?: string | null };
        stopCause?: {
          _id: string;
          id: string;
          name: string;
          description: string;
          categoryName?: string | null;
          languageCode: string;
          stopType: StopType;
          requireInitials: boolean;
          requireComment: boolean;
          enableCountermeasure: boolean;
          meta: Array<{ name: string; description: string; languageCode: string }>;
          targetSetup?: {
            constantTime: number;
            parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
          } | null;
        } | null;
      } | null>;
      changeoverStops: Array<{
        duration: number;
        ongoing: boolean;
        target?: {
          target: number;
          previousBatch?: { batchId: string } | null;
          nextBatch?: { batchId: string } | null;
        } | null;
        timeRange: { to?: string | null; from?: string | null };
        stopCause?: {
          _id: string;
          id: string;
          name: string;
          description: string;
          categoryName?: string | null;
          languageCode: string;
          stopType: StopType;
          requireInitials: boolean;
          requireComment: boolean;
          enableCountermeasure: boolean;
          meta: Array<{ name: string; description: string; languageCode: string }>;
          targetSetup?: {
            constantTime: number;
            parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
          } | null;
        } | null;
      } | null>;
    } | null> | null;
    timeHours?: Array<{
      timeRange: { from?: string | null; to?: string | null };
      stats: {
        data: {
          produced?: number | null;
          oee?: {
            oee1: number;
            oee2: number;
            oee3: number;
            tcu: number;
            totalEquipmentTime: number;
            mannedTime: number;
            operatingTime: number;
            productionTime: number;
            valuedOperatingTime: number;
            scrapLoss: number;
            speedLoss: number;
            oee1MaxProduced: number;
            oee2MaxProduced: number;
            oee3MaxProduced: number;
            tcuMaxProduced: number;
          } | null;
        };
      };
    } | null> | null;
  };
};

export type LineMainSensorConfigQueryVariables = Exact<{
  lineId: Scalars['ID'];
}>;

export type LineMainSensorConfigQuery = {
  line: {
    id: string;
    mainSensor?: {
      _id?: string | null;
      peripheralId: string;
      name: string;
      description: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
    } | null;
  };
  units?: { units?: Array<{ name: string; description?: string | null }> | null } | null;
  analogInputRanges?: { analogInputRanges?: Array<{ name: string; description?: string | null }> | null } | null;
  sensorTypes?: { sensorTypes?: Array<{ name: string; description?: string | null }> | null } | null;
};

export type LineOeeDataQueryVariables = Exact<{
  lineId: Scalars['ID'];
  time: Array<Time> | Time;
  statsInput: StatsInput;
  OEEInput: OeeInput;
}>;

export type LineOeeDataQuery = {
  line: {
    id: string;
    mainSensor?: {
      _id?: string | null;
      id: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
    } | null;
    time?: Array<{
      timeRange: { from?: string | null; to?: string | null };
      stopStats: {
        longestNonStop: number;
        stopCauseStats: Array<{
          stopCauseId: string;
          stopCauseName: string;
          stopCauseDescription: string;
          stopCategoryName: string;
          type?: StopType | null;
          accDuration: number;
          accTarget: number;
          numberOfStops: number;
          isMicroStop: boolean;
        } | null>;
      };
      stats: {
        timeRange: { from?: string | null; to?: string | null };
        data: {
          oee?: {
            oee1: number;
            oee2: number;
            oee3: number;
            tcu: number;
            totalEquipmentTime: number;
            mannedTime: number;
            productionTime: number;
            operatingTime: number;
            valuedOperatingTime: number;
            speedLoss: number;
            scrapLoss: number;
          } | null;
        };
      };
    } | null> | null;
  };
};

export type LineOverviewWithKpiQueryVariables = Exact<{
  lineId: Scalars['ID'];
  time: Array<Time> | Time;
  points: Scalars['Int'];
  showStops: Scalars['Boolean'];
  showOee: Scalars['Boolean'];
  showWeeklyProgress: Scalars['Boolean'];
  showDailyProgress: Scalars['Boolean'];
  hasMaintenanceModule: Scalars['Boolean'];
  validIn: ScheduleTimeInput;
  pastWeek: Array<Time> | Time;
  today: Array<Time> | Time;
}>;

export type LineOverviewWithKpiQuery = {
  line: {
    id: string;
    name: string;
    description?: string | null;
    schedule?: { weeklyTargets: { produced?: number | null } } | null;
    mainSensor?: {
      _id?: string | null;
      peripheralId: string;
      name: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      time?: Array<{
        timeRange: { from?: string | null; to?: string | null };
        samples?: Array<{
          data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
          timeRange: { from?: string | null; to?: string | null };
        } | null> | null;
        batches: {
          nextToken?: string | null;
          items: Array<{
            batchId: string;
            batchNumber: string;
            state: BatchState;
            amount: number;
            manualScrap?: number | null;
            plannedStart?: string | null;
            actualStart?: string | null;
            actualStop?: string | null;
            comment?: string | null;
            lineId: string;
            stats: { data: { produced?: number | null } };
            product: {
              productId: string;
              name: string;
              itemNumber: string;
              lineId: string;
              validatedLineSpeed: number;
              expectedAverageSpeed: number;
              dataMultiplier: number;
              comment?: string | null;
              packaging: {
                packagingId: string;
                lineId: string;
                name: string;
                packagingNumber: string;
                unit: string;
                comment?: string | null;
              };
              parameters: Array<{ key: string; value: string }>;
            };
          }>;
        };
        stats: {
          data: {
            lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null;
            oee?: {
              oee1: number;
              oee2: number;
              oee3: number;
              tcu: number;
              totalEquipmentTime: number;
              mannedTime: number;
              productionTime: number;
              operatingTime: number;
              valuedOperatingTime: number;
              speedLoss: number;
              scrapLoss: number;
            } | null;
          };
        };
        stops?: Array<{
          __typename: 'Stop';
          _id: string;
          duration: number;
          ongoing: boolean;
          isMicroStop: boolean;
          comment?: string | null;
          initials?: string | null;
          registeredTime?: string | null;
          countermeasure?: string | null;
          countermeasureInitials?: string | null;
          standalone?: { excludeProduction?: boolean | null } | null;
          target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
          timeRange: { to?: string | null; from?: string | null };
          stopCause?: {
            _id: string;
            id: string;
            name: string;
            description: string;
            categoryName?: string | null;
            languageCode: string;
            stopType: StopType;
            requireInitials: boolean;
            requireComment: boolean;
            enableCountermeasure: boolean;
            meta: Array<{ name: string; description: string; languageCode: string }>;
            targetSetup?: {
              constantTime: number;
              parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
            } | null;
          } | null;
        } | null>;
      } | null> | null;
    } | null;
    pastWeekTime?: Array<{
      _id?: string | null;
      timeRange: { to?: string | null; from?: string | null };
      stats: { data: { produced?: number | null } };
    } | null> | null;
    todayTime?: Array<{
      _id?: string | null;
      timeRange: { to?: string | null; from?: string | null };
      stats: { data: { produced?: number | null } };
    } | null> | null;
    nodes: Array<{
      sensor?: { _id?: string | null; maintenanceWorkOrders?: { nodes: Array<{ timestamp: string }> } } | null;
    }>;
  };
  units?: { units?: Array<{ name: string; description?: string | null }> | null } | null;
  analogInputRanges?: { analogInputRanges?: Array<{ name: string; description?: string | null }> | null } | null;
  sensorTypes?: { sensorTypes?: Array<{ name: string; description?: string | null }> | null } | null;
};

export type LineOverviewQueryVariables = Exact<{
  lineId: Scalars['ID'];
}>;

export type LineOverviewQuery = {
  line: {
    id: string;
    name: string;
    description?: string | null;
    edges: Array<{ from?: string | null; to: string }>;
    nodes: Array<{
      id: string;
      type: NodeType;
      sensor?: { _id?: string | null; peripheralId: string; name: string; description: string } | null;
    }>;
  };
};

export type LinePackagingsFromPeripheralQueryVariables = Exact<{
  peripheralId: Scalars['ID'];
  paginationToken?: InputMaybe<Scalars['ID']>;
}>;

export type LinePackagingsFromPeripheralQuery = {
  lineFromPeripheral?: {
    id: string;
    packagings: {
      nextToken?: string | null;
      items: Array<{
        packagingId: string;
        lineId: string;
        name: string;
        packagingNumber: string;
        unit: string;
        comment?: string | null;
      }>;
    };
  } | null;
};

export type LinePackagingsQueryVariables = Exact<{
  lineId: Scalars['ID'];
  maxItems?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['ID']>;
}>;

export type LinePackagingsQuery = {
  line: {
    id: string;
    packagings: {
      nextToken?: string | null;
      items: Array<{
        packagingId: string;
        lineId: string;
        name: string;
        packagingNumber: string;
        unit: string;
        comment?: string | null;
      }>;
    };
  };
};

export type LinePendingBatchControlsQueryVariables = Exact<{
  lineId: Scalars['ID'];
  time: Array<Time> | Time;
  maxItems?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['ID']>;
}>;

export type LinePendingBatchControlsQuery = {
  line: {
    id: string;
    time?: Array<{
      pendingControls: {
        nextToken?: string | null;
        items: Array<{
          batchControlId: string;
          controlReceiptId: string;
          controlReceiptName: string;
          entryId: string;
          title?: string | null;
          batchId: string;
          status: BatchControlStatus;
          timeTriggered: string;
          timeControlled?: string | null;
          timeControlUpdated?: string | null;
          comment?: string | null;
          initials?: string | null;
          fieldValues: Array<{
            value?: string | null;
            controlReceiptField: {
              fieldId: string;
              label: string;
              description?: string | null;
              type: ControlFieldType;
              sensorId?: string | null;
              limits?: { lower?: number | null; upper?: number | null } | null;
              derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
            };
          }>;
          trigger: { delayWhenStopped: boolean; type: ControlTriggerType; payload?: { millis: number } | null };
          followUp: { enabled: boolean; delayMs?: number | null };
          originalControl?: { controlId: string; timeTriggered: string } | null;
          history: Array<{
            status: BatchControlStatus;
            timeControlled?: string | null;
            timeControlUpdated?: string | null;
            comment?: string | null;
            initials?: string | null;
            fieldValues: Array<{
              value?: string | null;
              controlReceiptField: {
                fieldId: string;
                label: string;
                description?: string | null;
                type: ControlFieldType;
                sensorId?: string | null;
                limits?: { lower?: number | null; upper?: number | null } | null;
                derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
              };
            }>;
          }>;
        }>;
      };
    } | null> | null;
  };
};

export type LinePeripheralBatchesQueryVariables = Exact<{
  peripheralId: Scalars['ID'];
  filter?: InputMaybe<BatchFilter>;
  paginationToken?: InputMaybe<Scalars['ID']>;
}>;

export type LinePeripheralBatchesQuery = {
  lineFromPeripheral?: {
    id: string;
    batches: {
      nextToken?: string | null;
      items: Array<{
        batchId: string;
        batchNumber: string;
        state: BatchState;
        amount: number;
        manualScrap?: number | null;
        plannedStart?: string | null;
        actualStart?: string | null;
        actualStop?: string | null;
        comment?: string | null;
        lineId: string;
        product: {
          productId: string;
          name: string;
          itemNumber: string;
          lineId: string;
          validatedLineSpeed: number;
          expectedAverageSpeed: number;
          dataMultiplier: number;
          comment?: string | null;
          packaging: {
            packagingId: string;
            lineId: string;
            name: string;
            packagingNumber: string;
            unit: string;
            comment?: string | null;
          };
          attachedControlReceipts: Array<{
            controlReceiptId: string;
            name: string;
            description?: string | null;
            entries: Array<{
              entryId: string;
              title?: string | null;
              trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
              followUp: { enabled: boolean; delayMs?: number | null };
              fields: Array<{
                fieldId: string;
                label: string;
                description?: string | null;
                type: ControlFieldType;
                sensorId?: string | null;
                limits?: { lower?: number | null; upper?: number | null } | null;
                derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
              }>;
            }>;
          }>;
          parameters: Array<{ key: string; value: string }>;
        };
        stats: { data: { produced?: number | null } };
      }>;
    };
  } | null;
};

export type LinePreviousShiftQueryVariables = Exact<{
  lineId: Scalars['ID'];
  timeShift: Array<Time> | Time;
}>;

export type LinePreviousShiftQuery = {
  lineShift: {
    id: string;
    name: string;
    description?: string | null;
    mainSensor?: {
      _id?: string | null;
      id: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
    } | null;
    time?: Array<{
      _id?: string | null;
      timeRange: { from?: string | null; to?: string | null };
      stats: { data: { produced?: number | null } };
      stops: Array<{
        __typename: 'Stop';
        _id: string;
        duration: number;
        ongoing: boolean;
        isMicroStop: boolean;
        comment?: string | null;
        initials?: string | null;
        registeredTime?: string | null;
        countermeasure?: string | null;
        countermeasureInitials?: string | null;
        standalone?: { excludeProduction?: boolean | null } | null;
        target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
        timeRange: { to?: string | null; from?: string | null };
        stopCause?: {
          _id: string;
          id: string;
          name: string;
          description: string;
          categoryName?: string | null;
          languageCode: string;
          stopType: StopType;
          requireInitials: boolean;
          requireComment: boolean;
          enableCountermeasure: boolean;
          meta: Array<{ name: string; description: string; languageCode: string }>;
          targetSetup?: {
            constantTime: number;
            parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
          } | null;
        } | null;
      } | null>;
      batches: {
        items: Array<{
          batchId: string;
          batchNumber: string;
          state: BatchState;
          amount: number;
          manualScrap?: number | null;
          plannedStart?: string | null;
          actualStart?: string | null;
          actualStop?: string | null;
          comment?: string | null;
          lineId: string;
          product: {
            productId: string;
            name: string;
            itemNumber: string;
            lineId: string;
            validatedLineSpeed: number;
            expectedAverageSpeed: number;
            dataMultiplier: number;
            comment?: string | null;
            packaging: {
              packagingId: string;
              lineId: string;
              name: string;
              packagingNumber: string;
              unit: string;
              comment?: string | null;
            };
            parameters: Array<{ key: string; value: string }>;
          };
        }>;
      };
    } | null> | null;
  };
};

export type LineProducedQueryVariables = Exact<{
  lineId: Scalars['ID'];
  time: Array<Time> | Time;
}>;

export type LineProducedQuery = {
  line: { id: string; name: string; time?: Array<{ stats: { data: { produced?: number | null } } } | null> | null };
};

export type LineProductsFromPeripheralQueryVariables = Exact<{
  peripheralId: Scalars['ID'];
  paginationToken?: InputMaybe<Scalars['ID']>;
}>;

export type LineProductsFromPeripheralQuery = {
  lineFromPeripheral?: {
    id: string;
    products: {
      nextToken?: string | null;
      items: Array<{
        productId: string;
        name: string;
        itemNumber: string;
        lineId: string;
        validatedLineSpeed: number;
        expectedAverageSpeed: number;
        dataMultiplier: number;
        comment?: string | null;
        packaging: {
          packagingId: string;
          lineId: string;
          name: string;
          packagingNumber: string;
          unit: string;
          comment?: string | null;
        };
        attachedControlReceipts: Array<{
          controlReceiptId: string;
          name: string;
          description?: string | null;
          entries: Array<{
            entryId: string;
            title?: string | null;
            trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
            followUp: { enabled: boolean; delayMs?: number | null };
            fields: Array<{
              fieldId: string;
              label: string;
              description?: string | null;
              type: ControlFieldType;
              sensorId?: string | null;
              limits?: { lower?: number | null; upper?: number | null } | null;
              derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
            }>;
          }>;
        }>;
        parameters: Array<{ key: string; value: string }>;
      }>;
    };
  } | null;
};

export type LineProductsQueryVariables = Exact<{
  lineId: Scalars['ID'];
  paginationToken?: InputMaybe<Scalars['ID']>;
}>;

export type LineProductsQuery = {
  line: {
    id: string;
    products: {
      nextToken?: string | null;
      items: Array<{
        productId: string;
        name: string;
        itemNumber: string;
        lineId: string;
        validatedLineSpeed: number;
        expectedAverageSpeed: number;
        dataMultiplier: number;
        comment?: string | null;
        packaging: {
          packagingId: string;
          lineId: string;
          name: string;
          packagingNumber: string;
          unit: string;
          comment?: string | null;
        };
        attachedControlReceipts: Array<{
          controlReceiptId: string;
          name: string;
          description?: string | null;
          entries: Array<{
            entryId: string;
            title?: string | null;
            trigger: { type: ControlTriggerType; delayWhenStopped: boolean; payload?: { millis: number } | null };
            followUp: { enabled: boolean; delayMs?: number | null };
            fields: Array<{
              fieldId: string;
              label: string;
              description?: string | null;
              type: ControlFieldType;
              sensorId?: string | null;
              limits?: { lower?: number | null; upper?: number | null } | null;
              derivationSettings?: { formula: ControlDerivationFormula; fields: Array<string> } | null;
            }>;
          }>;
        }>;
        parameters: Array<{
          key: string;
          value: string;
          alarm?: {
            id: string;
            name: string;
            description: string;
            status: AlarmStatus;
            threshold: number;
            repeatNotification: boolean;
            enabled: boolean;
            type: AlarmType;
            languageCode: string;
            snoozeDuration?: number | null;
            alarmConfiguration: { x?: number | null; t?: number | null; y?: number | null; n?: number | null };
            peripheral?: { name: string; _id?: string | null } | { name: string; _id?: string | null } | null;
          } | null;
          setpoint?: {
            value: number;
            peripheral:
              | { name: string; id: string; _id?: string | null }
              | { name: string; id: string; _id?: string | null };
          } | null;
        }>;
      }>;
    };
  };
};

export type LineScheduleQueryVariables = Exact<{
  lineId: Scalars['ID'];
  validIn: ScheduleTimeInput;
}>;

export type LineScheduleQuery = {
  line: {
    id: string;
    name: string;
    description?: string | null;
    schedule?: {
      id: string;
      lineId: string;
      isExceptionalWeek: boolean;
      isFallbackSchedule: boolean;
      validFrom: { year: number; week: number };
      validTo?: { year: number; week: number } | null;
      shifts: Array<{
        id: string;
        name: string;
        description: string;
        timeRange: {
          from: { day: DayOfWeek; hour: number; minute: number };
          to: { day: DayOfWeek; hour: number; minute: number };
        };
        targets: {
          oee?: number | null;
          oee2?: number | null;
          oee3?: number | null;
          tcu?: number | null;
          produced?: number | null;
          numberOfBatches?: number | null;
        };
      }>;
      weeklyTargets: {
        lineId: string;
        id?: string | null;
        oee?: number | null;
        oee2?: number | null;
        oee3?: number | null;
        tcu?: number | null;
        produced?: number | null;
        numberOfBatches?: number | null;
        mainOeeType?: OeeType | null;
        validFrom?: { year: number; week: number } | null;
      };
      configuration: {
        lineId: string;
        disableLostConnectionAlarmOutsideWorkingHours: boolean;
        timezone: string;
        startDayOfWeek: DayOfWeek;
        automaticStopRegistration: {
          enabled: boolean;
          stopCauseId?: string | null;
          minimumStopMillis?: number | null;
          splitAtEnds?: boolean | null;
          comment?: string | null;
        };
      };
    } | null;
  };
};

export type LineSettingsQueryVariables = Exact<{
  lineId: Scalars['ID'];
}>;

export type LineSettingsQuery = {
  line: {
    id: string;
    name: string;
    description?: string | null;
    nodes: Array<{
      id: string;
      type: NodeType;
      peripheralId: string;
      sensor?: {
        _id?: string | null;
        peripheralId: string;
        name: string;
        description: string;
        config: {
          type?: SensorType | null;
          stopThreshold?: number | null;
          findStops?: boolean | null;
          energyMeter?: boolean | null;
          stopRegisterThreshold?: number | null;
          stopValueThreshold?: number | null;
          dataMultiplier?: number | null;
          validatedSpeed?: number | null;
          expectedSpeed?: number | null;
          analogOffset?: number | null;
          analogInputRange?: AnalogInputRange | null;
          publishRate?: number | null;
          sampleRate?: number | null;
          triggerIndex?: number | null;
          chartTimeScale?: ChartTimeScale | null;
          sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
          accumulatorConfig?: {
            resetsOnPowerOff: boolean;
            rolloverValue: number;
            rolloverThreshold?: number | null;
          } | null;
          discreteConfig?: {
            onStates: Array<number>;
            offStates: Array<number>;
            defaultState: DiscreteDeviceState;
          } | null;
          dataAlarm?: {
            notificationFrequency?: number | null;
            updateBuffer?: number | null;
            repeatNotification?: boolean | null;
            subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
          } | null;
          chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
        };
      } | null;
    }>;
    edges: Array<{ from?: string | null; to: string }>;
    mainSensor?: {
      _id?: string | null;
      peripheralId: string;
      groups?: Array<{
        id: string;
        lineIds: Array<string>;
        name: string;
        sensors: Array<{
          _id?: string | null;
          name: string;
          description: string;
          peripheralId: string;
          config: { type?: SensorType | null };
          peripheralInformation?: { node: { peripheralId: string } } | null;
        } | null>;
      }> | null;
    } | null;
  };
  units?: { units?: Array<{ name: string; description?: string | null }> | null } | null;
  analogInputRanges?: { analogInputRanges?: Array<{ name: string; description?: string | null }> | null } | null;
  sensorTypes?: { sensorTypes?: Array<{ name: string; description?: string | null }> | null } | null;
};

export type LineShiftDashboardWithoutStopsQueryVariables = Exact<{
  lineId: Scalars['ID'];
  timeDay: Array<Time> | Time;
  points: Scalars['Int'];
}>;

export type LineShiftDashboardWithoutStopsQuery = {
  lineDay: {
    id: string;
    name: string;
    description?: string | null;
    mainSensor?: {
      _id?: string | null;
      id: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
    } | null;
    time?: Array<{
      _id?: string | null;
      timeRange: { from?: string | null; to?: string | null };
      samples?: Array<{
        timeRange: { to?: string | null; from?: string | null };
        data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
      } | null> | null;
      stats: {
        data: {
          produced?: number | null;
          lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null;
        };
      };
      batches: {
        nextToken?: string | null;
        items: Array<{
          batchId: string;
          batchNumber: string;
          state: BatchState;
          amount: number;
          manualScrap?: number | null;
          plannedStart?: string | null;
          actualStart?: string | null;
          actualStop?: string | null;
          comment?: string | null;
          lineId: string;
          product: {
            productId: string;
            name: string;
            itemNumber: string;
            lineId: string;
            validatedLineSpeed: number;
            expectedAverageSpeed: number;
            dataMultiplier: number;
            comment?: string | null;
            packaging: {
              packagingId: string;
              lineId: string;
              name: string;
              packagingNumber: string;
              unit: string;
              comment?: string | null;
            };
            parameters: Array<{ key: string; value: string }>;
          };
          stats: { data: { produced?: number | null } };
        }>;
      };
    } | null> | null;
  };
  andon: Array<{ companyId: string; calls: Array<{ id: string; urgency: Urgency }> }>;
};

export type LineShiftDashboardQueryVariables = Exact<{
  lineId: Scalars['ID'];
  timeShift: Array<Time> | Time;
  timeDay: Array<Time> | Time;
  points: Scalars['Int'];
}>;

export type LineShiftDashboardQuery = {
  lineShift: {
    id: string;
    name: string;
    description?: string | null;
    mainSensor?: {
      _id?: string | null;
      id: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
    } | null;
    time?: Array<{
      _id?: string | null;
      timeRange: { from?: string | null; to?: string | null };
      samples?: Array<{
        timeRange: { to?: string | null; from?: string | null };
        data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
      } | null> | null;
      stops: Array<{
        __typename: 'Stop';
        _id: string;
        duration: number;
        ongoing: boolean;
        isMicroStop: boolean;
        comment?: string | null;
        initials?: string | null;
        registeredTime?: string | null;
        countermeasure?: string | null;
        countermeasureInitials?: string | null;
        standalone?: { excludeProduction?: boolean | null } | null;
        target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
        timeRange: { to?: string | null; from?: string | null };
        stopCause?: {
          _id: string;
          id: string;
          name: string;
          description: string;
          categoryName?: string | null;
          languageCode: string;
          stopType: StopType;
          requireInitials: boolean;
          requireComment: boolean;
          enableCountermeasure: boolean;
          meta: Array<{ name: string; description: string; languageCode: string }>;
          targetSetup?: {
            constantTime: number;
            parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
          } | null;
        } | null;
      } | null>;
      stats: {
        data: {
          produced?: number | null;
          lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null;
        };
      };
      batches: {
        nextToken?: string | null;
        items: Array<{
          batchId: string;
          batchNumber: string;
          state: BatchState;
          amount: number;
          manualScrap?: number | null;
          plannedStart?: string | null;
          actualStart?: string | null;
          actualStop?: string | null;
          comment?: string | null;
          lineId: string;
          product: {
            productId: string;
            name: string;
            itemNumber: string;
            lineId: string;
            validatedLineSpeed: number;
            expectedAverageSpeed: number;
            dataMultiplier: number;
            comment?: string | null;
            packaging: {
              packagingId: string;
              lineId: string;
              name: string;
              packagingNumber: string;
              unit: string;
              comment?: string | null;
            };
            parameters: Array<{ key: string; value: string }>;
          };
          stats: { data: { produced?: number | null } };
        }>;
      };
    } | null> | null;
    pendingBatches: {
      nextToken?: string | null;
      items: Array<{
        batchId: string;
        plannedStart?: string | null;
        amount: number;
        product: { expectedAverageSpeed: number; packaging: { unit: string } };
      }>;
    };
  };
  lineDay: {
    id: string;
    time?: Array<{
      _id?: string | null;
      timeRange: { from?: string | null; to?: string | null };
      samples?: Array<{
        timeRange: { to?: string | null; from?: string | null };
        data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
      } | null> | null;
      stops: Array<{
        __typename: 'Stop';
        _id: string;
        duration: number;
        ongoing: boolean;
        isMicroStop: boolean;
        comment?: string | null;
        initials?: string | null;
        registeredTime?: string | null;
        countermeasure?: string | null;
        countermeasureInitials?: string | null;
        standalone?: { excludeProduction?: boolean | null } | null;
        target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
        timeRange: { to?: string | null; from?: string | null };
        stopCause?: {
          _id: string;
          id: string;
          name: string;
          description: string;
          categoryName?: string | null;
          languageCode: string;
          stopType: StopType;
          requireInitials: boolean;
          requireComment: boolean;
          enableCountermeasure: boolean;
          meta: Array<{ name: string; description: string; languageCode: string }>;
          targetSetup?: {
            constantTime: number;
            parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
          } | null;
        } | null;
      } | null>;
      batches: {
        nextToken?: string | null;
        items: Array<{
          batchId: string;
          batchNumber: string;
          state: BatchState;
          amount: number;
          manualScrap?: number | null;
          plannedStart?: string | null;
          actualStart?: string | null;
          actualStop?: string | null;
          comment?: string | null;
          lineId: string;
          product: {
            productId: string;
            name: string;
            itemNumber: string;
            lineId: string;
            validatedLineSpeed: number;
            expectedAverageSpeed: number;
            dataMultiplier: number;
            comment?: string | null;
            packaging: {
              packagingId: string;
              lineId: string;
              name: string;
              packagingNumber: string;
              unit: string;
              comment?: string | null;
            };
            parameters: Array<{ key: string; value: string }>;
          };
          stats: { data: { produced?: number | null } };
        }>;
      };
    } | null> | null;
  };
  andon: Array<{ companyId: string; calls: Array<{ id: string; urgency: Urgency }> }>;
};

export type LineSimpleQueryVariables = Exact<{
  lineId: Scalars['ID'];
  filter?: InputMaybe<LineFilterInput>;
}>;

export type LineSimpleQuery = {
  line: {
    id: string;
    name: string;
    description?: string | null;
    tests: Array<{ id: string; name: string; description: string; time: { from?: string | null; to?: string | null } }>;
    nodes: Array<{
      id: string;
      peripheralId: string;
      type: NodeType;
      sensor?: {
        _id?: string | null;
        name: string;
        description: string;
        owner?: string | null;
        config: {
          type?: SensorType | null;
          stopThreshold?: number | null;
          findStops?: boolean | null;
          energyMeter?: boolean | null;
          stopRegisterThreshold?: number | null;
          stopValueThreshold?: number | null;
          dataMultiplier?: number | null;
          validatedSpeed?: number | null;
          expectedSpeed?: number | null;
          analogOffset?: number | null;
          analogInputRange?: AnalogInputRange | null;
          publishRate?: number | null;
          sampleRate?: number | null;
          triggerIndex?: number | null;
          chartTimeScale?: ChartTimeScale | null;
          sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
          accumulatorConfig?: {
            resetsOnPowerOff: boolean;
            rolloverValue: number;
            rolloverThreshold?: number | null;
          } | null;
          discreteConfig?: {
            onStates: Array<number>;
            offStates: Array<number>;
            defaultState: DiscreteDeviceState;
          } | null;
          dataAlarm?: {
            notificationFrequency?: number | null;
            updateBuffer?: number | null;
            repeatNotification?: boolean | null;
            subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
          } | null;
          chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
        };
      } | null;
    }>;
  };
};

export type LineStatusReportDataQueryVariables = Exact<{
  lineId: Scalars['ID'];
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type LineStatusReportDataQuery = {
  line: {
    id: string;
    time?: Array<{
      timeRange: { from?: string | null; to?: string | null };
      samples?: Array<{
        data: { accValue?: number | null };
        timeRange: { to?: string | null; from?: string | null };
      } | null> | null;
      stopStats: {
        longestNonStop: number;
        stopCauseStats: Array<{
          stopCauseId: string;
          stopCauseName: string;
          stopCauseDescription: string;
          stopCategoryName: string;
          type?: StopType | null;
          accDuration: number;
          accTarget: number;
          numberOfStops: number;
          isMicroStop: boolean;
        } | null>;
      };
      currentBatches: {
        nextToken?: string | null;
        items: Array<{
          batchId: string;
          batchNumber: string;
          state: BatchState;
          amount: number;
          manualScrap?: number | null;
          plannedStart?: string | null;
          actualStart?: string | null;
          actualStop?: string | null;
          comment?: string | null;
          lineId: string;
          product: {
            productId: string;
            name: string;
            itemNumber: string;
            lineId: string;
            validatedLineSpeed: number;
            expectedAverageSpeed: number;
            dataMultiplier: number;
            comment?: string | null;
            parameters: Array<{ key: string; value: string }>;
          };
          stats: { data: { produced?: number | null } };
        }>;
      };
    } | null> | null;
    pendingBatches: { nextToken?: string | null; items: Array<{ batchId: string; plannedStart?: string | null }> };
  };
};

export type LineQueryVariables = Exact<{
  lineId: Scalars['ID'];
  time: Array<Time> | Time;
  points: Scalars['Int'];
}>;

export type LineQuery = {
  line: {
    id: string;
    name: string;
    description?: string | null;
    nodes: Array<{
      id: string;
      type: NodeType;
      peripheralId: string;
      sensor?: {
        _id?: string | null;
        peripheralId: string;
        name: string;
        description: string;
        id: string;
        time?: Array<{
          _id?: string | null;
          samples?: Array<{
            data: { count: number; accValue?: number | null; minValue?: number | null; maxValue?: number | null };
            timeRange: { from?: string | null; to?: string | null };
          } | null> | null;
        } | null> | null;
        config: {
          type?: SensorType | null;
          stopThreshold?: number | null;
          findStops?: boolean | null;
          energyMeter?: boolean | null;
          stopRegisterThreshold?: number | null;
          stopValueThreshold?: number | null;
          dataMultiplier?: number | null;
          validatedSpeed?: number | null;
          expectedSpeed?: number | null;
          analogOffset?: number | null;
          analogInputRange?: AnalogInputRange | null;
          publishRate?: number | null;
          sampleRate?: number | null;
          triggerIndex?: number | null;
          chartTimeScale?: ChartTimeScale | null;
          sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
          accumulatorConfig?: {
            resetsOnPowerOff: boolean;
            rolloverValue: number;
            rolloverThreshold?: number | null;
          } | null;
          discreteConfig?: {
            onStates: Array<number>;
            offStates: Array<number>;
            defaultState: DiscreteDeviceState;
          } | null;
          dataAlarm?: {
            notificationFrequency?: number | null;
            updateBuffer?: number | null;
            repeatNotification?: boolean | null;
            subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
          } | null;
          chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
        };
      } | null;
    }>;
    mainSensor?: { id: string; _id?: string | null; config: { stopRegisterThreshold?: number | null } } | null;
    time?: Array<{
      stats: {
        data: { lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null };
      };
    } | null> | null;
  };
  units?: { units?: Array<{ name: string; description?: string | null }> | null } | null;
  analogInputRanges?: { analogInputRanges?: Array<{ name: string; description?: string | null }> | null } | null;
  sensorTypes?: { sensorTypes?: Array<{ name: string; description?: string | null }> | null } | null;
};

export type LinesListSimpleQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
}>;

export type LinesListSimpleQuery = { lines: Array<{ id: string; name: string; description?: string | null }> };

export type MaintenanceLinesQueryVariables = Exact<{ [key: string]: never }>;

export type MaintenanceLinesQuery = {
  lines: Array<{
    id: string;
    name: string;
    nodes: Array<{
      peripheralId: string;
      sensor?: {
        name: string;
        _id?: string | null;
        config: { type?: SensorType | null; sensorUnit?: { label?: string | null; unit?: Unit | null } | null };
      } | null;
    }>;
  }>;
};

export type MaintenanceLogQueryVariables = Exact<{
  planId: Scalars['MaintenancePlanId'];
  peripheralId: Scalars['PeripheralId'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type MaintenanceLogQuery = {
  maintenancePlan?: {
    log: {
      pageInfo: {
        hasPreviousPage: boolean;
        hasNextPage: boolean;
        startCursor?: string | null;
        endCursor?: string | null;
      };
      nodes: Array<{ timestamp: string; onTime: boolean; initials: string; comment: string }>;
    };
  } | null;
};

export type MaintenancePlansQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type MaintenancePlansQuery = {
  maintenancePlans: {
    pageInfo: {
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    nodes: Array<{
      planId: any;
      peripheralId: string;
      asset: string;
      component: string;
      tagPartNumber?: string | null;
      instructions?: string | null;
      startFrom: string;
      version: number;
      peripheral:
        | { _id?: string | null; name: string }
        | {
            _id?: string | null;
            name: string;
            config: { sensorUnit?: { label?: string | null } | null };
            peripheralInformation?: { line: { id: string; name: string } } | null;
          };
      trackBy: { time?: { days: number; hours: number } | null; production?: { produced: number } | null };
    }>;
  };
};

export type MaintenanceWorkOrdersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<MaintenanceFilter>;
}>;

export type MaintenanceWorkOrdersQuery = {
  maintenanceWorkOrders: {
    pageInfo: {
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    nodes: Array<{
      planId: any;
      peripheralId: string;
      timestamp: string;
      asset: string;
      component: string;
      tagPartNumber?: string | null;
      instructions?: string | null;
      peripheral:
        | { _id?: string | null; name: string }
        | {
            _id?: string | null;
            name: string;
            config: { sensorUnit?: { label?: string | null } | null };
            peripheralInformation?: { line: { id: string; name: string } } | null;
          };
      trackBy: { time?: { days: number; hours: number } | null; production?: { produced: number } | null };
      latestMaintenance?: { timestamp: string; initials: string; comment: string } | null;
    }>;
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  user: {
    sub: string;
    username: string;
    email?: string | null;
    givenName?: string | null;
    familyName?: string | null;
    groups: Array<{ role: { type: RoleType } }>;
    company: { id: string; name?: string | null };
  };
  features: {
    userPool: Array<{ id: string; feature: Array<{ Q: boolean; M: boolean; key: string }> }>;
    group: Array<{ id: string; feature: Array<{ Q: boolean; M: boolean; key: string }> }>;
    line: Array<{ id: string; feature: Array<{ Q: boolean; M: boolean; key: string }> }>;
    peripheral: Array<{ id: string; feature: Array<{ Q: boolean; M: boolean; key: string }> }>;
  };
  claims: { index: Array<{ name: string; id: number }>; resources: Array<{ key: string; claims: Array<number> }> };
};

export type RecentBatchesFromLineQueryVariables = Exact<{
  lineId: Scalars['ID'];
  filter?: InputMaybe<BatchFilter>;
}>;

export type RecentBatchesFromLineQuery = {
  line: {
    id: string;
    batches: {
      items: Array<{ batchId: string; batchNumber: string; actualStart?: string | null; actualStop?: string | null }>;
    };
  };
};

export type ReportsForGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type ReportsForGroupsQuery = {
  company: {
    id: string;
    groups: Array<{
      id: string;
      name: string;
      scheduledReports: Array<{
        id: string;
        enabled: boolean;
        type: ReportType;
        entityId: string;
        entityType: EntityType;
        name: string;
        description?: string | null;
        timezone: string;
        subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
        trigger: { type: TriggerType; parameters: string };
      }>;
    }>;
  };
};

export type ReportsForLineQueryVariables = Exact<{
  lineId: Scalars['ID'];
}>;

export type ReportsForLineQuery = {
  line: {
    id: string;
    name: string;
    scheduledReports: Array<{
      id: string;
      enabled: boolean;
      type: ReportType;
      entityId: string;
      entityType: EntityType;
      name: string;
      description?: string | null;
      timezone: string;
      subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
      trigger: { type: TriggerType; parameters: string };
    }>;
  };
};

export type ReportsForLinesQueryVariables = Exact<{ [key: string]: never }>;

export type ReportsForLinesQuery = {
  lines: Array<{
    id: string;
    name: string;
    scheduledReports: Array<{
      id: string;
      enabled: boolean;
      type: ReportType;
      entityId: string;
      entityType: EntityType;
      name: string;
      description?: string | null;
      timezone: string;
      subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
      trigger: { type: TriggerType; parameters: string };
    }>;
  }>;
};

export type ReportsForSensorsQueryVariables = Exact<{ [key: string]: never }>;

export type ReportsForSensorsQuery = {
  devices: Array<{
    uuid: string;
    sensors: Array<{
      _id?: string | null;
      id: string;
      name: string;
      scheduledReports: Array<{
        id: string;
        enabled: boolean;
        type: ReportType;
        entityId: string;
        entityType: EntityType;
        name: string;
        description?: string | null;
        timezone: string;
        subscribers: Array<{ type: SubscriptionType; value: string; languageCode: string }>;
        trigger: { type: TriggerType; parameters: string };
      }>;
    }>;
  } | null>;
};

export type SensorGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type SensorGroupsQuery = {
  company: {
    id: string;
    groups: Array<{
      id: string;
      name: string;
      sensors: Array<{
        _id?: string | null;
        name: string;
        description: string;
        peripheralId: string;
        peripheralType: PeripheralType;
        config: { type?: SensorType | null };
        peripheralInformation?: { node: { peripheralId: string } } | null;
      } | null>;
    }>;
  };
};

export type SensorSettingsQueryVariables = Exact<{
  peripheralId: Scalars['ID'];
}>;

export type SensorSettingsQuery = {
  peripheralByID?:
    | {
        name: string;
        _id?: string | null;
        peripheralId: string;
        peripheralType: PeripheralType;
        owner?: string | null;
        description: string;
        attachedSensors: Array<{
          _id?: string | null;
          peripheralId: string;
          peripheralType: PeripheralType;
          name: string;
          description: string;
        } | null>;
      }
    | {
        id: string;
        name: string;
        _id?: string | null;
        peripheralId: string;
        peripheralType: PeripheralType;
        owner?: string | null;
        description: string;
        peripheralInformation?: { node: { type: NodeType }; line: { id: string; name: string } } | null;
        config: {
          type?: SensorType | null;
          stopThreshold?: number | null;
          findStops?: boolean | null;
          energyMeter?: boolean | null;
          stopRegisterThreshold?: number | null;
          stopValueThreshold?: number | null;
          dataMultiplier?: number | null;
          validatedSpeed?: number | null;
          expectedSpeed?: number | null;
          analogOffset?: number | null;
          analogInputRange?: AnalogInputRange | null;
          publishRate?: number | null;
          sampleRate?: number | null;
          triggerIndex?: number | null;
          chartTimeScale?: ChartTimeScale | null;
          wiring?: { desired?: SensorWiring | null; reported?: SensorWiring | null } | null;
          sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
          accumulatorConfig?: {
            resetsOnPowerOff: boolean;
            rolloverValue: number;
            rolloverThreshold?: number | null;
          } | null;
          discreteConfig?: {
            onStates: Array<number>;
            offStates: Array<number>;
            defaultState: DiscreteDeviceState;
          } | null;
          dataAlarm?: {
            notificationFrequency?: number | null;
            updateBuffer?: number | null;
            repeatNotification?: boolean | null;
            subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
          } | null;
          chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
        };
      }
    | null;
  units?: { units?: Array<{ name: string; description?: string | null }> | null } | null;
  analogInputRanges?: { analogInputRanges?: Array<{ name: string; description?: string | null }> | null } | null;
  sensorTypes?: { sensorTypes?: Array<{ name: string; description?: string | null }> | null } | null;
};

export type ShiftTimesQueryVariables = Exact<{
  lineId: Scalars['ID'];
  validIn: ScheduleTimeInput;
}>;

export type ShiftTimesQuery = {
  line: {
    id: string;
    schedule?: {
      id: string;
      shifts: Array<{
        id: string;
        name: string;
        timeRange: {
          from: { day: DayOfWeek; hour: number; minute: number };
          to: { day: DayOfWeek; hour: number; minute: number };
        };
      }>;
      configuration: { timezone: string; startDayOfWeek: DayOfWeek };
    } | null;
  };
};

export type StatsQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  OEEInput: OeeInput;
}>;

export type StatsQuery = {
  device?: {
    _id?: string | null;
    sensor?: {
      id: string;
      _id?: string | null;
      config: {
        stopThreshold?: number | null;
        stopRegisterThreshold?: number | null;
        validatedSpeed?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { label?: string | null; unit?: Unit | null } | null;
      };
      time?: Array<{
        timeRange: { to?: string | null; from?: string | null };
        samples?: Array<{
          data: { accValue?: number | null };
          timeRange: { to?: string | null; from?: string | null };
        } | null> | null;
        stats: {
          data: {
            produced?: number | null;
            longestNonStop?: number | null;
            numberOfStops?: number | null;
            averageStopLength?: number | null;
            downtime?: number | null;
            valueAddingTime?: number | null;
            valueAddingTimeWhileManned?: number | null;
            mtbf?: number | null;
            averageProducedMinute?: number | null;
            averageProducedHour?: number | null;
            cycleTime?: number | null;
            producedPerStop?: number | null;
            speedWhileManned?: number | null;
            speedWhileProducing?: number | null;
            oee?: { oee1: number; oee2: number; oee3: number; tcu: number } | null;
            lineStatus?: { status?: Status | null; duration?: number | null; cause?: string | null } | null;
            customKPIs?: Array<{ id: string; name: string; unit: string; value?: number | null }> | null;
          };
        };
      } | null> | null;
    } | null;
  } | null;
};

export type StopCauseCategoriesQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
}>;

export type StopCauseCategoriesQuery = {
  device?: {
    uuid: string;
    _id?: string | null;
    sensor?: {
      _id?: string | null;
      id: string;
      peripheralId: string;
      stopCauseCategories: Array<{
        _id: string;
        id: string;
        name: string;
        ownerType: OwnerType;
        stopCauses: Array<{
          _id: string;
          id: string;
          name: string;
          description: string;
          categoryName?: string | null;
          languageCode: string;
          stopType: StopType;
          requireInitials: boolean;
          requireComment: boolean;
          enableCountermeasure: boolean;
          meta: Array<{ name: string; description: string; languageCode: string }>;
          targetSetup?: {
            constantTime: number;
            parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
          } | null;
        } | null>;
        meta: Array<{ name: string; languageCode: string }>;
      } | null>;
    } | null;
  } | null;
};

export type StopCauseMappingQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
}>;

export type StopCauseMappingQuery = {
  device?: {
    uuid: string;
    owner?: string | null;
    _id?: string | null;
    sensor?: {
      _id?: string | null;
      id: string;
      stopCauseCategories: Array<{
        _id: string;
        id: string;
        name: string;
        ownerType: OwnerType;
        stopCauses: Array<{
          _id: string;
          id: string;
          name: string;
          description: string;
          categoryName?: string | null;
          languageCode: string;
          stopType: StopType;
          requireInitials: boolean;
          requireComment: boolean;
          enableCountermeasure: boolean;
          meta: Array<{ name: string; description: string; languageCode: string }>;
          targetSetup?: {
            constantTime: number;
            parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
          } | null;
        } | null>;
        meta: Array<{ name: string; languageCode: string }>;
      } | null>;
      stopCauseMappings: Array<{
        peripheralIdTarget: string;
        peripheralIdEvent: string;
        value: string;
        stopCauseId: string;
        buffer?: number | null;
        lookForward: number;
        lookBack: number;
        regPriority?: number | null;
        split?: boolean | null;
      } | null>;
    } | null;
  } | null;
};

export type StopsAndStatsQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  filter?: InputMaybe<StopFilter>;
}>;

export type StopsAndStatsQuery = {
  device?: {
    _id?: string | null;
    sensor?: {
      _id?: string | null;
      id: string;
      peripheralId: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      time?: Array<{
        stopStats: {
          longestNonStop: number;
          stopCauseStats: Array<{
            stopCauseId: string;
            stopCauseName: string;
            stopCauseDescription: string;
            stopCategoryName: string;
            type?: StopType | null;
            accDuration: number;
            accTarget: number;
            numberOfStops: number;
            isMicroStop: boolean;
          } | null>;
        };
        stops: Array<{
          __typename: 'Stop';
          _id: string;
          duration: number;
          ongoing: boolean;
          isMicroStop: boolean;
          comment?: string | null;
          initials?: string | null;
          registeredTime?: string | null;
          countermeasure?: string | null;
          countermeasureInitials?: string | null;
          standalone?: { excludeProduction?: boolean | null } | null;
          target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
          timeRange: { to?: string | null; from?: string | null };
          stopCause?: {
            _id: string;
            id: string;
            name: string;
            description: string;
            categoryName?: string | null;
            languageCode: string;
            stopType: StopType;
            requireInitials: boolean;
            requireComment: boolean;
            enableCountermeasure: boolean;
            meta: Array<{ name: string; description: string; languageCode: string }>;
            targetSetup?: {
              constantTime: number;
              parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
            } | null;
          } | null;
        } | null>;
      } | null> | null;
      cameras: Array<{ _id?: string | null; name: string; peripheralId: string } | null>;
    } | null;
  } | null;
};

export type StopsLatestQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  filter?: InputMaybe<StopFilter>;
}>;

export type StopsLatestQuery = {
  device?: {
    _id?: string | null;
    sensor?: {
      _id?: string | null;
      id: string;
      time?: Array<{
        stops: Array<{
          ongoing: boolean;
          timeRange: { from?: string | null };
          stopCause?: {
            _id: string;
            id: string;
            name: string;
            description: string;
            categoryName?: string | null;
            languageCode: string;
            stopType: StopType;
            requireInitials: boolean;
            requireComment: boolean;
            enableCountermeasure: boolean;
            meta: Array<{ name: string; description: string; languageCode: string }>;
            targetSetup?: {
              constantTime: number;
              parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
            } | null;
          } | null;
        } | null>;
      } | null> | null;
    } | null;
  } | null;
};

export type StopsQueryVariables = Exact<{
  uuid: Scalars['ID'];
  index: Scalars['ID'];
  time: Array<Time> | Time;
  filter?: InputMaybe<StopFilter>;
}>;

export type StopsQuery = {
  device?: {
    _id?: string | null;
    sensor?: {
      _id?: string | null;
      id: string;
      peripheralId: string;
      config: {
        type?: SensorType | null;
        stopThreshold?: number | null;
        findStops?: boolean | null;
        energyMeter?: boolean | null;
        stopRegisterThreshold?: number | null;
        stopValueThreshold?: number | null;
        dataMultiplier?: number | null;
        validatedSpeed?: number | null;
        expectedSpeed?: number | null;
        analogOffset?: number | null;
        analogInputRange?: AnalogInputRange | null;
        publishRate?: number | null;
        sampleRate?: number | null;
        triggerIndex?: number | null;
        chartTimeScale?: ChartTimeScale | null;
        sensorUnit?: { unit?: Unit | null; label?: string | null } | null;
        accumulatorConfig?: {
          resetsOnPowerOff: boolean;
          rolloverValue: number;
          rolloverThreshold?: number | null;
        } | null;
        discreteConfig?: {
          onStates: Array<number>;
          offStates: Array<number>;
          defaultState: DiscreteDeviceState;
        } | null;
        dataAlarm?: {
          notificationFrequency?: number | null;
          updateBuffer?: number | null;
          repeatNotification?: boolean | null;
          subscribers: Array<{ type: SubscriberType; value: string; languageCode: string }>;
        } | null;
        chart?: { scale?: ChartTimeScale | null; dataFilter?: DataFilter | null } | null;
      };
      time?: Array<{
        stops: Array<{
          __typename: 'Stop';
          _id: string;
          duration: number;
          ongoing: boolean;
          isMicroStop: boolean;
          comment?: string | null;
          initials?: string | null;
          registeredTime?: string | null;
          countermeasure?: string | null;
          countermeasureInitials?: string | null;
          standalone?: { excludeProduction?: boolean | null } | null;
          target?: { previousBatch?: string | null; nextBatch?: string | null; target: number } | null;
          timeRange: { to?: string | null; from?: string | null };
          stopCause?: {
            _id: string;
            id: string;
            name: string;
            description: string;
            categoryName?: string | null;
            languageCode: string;
            stopType: StopType;
            requireInitials: boolean;
            requireComment: boolean;
            enableCountermeasure: boolean;
            meta: Array<{ name: string; description: string; languageCode: string }>;
            targetSetup?: {
              constantTime: number;
              parameterTimes: Array<{ parameter: string; time: number; unlessParameter?: string | null }>;
            } | null;
          } | null;
        } | null>;
      } | null> | null;
    } | null;
  } | null;
};
